import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {ChangeDetectorRef} from "@angular/core";
import {BaseModelService} from "../services/model/base-model.service";
import {AbstractCaptionService} from "irf-caption";
import {PermissionCheckComponent} from "./PermissionCheckComponent";
import {MyStorageService} from "../services/my-storage.service";

export abstract class AbstractListPage<MODEL, SERVICE extends BaseModelService<any>> extends PermissionCheckComponent {
  public models: MODEL;
  public mobile: boolean = false;
  public eventWhereMobileIsTrue: () => void;

  protected readonly _destroyed = new Subject<void>();

  protected constructor(protected viewportService: NxViewportService,
                        protected _cdr: ChangeDetectorRef,
                        protected translateService: AbstractCaptionService,
                        public service: SERVICE,
                        myStorageService: MyStorageService,
  ) {
    super(myStorageService);
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
        if (this.eventWhereMobileIsTrue) {
          this.eventWhereMobileIsTrue();
        }
      });
    this.initPermission();
  }


  public new(): () => void {
    return () => this.service.new();
  }

  public show(): (row: any) => void {
    return (model: any) => this.service.show(model.keycloakId ? model.email : model.id);
  }

  public edit(): (row: any) => void {
    return (model: any) => this.service.edit(model.keycloakId ? model.email : model.id);
  }

  protected async getTranslate(key): Promise<string> {
    return (await this.translateService.getCaption(key)).nev;
  }
}
