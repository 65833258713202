import {BaseModel} from "./base.model";

export class Attachment implements BaseModel{

  id: number = 0;
  title: string = '';
  mimeType: string = '';
  filePath: string = '';
  fileName: string = '';
  slug: string = '';
  detectorAttachment:boolean = false;

  public copyWithoutId(): Attachment {
    let copy: Attachment = Object.assign(new Attachment(), this);
    copy.id = 0;
    return copy;
  }

}

