import {Route} from "@angular/router";
import {MEASURING_DEVICE_STATUSES} from "../models/measuring-device.model";

export const DENY_DATE_KEYS = {
  DENY_DATES: "DENY_DATES",
  FIRST_START_DENY_DATE: "FIRST_START_DENY_DATE",
  FIRST_END_DENY_DATE: "FIRST_END_DENY_DATE"
}

export const COUNTRY_CODES: string[] = ['al', 'at', 'ba', 'be', 'bg', 'by', 'ch', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gr', 'hu', 'hr', 'ie', 'it', 'lt', 'lu', 'lv', 'mk', 'nl', 'no', 'pl', 'pt', 'ro', 'rs', 'se', 'si', 'sk', 'tr', 'ua', 'vn', 'id', 'th'];

export const ROLE_INDEX: { [key: string]: number } = {
  "admin": 0,
  "Partner": 1,
  "configurator": 2,
  "distributor": 3,
}
export const PARTNER_USER_TYPES = {
  "GENERAL": "GENERAL",
  "CONTACT": "CONTACT"
}
export const ATTACHMENT_REFRESH_EVENT_TYPES = {
  CHANGE_DATA: 'CHANGE_DATA'
}
export const ROLES = ['admin', 'Partner', 'configurator', "distributor"];
export const USER_ROLES = {
  'ADMIN': 'admin',
  'PARTNER': 'Partner',
  'CONFIGURATOR': 'configurator',
  'DISTRIBUTOR': 'distributor'
};
export const AUTH_USER_KEY = 'AUTH_USER';
export const DISTRIBUTOR_KEY = 'DISTRIBUTOR_KEY';
export const PARTNER_KEY = 'PARTNER_KEY';
export const ROLE_CARD = 'ROLE_CARD';
export const DEVICE_API_KEY = 'DEVICE_API_KEY';

export const ADDON_ROUTES: { [key: string]: Route } = {
  'jcd-addon': {
    path: 'jcd-addon',
    data: {title: 'general.title.jcd-addon'},
    loadChildren: () => import('jcd-addon').then(mod => mod.JcdAddonModule)
  }
}

export const DEVICE_STATUSES: MEASURING_DEVICE_STATUSES[] = ['ACTIVE', 'UNDER_CONSTRUCTION', 'FAULTY', 'UNDER_REPAIR'];
export const GANT_FILTER_COOKIE_KEY = "GANT_FILTER";
export const GANT_DISTRIBUTOR_FILTER_COOKIE_KEY = "GANT_FILTER_DISTRIBUTOR";
