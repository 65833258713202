import {Directive, EventEmitter, Input, Output, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[viewAnchor]'
})
export class ViewAnchorDirective {

  @Input() data: any;
  @Input() isOptional: boolean = false;
  @Input() isContent: boolean;
  @Output() refreshEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
