import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient, HttpParams} from '@angular/common/http';

import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {MeasuringDevice} from '../models/measuring-device.model';
import {FilterPayloadInterface} from "../services/filterer/filter-payload-interface";

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class MeasuringDeviceRepository extends BaseRepository<any> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/admin/measuring-devices/filter')
  getAllByFilter(filter: FilterPayloadInterface) {
    const options = {params: new HttpParams().append('filter', JSON.stringify(filter))};
    return super.get(undefined, options);
  }

  @Path('/distributor/measuring-devices/for-distributors')
  getAllForDistributor(ids:number[]) {
    return super.post(ids);
  }

  @Path('/admin/measuring-devices/without-contract?start={start}&end={end}')
  getAllWithoutContract(start: string, end: string) {
    return super.get();
  }

  @Path('/distributor/measuring-devices/{distributorId}/without-contract?start={start}&end={end}')
  getAllWithoutContractForDistributor(distributorId:number, start: string, end: string) {
    return super.get();
  }

  @Path('/admin/measuring-devices/{id}')
  getById(id: string) {
    return super.get();
  }
  @Path('/distributor/measuring-devices/{id}')
  getByIdAsDistributor(id: number) {
    return super.get();
  }

  @Path('/admin/measuring-devices')
  save(measuringDevice: MeasuringDevice) {
    return super.post({
      type: "Add",
      id: 0,
      attribute: measuringDevice
    });
  }

  @Path('/admin/measuring-devices/{id}')
  update(id: number, measuringDevice: MeasuringDevice) {
    return super.put({
      type: "Update",
      id: 0,
      attribute: measuringDevice
    });
  }

  @Path('/admin/measuring-devices/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  @Path('/admin/measuring-devices/{id}/attachments/{attachmentId}/add')
  addAttachment(id: number, attachmentId: number) {
    return super.patch();
  }

  @Path('/admin/measuring-devices/{id}/attachments/{attachmentId}/remove')
  removeAttachment(id: number, attachmentId: number) {
    return super.patch();
  }

  @Path('/admin/measuring-devices/{id}/notes/{noteId}/add')
  addNote(id: number, noteId: number) {
    return super.patch();
  }

  @Path('/admin/measuring-devices/{id}/status/{status}')
  patchStatus(id: number, status: string) {
    return super.patch();
  }
}

