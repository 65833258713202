<div nxLayout="grid nogutters">
  <div nxRow="">
    <div nxCol="12">
      <div class="map-container">
        <div id="map" style="{{'height:'+height}}"></div>
      </div>
    </div>
  </div>
</div>

<!--  <ng-template #sajt [cdkPortalOutlet]="componentPortal" (attached)=fillModelInTemplate($event)></ng-template>-->

