<div nxModalContent>
  <nx-card class="w-100 h-100">
    <div nxLayout="grid">
      <form-webhook-form [model]="model"></form-webhook-form>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="model"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | caption}}
  </button>
</div>
