import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {Attachment} from '../models/attachment.model';

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class AttachmentRepository extends BaseRepository<PostPayloadInterface> {


  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/api/attachments?deleted={deleted}')
  getAllByDeleted(deleted: boolean) {
    return super.get();
  }

  @Path('/api/attachments/deletable')
  getAllDeletable() {
    return super.get();
  }

  @Path('/api/attachments/detector-attachment')
  getAllForDetector() {
    return super.get();
  }

  @Path('/api/attachments/{extension}')
  getAllIByExtension(extension: string) {
    return super.get();
  }

  @Path('/api/attachments/images')
  getAllImages() {
    return super.get();
  }

  @Path('/api/attachments/{id}')
  getById(id: string) {
    return super.get();
  }

  @Path('/api/attachments')
  save(file: File, attachment: Attachment) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('attachment', JSON.stringify(attachment));
    return super.postFile(formData);
  }

  @Path('/admin/attachments')
  update(attachment: Attachment) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: attachment
    });
  }

  @Path('/admin/attachments/{id}')
  updateFileForAttachment(file: File, id: number) {
    return super.patch({
      type: 'UpdateFile',
      id: 0,
      attribute: 'asd? :)'
    });
  }

  @Path('/admin/attachments/{id}/clear-cache')
  clearCacheForAttachment(id: number) {
    return super.patch();
  }

  @Path('/admin/attachments/{id}')
  deleteById(id: string) {
    return super.delete();
  }


}

