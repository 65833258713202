import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA, NxDialogService} from "@aposin/ng-aquila/modal";
import {Contract} from "../../../models/contract.model";
import {DEVICE_API_KEY} from "../../../utilities/constants";
import {ConfiguratorRepository} from "../../../repositories/configurator-repository";
import {MyStorageService} from "../../../services/my-storage.service";
import {lastValueFrom} from "rxjs";
import {NxTabChangeEvent} from "@aposin/ng-aquila/tabs";
import {ContractService} from "../../../services/model/contract.service";
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig, FormlyFormOptions} from "@ngx-formly/core";
import {FormlyJsonschema} from "@ngx-formly/core/json-schema";
import {WebsocketService, WS_ENDPOINTS} from "../../../services/websocket.service";
import {PermissionCheckComponent} from "../../../models/PermissionCheckComponent";


export class ShowOnlineDeviceModalInput {
  contract: any | Contract;
  modalId: string;

  constructor(contract: any, modalId: string) {
    this.contract = contract;
    this.modalId = modalId;
  }
}


@Component({
  selector: 'app-show-online-device',
  templateUrl: './show-online-device.component.html',
  styleUrls: ['./show-online-device.component.scss']
})
export class ShowOnlineDeviceComponent extends PermissionCheckComponent implements OnInit {
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  resultJson: any = {};
  type: string;
  defaultConfig: any;
  currentImgSrc: string;
  contract: any | Contract;
  currentIndex = 0;
  deviceStatus: any;
  unsavedConfig: boolean = false;
  actualConfig: any;
  deviceConfig: any;
  deviceConfigForFormly: any;
  private lockId: string;
  private statusRefresh: any;
  formlyError: any | undefined;
  private imgLoad = 0;

  constructor(myStorageService: MyStorageService,
              @Inject(NX_MODAL_DATA) public inputData: ShowOnlineDeviceModalInput,
              private configuratorRepo: ConfiguratorRepository,
              private contractService: ContractService,
              private formlyJsonschema: FormlyJsonschema,
              private websocketService: WebsocketService,
              private dialogService: NxDialogService
  ) {
    super(myStorageService);
  }

  async ngOnInit(): Promise<void> {
    this.initPermission();
    const contract = this.inputData.contract;
    if (contract.measuringDevice) {
      this.configuratorRepo.setUrl(contract.measuringDevice.globalAddress);
      this.myStorageService.saveToStore(DEVICE_API_KEY, contract.measuringDevice.apiKey)
      this.currentImgSrc = this.configuratorRepo.getImageUrl();
      this.contract = contract;
      this.getDeviceStatusPromise().then(status => {
        console.log(status);
        this.deviceStatus = status;
        this.initAfterDeviceStatusIsOk();
      }).catch(error => {
        console.log(error);
        this.dialogService.getModalById(this.inputData.modalId)?.close();
      });
    } else {
      this.contractService.notificationService.showWarning('general.warning', 'error.contract.hasNotDevice');
    }
  }

  private initAfterDeviceStatusIsOk() {
    this.statusRefresh = setInterval(() => this.loadStatus(false), 2000);
    // const detectors = await lastValueFrom(this.configuratorRepo.getDetectors());
    // console.log(detectors);
    // const cameraStat = await lastValueFrom(this.configuratorRepo.getCameraStat());
    // console.log(cameraStat);
    this.actualConfig = JSON.parse(this.contract.actualConfig.rawConfigJson);
    this.deviceConfig = JSON.stringify(this.actualConfig.model);
    // const config = await lastValueFrom(this.configuratorRepo.getConfig());
    this.initFormly(this.actualConfig);
    this.initWebsocketEndpoints();
  }

  private initWebsocketEndpoints() {
    this.websocketService.connectToEndpoint(WS_ENDPOINTS.LOCK, (response) => {
      if (response.subscriber != this.authUser.email) {
        if (this.contract.measuringDevice.id == response.attribute.id) {
          this.contract.locked = true;
        }
      }
    });
    this.websocketService.connectToEndpoint(WS_ENDPOINTS.UNLOCK, (response) => {
      if (response.subscriber != this.authUser.email) {
        if (this.contract.measuringDevice.id == response.attribute.id) {
          this.contract.locked = false;
        }
      }
    });
  }

  private getDeviceStatusPromise() {
    return lastValueFrom(this.configuratorRepo.getDeviceStatus());
  }

  private async loadStatus(first?: boolean) {
    ConfiguratorRepository.showLoader = false;
    this.getDeviceStatusPromise()
      .then(result => {
        this.deviceStatus = result
        ConfiguratorRepository.showLoader = true;
      })
      .catch((error) => {
        console.log(error);
        this.clearInterval();
        ConfiguratorRepository.showLoader = true;
      });
  }

  initFormly(config) {
    try {
      if (config != undefined && config != null) {
        this.deviceConfigForFormly = config;
        this.type = 'nested';
        this.form = new FormGroup({});
        this.options = {};
        this.fields = [this.formlyJsonschema.toFieldConfig(this.deviceConfigForFormly.schema)];
        this.model = this.deviceConfigForFormly.model;
        this.resultJson.schema = this.deviceConfigForFormly.schema;
        this.resultJson.model = this.model;
      }
    } catch (e) {
      console.log(config, e);
      this.formlyError = e;
    }
  }

  saveConfig() {
    if (this.form.valid) {
      try {
        this.actualConfig.model = this.model;
        this.configuratorRepo.saveConfig(this.resultJson.model).subscribe(async result => {
          await lastValueFrom(this.contractService.updateConfigJson(this.contract.id, this.lockId, JSON.stringify(this.actualConfig)));
          await lastValueFrom(this.contractService.unlock(this.contract.id, this.lockId));
          this.contractService.notificationService.showSuccess('general.success', 'onlineDevice.successConfigUpdate');
          this.unsavedConfig = false;
          setTimeout(() => {
            this.currentIndex = 0;
          }, 50)
          this.lockId = "";
        });
      } catch (e: any) {
        this.contractService.notificationService.showError('general.error', 'onlineDevice.errorConfigUpdate');
      }
    } else {
      this.contractService.notificationService.showWarning('warning', 'warning.invalidForm');
    }

  }

  cancelConfig() {
    this.unsavedConfig = false;
    setTimeout(() => {
      this.currentIndex = 0;
    }, 300)
    this.unlockProcess();
  }

  tabChanged($event: NxTabChangeEvent) {
    if ($event.index == 3) {
      this.lockProcess();
    }
  }

  private async lockProcess() {
    this.lockId = "sajt"
    await lastValueFrom(this.contractService.lock(this.contract.id, this.lockId));
    this.unsavedConfig = true;
  }

  private async unlockProcess() {
    if (this.lockId.length > 0) {
      await lastValueFrom(this.contractService.unlock(this.contract.id, this.lockId));
    }
  }

  submit() {
    if (this.form.valid) {
      alert(JSON.stringify(this.model));
    }
  }

  clearInterval() {
    clearInterval(this.statusRefresh);
  }

  refreshImg() {
    this.currentImgSrc = this.configuratorRepo.getImageUrl() + "?" + this.imgLoad;
    this.imgLoad++;
  }
}
