<ng-container *ngIf="model">
  <nx-card class="viewer">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.name"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>

    <div nxRow="" class="nx-margin-s">
      <div nxCol="12, 6">
        <inside-show-detector [model]="model"></inside-show-detector>
      </div>
      <div nxCol="12, 6">
        <nx-data-display class="nx-margin-bottom-s" label="{{'detector.defaultConfig' | caption}}" orientation="vertical">
          <app-print-pre [data]="model.defaultConfig"></app-print-pre>
        </nx-data-display>
      </div>
    </div>
  </nx-card>
</ng-container>
