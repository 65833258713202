 import {Utilities} from '../utilities/utilities';

export abstract class AttachmentUrlService {

  private static BASE_URL = '/mgmt';

  public static getSvgImageUrl(slug: string) {
    return Utilities.getServiceUrl() + AttachmentUrlService.BASE_URL + '/img/' + slug;
  }

  public static getScaleHeightImageUrl(slug: string, height: number) {
    return Utilities.getServiceUrl() + AttachmentUrlService.BASE_URL + '/img/scale/height/' + height + '/' + slug;
  }

  public static getScaleWidthImageUrlBySlug(slug: string, width: number) {
    return Utilities.getServiceUrl() + AttachmentUrlService.BASE_URL + '/img/scale/width/' + width + '/' + slug;
  }

  public static getDownloadUrl(slug: string) {
    return Utilities.getServiceUrl() + AttachmentUrlService.BASE_URL + '/row/' + slug;
  }

}
