<ng-container *ngIf="partners && distributors">
  <form class="form-example mt-3">
    <div nxLayout="grid">
      <div nxRow="">
        <ng-container *ngIf="model.measuringPoint">
        <div nxCol="12,12,12,12">
          <nx-formfield nxLabel="{{'contract.point' | caption}}">
            <input type="text" nxInput disabled
                   [value]="model.measuringPoint?.name"/>
          </nx-formfield>
        </div>
        </ng-container>
        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'contract.partner' | caption}}">
            <nx-dropdown
              name="partnerId"
              [(ngModel)]="model.partnerId"
              (ngModelChange)="updatePartner($event)"
            >
              <ng-container *ngFor="let partner of partners">
                <nx-dropdown-item [nxValue]="partner.id"> {{partner.name}}</nx-dropdown-item>
              </ng-container>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'general.distributor' | caption}}">
            <nx-dropdown
              name="distributorId"
              [(ngModel)]="model.distributorId"
              (ngModelChange)="updateDistributor($event)"
            >
              <ng-container *ngFor="let distributor of distributors">
                <nx-dropdown-item [nxValue]="distributor.id"> {{distributor.name}}</nx-dropdown-item>
              </ng-container>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'contract.start' | caption}}">
            <input name="start" type="local-datetime" nxInput nxDatefield [nxDatepicker]="startDatePicker"
                   (dateChange)="updateEndDatepicker()"
                   [nxDatefieldFilter]="denyDate()"
                   [(ngModel)]="model.start" required/>
            <nx-datepicker-toggle
              [for]="startDatePicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #startDatePicker></nx-datepicker>
          </nx-formfield>

        </div>
        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'contract.end' | caption}}">
            <input name="end" type="datetime" nxInput nxDatefield [nxDatepicker]="endDatePicker" [nxMin]="minDate" [disabled]="!minDate"

                   [nxDatefieldFilter]="denyDate()"
                   [(ngModel)]="model.end" required/>
            <nx-datepicker-toggle
              [for]="endDatePicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #endDatePicker></nx-datepicker>
          </nx-formfield>
        </div>
        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'addon.chartType' | caption}} *">
            <nx-multi-select [(ngModel)]="model.chartTypes" name="chartTypes"
                             [options]="chartTypes" selectValue="id" selectLabel="label"
            ></nx-multi-select>
          </nx-formfield>
        </div>

        <div nxCol="12">
          <nx-message nxContext="info">
            <span class="nx-font-weight-bold">{{'general.info' | caption}}</span><br/>
            <span *ngIf="model.measuringPoint">  {{'contract.selectDateToContinue' | caption}}</span>
            <span *ngIf="!model.measuringPoint">  {{'contract.selectDateToContinue2' | caption}}</span>
            <br/>
            <button (click)="loadDeviceAndPoint()" nxButton="secondary small" class="nx-margin-top-s">
              <nx-icon name="check"></nx-icon>
              {{'contract.dateIsOk' | caption}}
            </button>
          </nx-message>
        </div>
        <ng-container *ngIf="model.start && model.end && measuringDevices">
          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'contract.device' | caption}}">
              <nx-dropdown
                name="measuringDeviceId"
                (ngModelChange)="updateDevice($event)"
                [(ngModel)]="model.measuringDeviceId"
              >
                <ng-container *ngFor="let device of measuringDevices">
                  <nx-dropdown-item [nxValue]="device.id"> {{device.uuId}}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </div>
        </ng-container>
        <ng-container *ngIf="model.start && model.end && measuringPoints && !model.measuringPoint">
          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'contract.point' | caption}}">
              <nx-dropdown
                name="measuringDeviceId"
                [(ngModel)]="model.measuringPointId"
              >
                <ng-container *ngFor="let point of measuringPoints">
                  <nx-dropdown-item [nxValue]="point.id"> {{point.name}}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>
<!--  <ng-container *ngIf="measuringPoints.length<1 || measuringDevices.length<1">-->
<!--    <nx-message nxContext="info">-->
<!--      <span class="nx-font-weight-bold">{{'general.info' | caption}}</span><br/>-->
<!--      {{'contract.canNotCreate' | caption}}-->
<!--      <ul class="nx-margin-left-2m">-->
<!--        <li *ngIf="!measuringDevices || measuringDevices.length<1">{{'contract.noDevice' | caption}}</li>-->
<!--        <li *ngIf="!measuringPoints || measuringPoints.length<1">{{'contract.noPoint' | caption}}</li>-->
<!--      </ul>-->
<!--    </nx-message>-->
<!--  </ng-container>-->
