import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-print-pre',
  templateUrl: './print-pre.component.html',
  styleUrls: ['./print-pre.component.css']
})
export class PrintPreComponent implements OnInit {
  @Input() data: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
