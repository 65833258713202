<div nxModalContent>

  <ng-container *ngIf="contract && deviceStatus">
    <nx-card class="w-100 h-100">
      <nx-card-header>
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
          {{contract.name}}
        </h3>
      </nx-card-header>
      <nx-tab-group [(selectedIndex)]="currentIndex" appearance="expert" (selectedTabChange)="tabChanged($event)">
        <nx-tab [disabled]="unsavedConfig">
          <ng-template nxTabLabel>
            <nx-icon name="signal" size="s" aria-hidden="true" class="nx-margin-right-xs"></nx-icon>
            {{'onlineDevice.status' | caption}} ({{deviceStatus.deviceId.value}})
          </ng-template>
          <ng-template nxTabContent>
            <div nxLayout="grid" class="nx-margin-top-s">
              <div nxRow="">
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="temperature-low" size="s"></nx-icon>
                      <p>{{'onlineDevice.cpuTemperature' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{deviceStatus.cpu.temperature.value.toFixed(1)}}°
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="microchip" size="s"></nx-icon>
                      <p>{{'onlineDevice.cpuFrequency' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{deviceStatus.cpu.frequency.value.toFixed(2)}} {{deviceStatus.cpu.frequency.type | uppercase}}
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="tachometer-fast" size="s"></nx-icon>
                      <p>{{'onlineDevice.cpuUsage' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{(deviceStatus.cpu.usage.value * 100).toFixed(1)}} %
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="tachometer-average" size="s"></nx-icon>
                      <p>{{'onlineDevice.avgCpuUsage' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{(deviceStatus.cpu.loadAvg.value).toFixed(1)}} %
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>

                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="memory" size="s"></nx-icon>
                      <p>{{'onlineDevice.memoryUsage' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{(deviceStatus.mem.usage.value * 100).toFixed(1)}} %
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="hdd" size="s"></nx-icon>
                      <p>{{'onlineDevice.diskSpace' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{(deviceStatus.disk.usage.value * 100).toFixed(1)}} %
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="sd-card" size="s"></nx-icon>
                      <p>{{'onlineDevice.freeDiskSpace' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{deviceStatus.disk.free.value | byteTo}}
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
                <div nxCol="12,12,6,4,3">
                  <nx-card class="box">
                    <nx-card-header>
                      <nx-icon name="compact-disc" size="s"></nx-icon>
                      <p>{{'onlineDevice.totalDiskSpace' | caption}}</p>
                      <h4 class="nx-margin-y-s">
                        {{deviceStatus.disk.total.value| byteTo}}
                      </h4>
                    </nx-card-header>
                  </nx-card>
                </div>
              </div>
            </div>
          </ng-template>
        </nx-tab>
        <nx-tab [disabled]="unsavedConfig">
          <ng-template nxTabLabel>
            <nx-icon name="image" size="s" aria-hidden="true" class="nx-margin-right-xs"></nx-icon>
            {{'onlineDevice.currentImage' | caption}}
          </ng-template>
          <ng-template nxTabContent>
            <div nxLayout="grid" class="nx-margin-top-s">
              <div nxRow="">
                <div nxCol="12">
                  <figure nxFigure="">
                    <img [src]="currentImgSrc | secureImg | async " [alt]="contract.name"
                         (error)="currentImgSrc='img/rvc_ico.png'" (click)="refreshImg()"
                         style="width:100%; cursor:pointer"/>
                  </figure>
                </div>
              </div>
            </div>
          </ng-template>
        </nx-tab>
        <nx-tab [disabled]="unsavedConfig">
          <ng-template nxTabLabel>
            <nx-icon name="list" size="s" aria-hidden="true" class="nx-margin-right-xs"></nx-icon>
            {{'onlineDevice.logView' | caption}}
          </ng-template>
          <ng-template nxTabContent>
            <div nxLayout="grid" class="nx-margin-top-s">
              <div nxRow="">
                <div nxCol="12">
                  <app-device-log-viewer></app-device-log-viewer>
                </div>
              </div>
            </div>
          </ng-template>
        </nx-tab>
        <nx-tab [disabled]="contract.locked || !hasPermission || formlyError">
          <ng-template nxTabLabel>
            <nx-icon name="setting" size="s" aria-hidden="true" class="nx-margin-right-xs"></nx-icon>
            {{'onlineDevice.settings' | caption}}
          </ng-template>
          <ng-template nxTabContent>
            <div nxLayout="grid" class="nx-margin-top-s">
              <div nxRow="">
                <div nxCol="12">
                  <button
                    class="float-end nx-margin-left-s"
                    nxButton="small primary"
                    type="button"
                    (click)="saveConfig()"
                  >
                    {{'general.save' | caption}}
                  </button>
                  <button
                    class="float-end"
                    nxButton="small secondary"
                    type="button"
                    (click)="cancelConfig()"
                  >
                    {{'general.cancel' | caption}}
                  </button>
                </div>

                <div nxCol="12">
                  <form [formGroup]="form" (submit)="saveConfig()">
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
                    <button
                      class="float-end nx-margin-left-s"
                      nxButton="small primary"
                      type="submit"
                    >
                      {{'general.save' | caption}}
                    </button>
                    <button
                      class="float-end"
                      nxButton="small secondary"
                      type="button"
                      (click)="cancelConfig()"
                    >
                      {{'general.cancel' | caption}}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </ng-template>
        </nx-tab>
      </nx-tab-group>


    </nx-card>

  </ng-container>
</div>
<div nxModalActions>
  <ng-container *ngIf="!unsavedConfig">
    <button
      nxModalClose="back"
      class="nx-margin-bottom-0 nx-margin-right-xs"
      nxButton="small secondary"
      type="button"
      (click)="clearInterval()"
    >
      {{'general.back' | caption}}
    </button>
  </ng-container>
  <!--  <button-->
  <!--    [nxModalClose]="'ok'"-->
  <!--    class="nx-margin-bottom-0"-->
  <!--    nxButton="small"-->
  <!--    type="button"-->
  <!--  >-->
  <!--    {{'general.add' | caption}}-->
  <!--  </button>-->
</div>


