import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AbstractShowPage} from "../../../../models/AbstractShowPage";
import {MeasuringPoint} from "../../../../models/measuring-point.model";
import {MeasuringPointDistributorService} from "../../../../services/model/measuring-point-distributor.service";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";
import {ROUTING} from "../../../../utilities/routing-constants";
import {PageTest} from "../../../../utilities/PageTest";
import {MyStorageService} from "../../../../services/my-storage.service";

@Component({
  selector: 'app-show-measuring-points',
  templateUrl: './show-measuring-points-for-distributor.component.html',
  styleUrls: ['./show-measuring-points-for-distributor.component.scss']
})
@PageTest({
  path: 'show/:id',
  pathPrefix: ROUTING.MeasuringPoint.basePlural,
  layout: DistributorLayoutComponent
})
export class ShowMeasuringPointsForDistributorComponent extends AbstractShowPage<MeasuringPoint, MeasuringPointDistributorService> implements OnInit {

  constructor(service: MeasuringPointDistributorService, route: ActivatedRoute,
              myStorageService:MyStorageService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

}
