<nx-card class="w-100 nx-margin-bottom-s">
  <nx-card-header>
    <nx-toolbar style="margin-left: -12px; margin-right: -12px; margin-top: -12px; width:auto" >
      <span class="back-right">{{row.firstName}} {{row.lastName}}</span>
      <ng-container *ngFor="let action of actionConfig">
        <button
          nxIconButton="tertiary small"
          aria-label="a useful label"
          type="button"
          class="nx-margin-right-2xs nx-margin-bottom-0"
          *ngIf="isHidden(row, action)"
          (click)="action.clickPredicate(row)"
          [nxTooltip]="action.title | caption">
          <nx-icon [name]="action.iconName" [attr.aria-label]="action.title"></nx-icon>
        </button>
      </ng-container>
    </nx-toolbar>
  </nx-card-header>
  <nx-data-display [orientation]="orientation$ | async"
                   class="nx-margin-bottom-xs nx-margin-top-s"
  >
    <nx-data-display-label size="s">{{'user.keycloakId' | caption}}</nx-data-display-label>
    <p nxCopytext="normal">{{row.keycloakId }}</p>
  </nx-data-display>
  <nx-data-display [orientation]="orientation$ | async"
                   class="nx-margin-bottom-xs"
  >
    <nx-data-display-label size="s">{{'user.email' | caption}}</nx-data-display-label>
    <p nxCopytext="normal">{{row.email }}</p>
  </nx-data-display>
  <nx-data-display *ngIf="row.partners && row.partners.length>0"
                   [orientation]="orientation$ | async"
                   class="nx-margin-bottom-xs"
  >
    <nx-data-display-label size="s">{{'user.partners' | caption}}</nx-data-display-label>
    <p nxCopytext="normal">
      {{row.partners | joint:'name'}}
    </p>
  </nx-data-display>
  <nx-data-display [orientation]="orientation$ | async"
                   class="nx-margin-bottom-xs"
  >
    <nx-data-display-label size="s">{{'user.roles' | caption}}</nx-data-display-label>
    <p nxCopytext="normal">{{row.roles }}</p>
  </nx-data-display>

</nx-card>

