import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PartnerService} from '../../../services/model/partner.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {AttachmentService} from "../../../services/model/attachment.service";
import {MyStorageService} from "../../../services/my-storage.service";
import {DistributorService} from "../../../services/model/distributor.service";

@Component({
  selector: 'app-show-distributors',
  templateUrl: './show-distributors.component.html',
  styleUrls: ['./show-distributors.component.scss']
})
@PageTest({
  path: 'show/:id',
  pathPrefix: ROUTING.Distributor.basePlural,
  layout: AdminLayoutComponent
})
export class ShowDistributorsComponent extends AbstractShowPage<any, DistributorService> implements OnInit {

  constructor(service: DistributorService, route: ActivatedRoute,
              myStorageService:MyStorageService, private attachmentService:AttachmentService) {
    super(service, route,myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }


  protected override initProcess() {
    this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachment);
    this.model.address = JSON.parse(this.model.address);
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

}
