<header class="w-100" nx-header>

  <ng-container *ngIf="withNavigation && roleCards.length>1">
    <nx-header-navigation>
      <nx-header-navigation-item>
        <a
          routerLink="/"
          nxHeaderLink
          [routerLinkActiveOptions]="{exact: true}"
          routerLinkActive="is-active"
          [skipLocationChange]="true"
          nxTooltip="{{'general.home' | caption}}"
        >
          <ng-container *ngIf="!mobil">{{'general.home' | caption}}</ng-container>
          <ng-container *ngIf="mobil">
            <button nxPlainButton="">
              <nx-icon name="home" [attr.aria-label]="'general.home' | caption"></nx-icon>
            </button>
          </ng-container>
        </a>
      </nx-header-navigation-item>
      <ng-container *ngFor="let card of roleCards">
        <nx-header-navigation-item>
          <a [routerLink]="card.startUri"
             nxHeaderLink
             [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="is-active"
             nxTooltip="{{card.title | caption}}"
          >
            <ng-container *ngIf="!mobil">{{card.title | caption}}</ng-container>
            <ng-container *ngIf="mobil">
              <button nxPlainButton="">
                <nx-icon name="{{card.iconName}}" [attr.aria-label]="card.title | caption"></nx-icon>
              </button>
            </ng-container>
          </a>
        </nx-header-navigation-item>
      </ng-container>
    </nx-header-navigation>
  </ng-container>
  <nx-header-actions>
    <!--    <button nxPlainButton="">-->
    <!--      <nx-icon name="search" aria-label="Open Search Bar"></nx-icon>-->
    <!--    </button>-->


    <nx-link size="large">
      <a href="javascript:void(0)" class="nx-margin-left-s user-icon"
         scrollStrategy="close"
         [nxContextMenuTriggerFor]="menu">
        <nx-icon name="user-o" size="s" aria-label="Logged in"></nx-icon>
      </a>
    </nx-link>
    <nx-context-menu #menu="nxContextMenu">
      <ng-container *ngIf="authUser">
        <button disabled nxContextMenuItem>
          <nx-icon aria-hidden="true" name="user-o" class="nx-margin-right-s"></nx-icon>
          {{authUser.lastName}} {{authUser.firstName}} <br>{{authUser.email}}</button>
        <button nxContextMenuItem type="button" (click)="logout()">{{'general.logout' | caption}}</button>
      </ng-container>

    </nx-context-menu>
  </nx-header-actions>
</header>
