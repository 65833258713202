import {MyTreeNode} from "../../components/sidebar/sidebar.component";

class MyFlatTreeNode {
}

export const ADMIN_MENU: MyFlatTreeNode[] = [
  {
    icon: 'user',
    label: 'menu.users',
    query: 'users',
  },
  {
    icon: 'hdd',
    label: 'menu.measuring-devices',
    query: 'measuring-devices'
  },
  {
    icon: 'location-dot',
    label: 'menu.measuring-points',
    query: 'measuring-points'
  },
  {
    icon: 'people-group',
    label: 'menu.partners',
    query: 'customers'
  },  {
    icon: 'distributors',
    label: 'menu.distributors',
    query: 'distributors'
  },
  {
    icon: 'rocket',
    label: 'menu.contracts',
    query: 'contracts'
  },
  {
    icon: 'puzzle-piece',
    label: 'menu.addons',
    query: 'addons'
  },
  {
    icon: 'detectors',
    label: 'menu.detectors',
    query: 'detectors'
  },
  {
    icon: 'file-alt',
    label: 'menu.attachmentsAll',
    query: 'attachments',
  },
  {
    icon: 'file-code',
    label: 'menu.attachmentsForDetector',
    query: 'attachments/detector',
  },
];
