import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AttachmentService} from '../../../services/model/attachment.service';
import {Attachment} from '../../../models/attachment.model';

@Component({
  selector: 'app-attachment-upload',
  templateUrl: './attachment-upload.component.html',
  styleUrls: ['./attachment-upload.component.scss']
})
export class AttachmentUploadComponent implements OnInit, OnChanges {

  @Input() acceptString: string;
  @Input() isMultiple = false;
  @Input() showBodyHeader = true;
  @Input() saveSupplier: (attachment:any)=>void;

  public event: EventEmitter<any> = new EventEmitter();

  attachment: Attachment = new Attachment();
  public files: any[] = [];

  constructor(private service: AttachmentService) {
  }

  ngOnInit() {
    this.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.files.length > 0 && !this.isMultiple) {
      this.attachment.title = this.files[0].name
    }
  }

  save() {
    if (this.files.length < 1 || !this.attachment.title) {
      this.service.notificationService.showWarning('general.invalidForm', 'general.invalidForm');
      return;
    }
    this.attachment.slug = this.attachment.title;
    this.files.forEach((file) => {
      this.service.repo.save(file, this.attachment.copyWithoutId()).subscribe(resp => {
        this.saveSupplier(resp);
      });
    });
    this.clear();
  }

  private clear() {
    this.files = [];
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

}
