<nx-card class="table-card-viewer">
    <page-list-view-header
      [title]="'privacy.headline'"
    ></page-list-view-header>
  <pdf-viewer class="pdf-viewer" [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              [show-all]="true"
              [fit-to-page]="false"
              [zoom]="1"
              [zoom-scale]="'page-width'"
  ></pdf-viewer>
</nx-card>
