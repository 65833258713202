import {SearchOperationsEnum} from "./search-operations.enum";

export interface ISearchFilter {
  modelFieldName:string,
  operation:SearchOperationsEnum,
  filterValue:any
}
export class SearchFilterModel implements ISearchFilter{
  modelFieldName:string;
  operation:SearchOperationsEnum;
  filterValue:any;

  constructor(modelFieldName: string, operation: SearchOperationsEnum, filterValue?: any) {
    this.modelFieldName = modelFieldName;
    this.operation = operation;
    this.filterValue = filterValue;
  }
}
