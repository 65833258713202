import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MeasuringPoint} from '../../../models/measuring-point.model';
import {lastValueFrom} from 'rxjs';
import {MeasuringPointService} from '../../../services/model/measuring-point.service';
import {MyStorageService} from "../../../services/my-storage.service";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {TableAction, TableCol} from "ng-aquila-datatable";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {VIEW_CHANGER_CONTAINER, ViewChanger} from "../../../components/list-view-header/list-view-header.component";
import {MarkerElement} from "../../../components/map/map.component";
import {Distributor} from "../../../models/distributor.model";

@Component({
  selector: 'app-measuring-points',
  templateUrl: './measuring-points.component.html',
  styleUrls: ['./measuring-points.component.scss']
})
@PageTest({
  path: ROUTING.MeasuringPoint.basePlural,
  layout: AdminLayoutComponent
})
export class MeasuringPointsComponent extends AbstractListPage<MeasuringPoint[], MeasuringPointService> implements OnInit {

  colDef: TableCol[] = [];
  actionDef: TableAction[];

  @ViewChild('test')
  hdrTpl: TemplateRef<any>;

  viewChangers: VIEW_CHANGER_CONTAINER;
  mapModels: MarkerElement[];
  countryCodesInAddress: string[] = [];


  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: MeasuringPointService,
              translateService: AbstractCaptionService, myStorageService: MyStorageService,) {

    super(viewportService, cdr, translateService, service, myStorageService);
    this.viewChangers = {
      'mapView': new ViewChanger('mapView', true, 'map'),
      'tableView': new ViewChanger('tableView', false, 'table'),
    }
  }

  ngOnInit(): void {

    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];
    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('general.name')),
      TableCol.newString('distributor.name', await this.getTranslate('measuringDevice.distributor')),
      TableCol.newString('latitude', await this.getTranslate('general.latitude')),
      TableCol.newString('longitude', await this.getTranslate('general.longitude')),
      TableCol.newString('timezone', await this.getTranslate('general.timezone')),
      TableCol.newString('threshold', await this.getTranslate('general.threshold')),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(false));
    this.models.forEach(mp => {
      if(mp.distributor == null){
        mp.distributor = new Distributor();
        mp.distributor.name = "";
      }
    });
    this.mapModels = this.service.convertModelListTOMarkerElementList(this.models);

    this.countryCodesInAddress = [];
    this.countryCodesInAddress = [...new Set(this.models.map(model => model.address.countryCode.toLowerCase()))];
  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }


  markerClick(): (event: any) => void {
    return (e) => this.service.show(e.target.options.markerId);
  }

}
