import {MyTreeNode} from "../../components/sidebar/sidebar.component";

class MyFlatTreeNode {
}

export const DISTRIBUTOR_MENU: MyFlatTreeNode[] = [

  {
    icon: 'hdd',
    label: 'menu.measuring-devices',
    query: 'measuring-devices'
  },
  {
    icon: 'location-dot',
    label: 'menu.measuring-points',
    query: 'measuring-points'
  },
  {
    icon: 'rocket',
    label: 'menu.contracts',
    query: 'contracts'
  },
];
