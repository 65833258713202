import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Partner} from '../../../models/partner.model';
import {lastValueFrom} from 'rxjs';
import {AUTH_USER_KEY, ROLE_INDEX} from '../../../utilities/constants';
import {KeycloakProfile} from 'keycloak-js';
import {PartnerService} from '../../../services/model/partner.service';
import {MyStorageService} from "../../../services/my-storage.service";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {TableAction, TableCol, TableFilter} from "ng-aquila-datatable";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
@PageTest({
  path: ROUTING.Partner.basePlural,
  layout: AdminLayoutComponent
})
export class PartnersComponent extends AbstractListPage<Partner[], PartnerService> implements OnInit {

  colDef: TableCol[] = [];
  actionDef: TableAction[];

  @ViewChild('addressTpl')
  addressTpl: TemplateRef<any>;

  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: PartnerService,
              translateService: AbstractCaptionService,myStorageService: MyStorageService,) {
    super(viewportService, cdr, translateService, service, myStorageService);

  }

  ngOnInit(): void {
    this.loadData();

  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];
    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('partner.name')),
      TableCol.newMailto('contact.email', await this.getTranslate('partner.contact')),
      TableCol.newString('address', await this.getTranslate('partner.address'), TableFilter.newDefaultText(), ()=>{
        return this.addressTpl
      })
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted());
    this.models.map(m => m.address = JSON.parse(m.address));
  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }
}
