import {Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {Contract} from '../models/contract.model';

@RepoPath('/mgmt/admin/contracts')
@Injectable({
  providedIn: 'root'
})
export class ContractRepository extends BaseRepository<PostPayloadInterface> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('?deleted={deleted}')
  getAllByDeleted(deleted: boolean) {
    return super.get();
  }

  @Path('/measuring-points/{pointId}/measuring-devices/{deviceId}')
  getAllByMeasuringDeviceIdOrMeasuringPointId(pointId: number, deviceId: number) {
    return super.get();
  }

  @Path('/{id}')
  getById(id: number) {
    return super.get();
  }

  save(contract: Contract) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: contract
    });
  }

  @Path('/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  @Path('/{id}/unlock')
  adminUnlock(id: number) {
    return super.patch();
  }
  @Path('/{id}/interval')
  updateInterval(id: number, dto:any) {
    return super.patch(dto);
  }
  @Path('/{id}/measuring-devices/{deviceId}')
  updateDevice(id: number,deviceId:number) {
    return super.patch();
  }
  @Path('/{id}/measuring-points/{pointId}')
  updatePoint(id: number,pointId:number) {
    return super.patch();
  }
  @Path('/{id}/partners')
  updatePartner(id: number,dto:any) {
    return super.patch(dto);
  }
  @Path('/{id}/config')
  updateConfig(id: number, dto: any) {
    return super.patch(dto);
  }
}

