import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DistributorsComponent} from './distributors/distributors.component';
import {RouterModule} from '@angular/router';
import {EditDistributorsComponent} from './edit-distributor/edit-distributors.component';
import {ShowDistributorsComponent} from './show-distributors/show-distributors.component';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {ComponentsModule} from '../../components/components.module';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {FormsModule} from '@angular/forms';
import {NxDropdownModule} from "@aposin/ng-aquila/dropdown";
import {NxSwitcherModule} from "@aposin/ng-aquila/switcher";
import {NxImageModule} from "@aposin/ng-aquila/image";
import {PipeModule} from "../../pipe/pipe.module";


@NgModule({
  declarations: [
    DistributorsComponent,
    EditDistributorsComponent,
    ShowDistributorsComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NxCardModule,
        NxIconModule,
        ComponentsModule,
        NxHeadlineModule,
        NxGridModule,
        NxButtonModule,
        NxFormfieldModule,
        NxInputModule,
        FormsModule,
        NxDropdownModule,
        NxSwitcherModule,
        NxImageModule,
        PipeModule,
    ]
})
export class DistributorModule {
}
