import {Component, OnDestroy, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {Attachment} from "../../../models/attachment.model";
import {AttachmentService} from "../../../services/model/attachment.service";
import {PageTest} from "../../../utilities/PageTest";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {ROUTING} from "../../../utilities/routing-constants";

@Component({
  selector: 'app-edit-attachments',
  templateUrl: './edit-attachments.component.html',
  styleUrls: ['./edit-attachments.component.scss']
})
@PageTest({
  path:['attachment/edit', 'attachment/edit/:id'],
  pathPrefix:ROUTING.Attachment.basePlural,
  layout: AdminLayoutComponent
})
export class EditAttachmentsComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  model: Attachment = new Attachment();

  constructor(private service: AttachmentService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      if (id) {
        this.model = await lastValueFrom(this.service.repo.getById(id));
      }
      if (this.model == null) {
        this.model = new Attachment();
      }
    });
  }

  public saveCallback(resp: any) {
    this.service.notificationService.showSuccess('general.success', 'attachments.successSave');
    this.service.navigateToBase();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
