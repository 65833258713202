export enum SearchOperationsEnum {
  GREATER_THAN = "GREATER_THAN",
  LESS_THAN = "LESS_THAN",
  GREATER_THAN_EQUAL = "GREATER_THAN_EQUAL",
  LESS_THAN_EQUAL = "LESS_THAN_EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  EQUAL = "EQUAL",
  MATCH = "MATCH",
  MATCH_START = "MATCH_START",
  MATCH_END = "MATCH_END",
  NOT_MATCH = "NOT_MATCH",
  MATCH_NOT_START = "MATCH_NOT_START",
  MATCH_NOT_END = "MATCH_NOT_END",
  IN = "IN",
  NOT_IN = "NOT_IN",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL"
}
