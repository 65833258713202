import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './loader/loader.component';
import {HeaderComponent} from './header/header.component';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {NxHeaderModule} from '@aposin/ng-aquila/header';
import {NxLinkModule} from '@aposin/ng-aquila/link';
import {NxContextMenuModule} from '@aposin/ng-aquila/context-menu';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NxSidebarModule} from '@aposin/ng-aquila/sidebar';
import {NxActionModule} from '@aposin/ng-aquila/action';
import {RouterModule} from '@angular/router';
import {NxTooltipModule} from '@aposin/ng-aquila/tooltip';
import {NxLabelModule} from '@aposin/ng-aquila/base';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {NxTableModule} from '@aposin/ng-aquila/table';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {PrintPreComponent} from './print-pre/print-pre.component';
import {TranslateModule} from '@ngx-translate/core';
import {NxDropdownModule} from '@aposin/ng-aquila/dropdown';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {
  ImageAttachmentElementComponent
} from './attachment/image-attachment-element/image-attachment-element.component';
import {AttachmentHandlerComponent} from './attachment/attachment-handler/attachment-handler.component';
import {AttachmentUploadComponent} from './attachment/attachment-upload/attachment-upload.component';
import {DragAndDropComponent} from './attachment/drag-and-drop/drag-and-drop.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DirectivesModule} from '../directives/directives.module';
import {ListViewHeaderComponent} from './list-view-header/list-view-header.component';
import {NxBadgeModule} from "@aposin/ng-aquila/badge";
import {NxPaginationModule} from "@aposin/ng-aquila/pagination";
import {AddImageToModelComponent} from './modal/add-image-to-model/add-image-to-model.component';
import {AddNoteToModelComponent} from './modal/add-note-to-model/add-note-to-model.component';
import {TextFieldModule} from "@angular/cdk/text-field";
import {NxToolbarModule} from "@aposin/ng-aquila/toolbar";
import {NxModalModule} from "@aposin/ng-aquila/modal";
import {NxCardModule} from "@aposin/ng-aquila/card";
import {NxAccordionModule} from "@aposin/ng-aquila/accordion";
import {FormToolBarComponent} from './form-tool-bar/form-tool-bar.component';
import {AddAddressToModelComponent} from './modal/add-address-to-model/add-address-to-model.component';
import {FormHeaderComponent} from './form-header/form-header.component';
import {ShowToolBarComponent} from './show-tool-bar/show-tool-bar.component';
import {MapComponent} from './map/map.component';
import {ShowPartnerComponent} from './inside-show-components/show-partner/show-partner.component';
import {
  ShowMeasuringDeviceComponent
} from './inside-show-components/show-measuring-device/show-measuring-device.component';
import {
  ShowMeasuringPointComponent
} from './inside-show-components/show-measuring-point/show-measuring-point.component';
import {ShowDetectorComponent} from './inside-show-components/show-detector/show-detector.component';
import {NxDataDisplayModule} from "@aposin/ng-aquila/data-display";
import {NxImageModule} from "@aposin/ng-aquila/image";
import {ShowUserContactComponent} from './inside-show-components/show-user-contact/show-user-contact.component';
import {PipeModule} from "../pipe/pipe.module";
import {
  ShowMeasuringDeviceDataComponent
} from './inside-show-components/show-measuring-device-data/show-measuring-device-data.component';
import {NxPopoverModule} from "@aposin/ng-aquila/popover";
import {PortalModule} from "@angular/cdk/portal";
import {ShowOnlineDeviceComponent} from './modal/show-online-device/show-online-device.component';
import {NxTabsModule} from "@aposin/ng-aquila/tabs";
import {ToggleHiddenVisibilityComponent} from './toggle-hidden-visibility/toggle-hidden-visibility.component';
import {NoteHandlerComponent} from './note-handler/note-handler.component';
import {NxNotificationPanelModule} from "@aposin/ng-aquila/notification-panel";
import {NxCopytextModule} from "@aposin/ng-aquila/copytext";
import {ShowImageComponent} from './attachment/show-image/show-image.component';
import {NxDatefieldModule} from "@aposin/ng-aquila/datefield";
import {ShowQrCodeComponent} from './modal/show-qr-code/show-qr-code.component';
import {QRCodeModule} from "angular2-qrcode";
import {UserFormComponent} from './forms/user-form/user-form.component';
import {NxPhoneInputModule} from "@aposin/ng-aquila/phone-input";
import {AddUserToModelComponent} from './modal/add-user-to-model/add-user-to-model.component';
import {AddressFormComponent} from './forms/address-form/address-form.component';
import {UserRoleFormComponent} from './forms/user-role-form/user-role-form.component';
import {NxRadioModule} from "@aposin/ng-aquila/radio-button";
import {IrfComponentsModule} from "common";
import {NxTreeModule} from "@aposin/ng-aquila/tree";
import {NxSwitcherModule} from "@aposin/ng-aquila/switcher";
import {NxSliderModule} from "@aposin/ng-aquila/slider";
import {UserCardComponent} from './list-view-card/usercard/user-card.component';
import {MeasuringDeviceFormComponent} from './forms/measuring-device-form/measuring-device-form.component';

import {MeasuringPointFormComponent} from './forms/measuring-point-form/measuring-point-form.component';
import {AddContractToModelComponent} from './modal/add-contract-to-model/add-contract-to-model.component';
import {ContractFormComponent} from './forms/contract-form/contract-form.component';
import {NxMessageModule} from "@aposin/ng-aquila/message";
import {DetectorFormComponent} from './forms/detector-form/detector-form.component';
import {IrfCaptionModule} from "irf-caption";
import {
  ShowContractSlimDataComponent
} from './inside-show-components/show-contract-slim-data/show-contract-slim-data.component';
import {AddonFormComponent} from './forms/addon-form/addon-form.component';
import {AddPartnerToModelComponent} from './modal/add-partner-to-model/add-partner-to-model.component';
import {FormlyModule} from "@ngx-formly/core";
import {GantChartComponent} from './gant-chart/gant-chart.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {ColorChromeModule} from 'ngx-color/chrome';
import {EditContractFormComponent} from './forms/edit-contract-form/edit-contract-form.component';
import {SelectContractEditTypeComponent} from './modal/select-contract-edit-type/select-contract-edit-type.component';
import {DeviceLogViewerComponent} from "./device-log-viewer/device-log-viewer.component";
import {GantChartOptionBComponent} from "./gant-chart-option-b/gant-chart-option-b.component";
import {
  ShowMeasuringPointDataComponent
} from './inside-show-components/show-measuring-point-data/show-measuring-point-data.component';
import {NgAquilaDatatableModule} from "ng-aquila-datatable";
import { AddNewWebhookComponent } from './modal/add-new-webhook/add-new-webhook.component';
import { WebhookFormComponent } from './forms/webhook-form/webhook-form.component';

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    SidebarComponent,
    PrintPreComponent,
    DeviceLogViewerComponent,
    ImageAttachmentElementComponent,
    AttachmentHandlerComponent,
    AttachmentUploadComponent,
    DragAndDropComponent,
    ListViewHeaderComponent,
    AddImageToModelComponent,
    AddNoteToModelComponent,
    FormToolBarComponent,
    AddAddressToModelComponent,
    FormHeaderComponent,
    ShowToolBarComponent,
    MapComponent,
    ShowPartnerComponent,
    ShowMeasuringDeviceComponent,
    ShowMeasuringPointComponent,
    ShowDetectorComponent,
    ShowUserContactComponent,
    ShowMeasuringDeviceDataComponent,
    ShowOnlineDeviceComponent,
    ToggleHiddenVisibilityComponent,
    NoteHandlerComponent,
    ShowImageComponent,
    ShowQrCodeComponent,
    UserFormComponent,
    AddUserToModelComponent,
    AddressFormComponent,
    UserRoleFormComponent,
    UserCardComponent,
    MeasuringDeviceFormComponent,
    MeasuringPointFormComponent,
    AddContractToModelComponent,
    ContractFormComponent,
    DetectorFormComponent,
    ShowContractSlimDataComponent,
    AddonFormComponent,
    AddPartnerToModelComponent,
    GantChartComponent,
    EditContractFormComponent,
    SelectContractEditTypeComponent,
    GantChartOptionBComponent,
    ShowMeasuringPointDataComponent,
    AddNewWebhookComponent,
    WebhookFormComponent,
  ],
  imports: [
    CommonModule,
    NxIconModule,
    NxHeaderModule,
    NxLinkModule,
    NxContextMenuModule,
    NxButtonModule,
    NxSidebarModule,
    NxActionModule,
    RouterModule,
    NxTooltipModule,
    NxLabelModule,
    FormsModule,
    NgxDatatableModule,
    NxFormfieldModule,
    NxInputModule,
    NxTableModule,
    NxHeadlineModule,
    TranslateModule,
    NxDropdownModule,
    NxGridModule,
    IrfCaptionModule,
    DragDropModule,
    DirectivesModule,
    NxBadgeModule,
    NxPaginationModule,
    TextFieldModule,
    NxToolbarModule,
    NxModalModule,
    NxCardModule,
    NxAccordionModule,
    NxDataDisplayModule,
    NxImageModule,
    PipeModule,
    NxPopoverModule,
    PortalModule,
    NxTabsModule,
    NxNotificationPanelModule,
    NxCopytextModule,
    NxDatefieldModule,
    QRCodeModule,
    NxPhoneInputModule,
    NxRadioModule,
    IrfComponentsModule,
    NxTreeModule,
    NxSwitcherModule,
    NxSliderModule,
    NgAquilaDatatableModule.forRoot({
      filter: 'filter',
      page: 'Page',
      elementsPerPage: 'Elements per page',
      showFilter: 'Show filter',
      items: 'Items',
      clearFilter: 'Clear filter',
      active: 'active',
      true: 'True',
      false: 'False'
    }),
    NxMessageModule,
    FormlyModule,
    ReactiveFormsModule,
    NgApexchartsModule, ColorChromeModule
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    SidebarComponent,
    PrintPreComponent,
    AttachmentUploadComponent,
    AttachmentHandlerComponent,
    ListViewHeaderComponent,
    ImageAttachmentElementComponent,
    AddImageToModelComponent,
    AddNoteToModelComponent,
    FormToolBarComponent,
    FormHeaderComponent,
    ShowToolBarComponent,
    MapComponent,
    ShowPartnerComponent,
    ShowDetectorComponent,
    ShowMeasuringPointComponent,
    ShowMeasuringDeviceComponent,
    ShowMeasuringDeviceDataComponent,
    NoteHandlerComponent,
    ShowImageComponent,
    UserFormComponent,
    MeasuringDeviceFormComponent,
    NgAquilaDatatableModule,
    MeasuringPointFormComponent,
    ContractFormComponent,
    DetectorFormComponent,
    IrfCaptionModule,
    ShowContractSlimDataComponent,
    AddonFormComponent,
    GantChartComponent,
    EditContractFormComponent,
    DeviceLogViewerComponent,
    GantChartOptionBComponent,
    AddressFormComponent,
    ShowMeasuringPointDataComponent,
  ]
})
export class ComponentsModule {
}
