import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {AddonRepository} from '../../repositories/addon-repository';
import {IrfNotificationService} from "irf-services";

@Injectable({
  providedIn: 'root'
})
export class AddonService extends BaseModelService<AddonRepository> {
  getById(id: any) {
    return this.repo.getById(id);
  }

  constructor(repo: AddonRepository, notificationService: IrfNotificationService, router: Router,) {
    super(ROUTING.Addon, repo, notificationService, router);
  }

}
