import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {AddressModel} from "./address.model";

@Component({
  selector: 'app-add-address-to-model',
  templateUrl: './add-address-to-model.component.html',
  styleUrls: ['./add-address-to-model.component.scss']
})
export class AddAddressToModelComponent implements OnInit {
  address:AddressModel;
  constructor(@Inject(NX_MODAL_DATA) public model: any,) { }

  ngOnInit(): void {
    if(this.model.address){
      this.address = {...this.model.address};
    }else{
      this.address = new AddressModel();
    }
  }

}
