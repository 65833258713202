import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {PartnerRepository} from '../../repositories/partner-repository';
import {IrfNotificationService} from "irf-services";
import {Partner, PartnerUser} from "../../models/partner.model";
import {ValidatorService} from "../validator.service";
import {PARTNER_USER_TYPES} from "../../utilities/constants";
import {map} from "rxjs";
import {DistributorRepository} from "../../repositories/distributor-repository";
import {Distributor, DistributorUser} from "../../models/distributor.model";

@Injectable({
  providedIn: 'root'
})
export class DistributorService extends BaseModelService<DistributorRepository> {

  constructor(repo: DistributorRepository, notificationService: IrfNotificationService, router: Router,) {
    super(ROUTING.Distributor, repo, notificationService, router);
  }

  getAllByDeleted(dtoType?:string,deleted = false) {
    if(dtoType){
      return this.repo.getAllByDeletedAndDtoType(deleted, dtoType).pipe(
        map(distributor => this.addExtendedPropertiesForModelList(distributor))
      );
    }else{
      return this.repo.getAllByDeleted(deleted).pipe(
        map(distributor => this.addExtendedPropertiesForModelList(distributor))
      );
    }
  }

  getById(id: string) {
    return this.repo.getById(id).pipe(
      map(partner => this.addExtendedPropertiesForModel(partner))
    );
  }

  getDistributorForUser(userKey: string) {
    return this.repo.getDistributorForUser(userKey);
  }
  getDistributorForUserAsAdmin(userKey: string) {
    return this.repo.getDistributorForUserAsAdmin(userKey);
  }

  override addExtendedPropertiesForModel(model: any): any {
    if (model.users) {
      const emails:string[] = [];
      const tmp = model.users.map((user: any) => {
        if(user.user) {
          const a = new DistributorUser(user.user.keycloakId);
          a.user = user.user;
          return a;
        }else{
          return new DistributorUser(user.userKey);
        }
      });
      model.users = [...tmp];
      model.contactEmails = emails.concat(', ');
    }
    return model;
  }

  override validate(editableRow: Distributor) {
    const attributeBlackList = ['id', 'slug', 'createdAt', 'updatedAt', 'rules', 'measuringDevices', 'measuringPoints', 'addons', 'contracts', 'contact', 'attachment'];
    const requiredList = ['attachmentId','users' ];
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    requiredList.forEach(key => {
      if (!attributeList.includes(key)) {
        attributeList.push(key);
      }
    });
    ValidatorService.allRequired(editableRow, attributeList, 'Partner');
  }

}
