import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Detector} from '../../../models/detector.model';
import {lastValueFrom} from 'rxjs';
import {AUTH_USER_KEY, ROLE_INDEX} from '../../../utilities/constants';
import {KeycloakProfile} from 'keycloak-js';
import {DetectorService} from '../../../services/model/detector.service';
import {MyStorageService} from "../../../services/my-storage.service";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {TableAction, TableCol} from "ng-aquila-datatable";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";

@Component({
  selector: 'app-detectors',
  templateUrl: './detectors.component.html',
  styleUrls: ['./detectors.component.scss']
})
@PageTest({
  path: ROUTING.Detector.basePlural,
  layout: AdminLayoutComponent
})
export class DetectorsComponent extends AbstractListPage<Detector[], DetectorService> implements OnInit {

  colDef: TableCol[] = [];
  actionDef: TableAction[];

  @ViewChild('test') hdrTpl: TemplateRef<any>;


  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: DetectorService,
              translateService: AbstractCaptionService, myStorageService: MyStorageService,) {
    super(viewportService, cdr, translateService, service, myStorageService);

  }

  ngOnInit(): void {

    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];
    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('general.name')),
      TableCol.newString('versionNumber', await this.getTranslate('detector.versionNumber')),
      TableCol.newBoolean('remoteInstall', await this.getTranslate('detector.remoteInstall'), false),
      TableCol.newString('uuId', await this.getTranslate('detector.uuId')),
    ];
    this.models = await lastValueFrom(this.service.getAllByDeleted(false));
  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('general.warning', 'general.areUSure', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }

}
