import {HttpResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ConfiguratorRepository} from "../../repositories/configurator-repository";
import {IrfNotificationService} from "irf-services";

type logType = {
  name: string,
  type: string
}

@Component({
  selector: 'app-device-log-viewer',
  templateUrl: './device-log-viewer.component.html',
  styleUrls: ['./device-log-viewer.component.css']
})
export class DeviceLogViewerComponent implements OnInit {
  logTypeIndex = 0;
  logTypes: logType[] = [
    {name: "rvc_services", type: "user"},
    {name: "rvc_detector", type: "user"},
    {name: "rvc_uploader", type: "user"}
  ];
  days: number = 1;
  logs: string[] = [];

  constructor(
    private configuratorRepo: ConfiguratorRepository, private notificationService: IrfNotificationService) {

  }

  ngOnInit(): void {
    this.getLogs();
  }

  getLogs(): void {
    const seconds = this.days * 24 * 60 * 60;
    const logType = this.logTypes[this.logTypeIndex]
    this.configuratorRepo.getLogs(`${logType.name}.service`, logType.type, seconds).subscribe((data: any) => {
      this.logs = data;
      if (this.logs.every(str => str === "")) {
        this.notificationService.showInfo('general.info', `There is no logs in the past ${this.days} days.`, "toastr");
      }
    });
  }

  getLogFile(): void {
    const seconds = this.days * 24 * 60 * 60;
    const logType = this.logTypes[this.logTypeIndex];
    this.configuratorRepo.getLogFile(`${logType.name}.service`, logType.type, seconds).subscribe((response: HttpResponse<Blob>) => {
      if (response.body) {
        const url = window.URL.createObjectURL(response.body);
        const a = document.createElement('a');
        a.href = url;
        const date = new Date().toISOString().slice(0, 10);
        a.download = `\`${logType.name}-service-logs-${date}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
  }
}
