import {Component, Input, OnInit} from '@angular/core';
import {Contract} from "../../../models/contract.model";

@Component({
  selector: 'inside-show-contract-slim-data',
  templateUrl: './show-contract-slim-data.component.html',
  styleUrls: ['./show-contract-slim-data.component.scss']
})
export class ShowContractSlimDataComponent implements OnInit {

   @Input() model:Contract;

  constructor() { }

  ngOnInit(): void {
  }

}
