<ng-container *ngIf="model">
  <nx-card class="p-0 w-100">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.name"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>
    <div nxRow="" class="nx-margin-top-s">
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <img [src]="model.attachment.srcWidth" alt="{{model.name}}" width="50"/>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.name}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,8,9">
        <div nxRow="">
          <ng-container *ngFor="let userEntry of model.users">
            <div nxCol="12,12,6,4,3">
              <nx-card class="box">
                <nx-card-header>
                  <p>{{userEntry.user.email}}<br> {{userEntry.user.phone}}</p>
                  <h3 class="nx-margin-y-s">
                    {{userEntry.user.firstName}} {{userEntry.user.lastName}}
                  </h3>
                </nx-card-header>
              </nx-card>
            </div>
          </ng-container>
        </div>
      </div>
      <div nxCol="12">
        {{model.address | address}}
      </div>
    </div>
  </nx-card>
</ng-container>
