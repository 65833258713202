export class FormToolBarOption {
  openModalSupplier: () => void;
  iconName: string;
  name: string
  disable: boolean;

  constructor(openModalSupplier: () => void, iconName: string, name: string, disable: boolean = false) {
    this.openModalSupplier = openModalSupplier;
    this.iconName = iconName;
    this.name = name;
    this.disable = disable;
  }
}
