import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient, HttpParams} from '@angular/common/http';

import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {FilterPayloadInterface} from "../services/filterer/filter-payload-interface";

@RepoPath('/raw-data/admin/meta-data')
@Injectable({
  providedIn: 'root'
})
export class RawDataRepository extends BaseRepository<PostPayloadInterface> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/filter')
  getAllByFilter(filter: FilterPayloadInterface) {
    const options = {params: new HttpParams().append('filter', JSON.stringify(filter))};
    return super.get(undefined, options);
  }
}

