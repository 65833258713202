import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements OnInit {

  @Input() files: any[];
  @Input() acceptString = 'image/*';
  @Input() isMultiple = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.target.files);
  }

  // fileBrowseHandler(files: any) {
  //   .target.files
  //   this.prepareFilesList(files);
  // }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
  }

}
