import {lastValueFrom, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {BaseModelService} from "../services/model/base-model.service";
import {BaseModel} from "./base.model";
import {PermissionCheckComponent} from "./PermissionCheckComponent";
import {MyStorageService} from "../services/my-storage.service";

export class AbstractShowPage<MODEL extends BaseModel, SERVICE extends BaseModelService<any>> extends PermissionCheckComponent{
  model: MODEL;
  protected sub: Subscription;

  constructor(protected service: SERVICE,
              protected route: ActivatedRoute,
              myStorageService:MyStorageService) {
    super(myStorageService);
  }

  onInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      this.model = await lastValueFrom(this.service.getById(params['id']));
      if (this.model == null) {
        this.service.notificationService.swalAlertPromise('warning', 'general.warning', 'error.modelNotExsist').then((result: any) => {
          this.service.navigateToBase();
        });
      }
      this.initProcess();
    });
  }

  onDestroy(): void {
    this.sub.unsubscribe();
  }

  back() {
    return () => this.service.navigateToBase();

  }

  edit() {
    return () => this.service.edit(this.model.id);
  }

  protected initProcess() {

  }
}
