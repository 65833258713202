import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Addon} from "../../../models/addon.model";
import {AbstractBaseComponent} from "common";

@Component({
  selector: 'forms-addon-form',
  templateUrl: './addon-form.component.html',
  styleUrls: ['./addon-form.component.scss']
})
export class AddonFormComponent extends AbstractBaseComponent implements OnInit,OnChanges {

  @Input() model: Addon;

  constructor() {
    super();
  }

  async ngOnInit(): Promise<void> {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      console.log('Model has changed:', changes['model'].currentValue);
    }
  }
  changeChartTypes() {
    console.log(this.model);
  }

  changeMenuConfig() {
    console.log(this.model);
  }
}
