<nx-card class="viewer">
  <nx-card-header >
    <page-form-header [model]="model" [titleProperty]="'title'"></page-form-header>
  </nx-card-header>
  <app-attachment-upload
    [acceptString]="'image/*,application/zip,application/pdf'"
    [isMultiple]="false"
    [saveSupplier]="saveCallback.bind(this)"
  ></app-attachment-upload>

</nx-card>
