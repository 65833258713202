import {OrderByModel} from "./orderBy.model";

export interface FilterPayloadInterface {
  dtoType:string,
  filters:any[]
  groupByAttributes:string[];
  orderByFilters:OrderByModel[];
}
export class FilterRequestPayload implements FilterPayloadInterface{
  groupByAttributes: string[];
  orderByFilters: OrderByModel[];
  dtoType:string;
  filters:any[];
  constructor(dtoType:string) {
    this.dtoType = dtoType;
    this.filters = [];
    this.groupByAttributes = [];
    this.orderByFilters = [];
  }

}
