import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import Swal from "sweetalert2";
import {AUTH_USER_KEY, ROLE_INDEX} from "./constants";
import {MyStorageService} from "../services/my-storage.service";
import {AbstractCaptionService} from "irf-caption";

export abstract class KeycloakBaseAuthGuard extends KeycloakAuthGuard implements CanActivate {
  private authUser: any;

  protected constructor(protected override router: Router, protected override keycloakAngular: KeycloakService, protected translateService: AbstractCaptionService, protected myStorageService: MyStorageService, protected role: string) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      const userInRole = this.keycloakAngular.isUserInRole(this.role);
      if (userInRole) {
        await this.checkPermission(state, resolve);
      } else {
        this.resolveWithWarning(userInRole, resolve, '/');
      }
    });
  }

  protected async checkPermission(state: RouterStateSnapshot, resolve: (value: (PromiseLike<boolean | UrlTree> | boolean | UrlTree)) => void) {
    await this.getAuthUser();
    if (state.url.includes("edit")) {
      const splitUrl = state.url.split("edit");
      const showUrl = splitUrl[0];
      const chmodElement = this.authUser.attributes.chmod[ROLE_INDEX[this.role]];
      const hasEdit = chmodElement > 4;
      this.resolveWithWarning(hasEdit, resolve, showUrl.slice(0, -1));
    } else {
      resolve(true);
    }
  }

  protected async getAuthUser() {
    let authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, undefined);
    if (authUser == undefined) {
      authUser = await this.keycloakAngular.getKeycloakInstance().loadUserProfile();
      const b: any = await this.keycloakAngular.getKeycloakInstance().loadUserInfo();
      authUser.id = b.sub;
      this.myStorageService.saveToCookies(AUTH_USER_KEY, authUser);
    }else{
      authUser.attributes.chmod = JSON.parse(authUser.attributes.chmod);
    }
    this.authUser = authUser;

  }

  protected async resolveWithWarning(result: boolean, resolve: (value: (PromiseLike<boolean | UrlTree> | boolean | UrlTree)) => void, redirectUrl: string) {
    if (!result) {
      await Swal.fire(
        (await this.translateService.getCaption('general.warning')).nev,
        (await this.translateService.getCaption('error.dontHaveRole')).nev,
        'warning'
      );
      resolve(this.router.parseUrl(redirectUrl))
    } else {
      resolve(result);
    }
  }
}
