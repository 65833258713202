import {Component, Input, OnInit} from '@angular/core';
import {Detector} from "../../../models/detector.model";
import {lastValueFrom} from "rxjs";
import {AttachmentService} from "../../../services/model/attachment.service";
import {Attachment} from "../../../models/attachment.model";

@Component({
  selector: 'forms-detector-form',
  templateUrl: './detector-form.component.html',
  styleUrls: ['./detector-form.component.scss']
})
export class DetectorFormComponent implements OnInit {

  @Input() model: Detector;

  attachments: Attachment[];

  constructor(
    private attachmentService: AttachmentService,) {
  }

  async ngOnInit(): Promise<void> {

    this.attachments = await lastValueFrom(this.attachmentService.getAllForDetector());
  }

}
