<div nxRow="">
  <div nxCol="12,12,12,6">
    <div nxRow="">
      <div nxCol="12">
        <nx-formfield nxLabel="{{'user.roles' | caption}} *">
          <nx-multi-select [(ngModel)]="user.roles" name="roles" (selectionChange)="setDefaultChmod()"
                           [options]="staticRoles"
          ></nx-multi-select>
        </nx-formfield>
      </div>
      <ng-container *ngIf="user.roles.includes('Partner') && partners && userPartner">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'user.partners' | caption}}">
            <nx-multi-select [(ngModel)]="userPartner" name="partners"
                             [options]="partners"
                             [filter]="true"
                             selectLabel="name"
                             selectValue="id"
                             (selectionChange)="changeUserPartner()"
            ></nx-multi-select>
          </nx-formfield>
        </div>
      </ng-container>
      <ng-container *ngIf="user.roles.includes('distributor') && distributors && userDistributor">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'user.distributor' | caption}}">
            <nx-multi-select [(ngModel)]="userDistributor" name="distributors"
                             [options]="distributors"
                             [filter]="true"
                             selectLabel="name"
                             selectValue="id"
                             (selectionChange)="changeUserDistributor()"
            ></nx-multi-select>
          </nx-formfield>
        </div>
      </ng-container>
    </div>
  </div>
  <div nxCol="12,12,12,6">
    <div nxRow="">
      <div nxCol="12">
        <ng-container *ngFor="let role of user.roles">
          <nx-radio-group [(ngModel)]="user.chmod[roleIndex[role]]" [name]="'chmod'+role">
            <nx-label>{{role}} {{'user.role' | caption}}</nx-label>
            <div class="horizontal-checkboxes">
              <nx-radio nxValue="4" class="nx-margin-bottom-s">
                {{'general.read' | caption}}
              </nx-radio>
              <nx-radio nxValue="6" class="nx-margin-bottom-s">
                {{'general.readAndWrite' | caption}}
              </nx-radio>
            </div>
          </nx-radio-group>
        </ng-container>
      </div>
    </div>
  </div>
</div>
