<div nxModalContent >
  <nx-card class="w-100 h-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.addPartnerToModel' | caption}}
      </h3>
    </nx-card-header>
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12">
          <ng-container *ngIf="partners">
            <nx-formfield nxLabel="{{'addon.partners' | caption}}">
              <nx-multi-select filter
                               [(ngModel)]="selectedPartnerKeys" name="partners"
                               [options]="partners"
                               [selectLabel]="getLabel()"
                               [selectValue]="'id'"
                               (selectionChange)="partnerSelectionChange()"
              ></nx-multi-select>
            </nx-formfield>
          </ng-container>
        </div>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="modelPartners"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | caption}}
  </button>
</div>
