import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {MeasuringPoint} from '../models/measuring-point.model';

@RepoPath('/mgmt/distributor/measuring-points')
@Injectable({
  providedIn: 'root'
})
export class MeasuringPointDistributorRepository extends BaseRepository<PostPayloadInterface> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/{distributorId}/for-distributor?deleted={deleted}')
  getAllByDeleted(distributorId:number,deleted: boolean) {
    return super.get();
  }

  @Path('/{distributorId}/without-contract?start={start}&end={end}')
  getAllWithoutContract(distributorId:number,start: string, end: string) {
    return super.get();
  }

  @Path('/{id}')
  getById(id: string) {
    return super.get();
  }

  save(measuringPoint: MeasuringPoint) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: measuringPoint
    });
  }

  @Path('/{id}')
  update(id: number, measuringPoint: MeasuringPoint) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: measuringPoint
    });
  }

  @Path('/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  @Path('/{id}/attachments/{attachmentId}/add')
  addAttachment(id: number, attachmentId: number) {
    return super.patch();
  }

  @Path('/{id}/attachments/{attachmentId}/remove')
  removeAttachment(id: number, attachmentId: number) {
    return super.patch();
  }

  @Path('/{id}/notes/{noteId}/add')
  addNote(id: number, noteId: number) {
    return super.patch();
  }


}

