import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DetectorsComponent} from './detectors/detectors.component';
import {RouterModule} from '@angular/router';
import {EditDetectorsComponent} from './edit-detectors/edit-detectors.component';
import {ShowDetectorsComponent} from './show-detectors/show-detectors.component';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {ComponentsModule} from '../../components/components.module';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {FormsModule} from '@angular/forms';
import {NxSwitcherModule} from "@aposin/ng-aquila/switcher";
import {TextFieldModule} from "@angular/cdk/text-field";
import {NxDataDisplayModule} from "@aposin/ng-aquila/data-display";
import {NxCopytextModule} from "@aposin/ng-aquila/copytext";
import {NxToolbarModule} from "@aposin/ng-aquila/toolbar";


@NgModule({
  declarations: [
    DetectorsComponent,
    EditDetectorsComponent,
    ShowDetectorsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NxCardModule,
    NxIconModule,
    ComponentsModule,
    NxHeadlineModule,
    NxGridModule,
    NxButtonModule,
    NxFormfieldModule,
    NxInputModule,
    FormsModule,
    NxSwitcherModule,
    TextFieldModule,
    NxDataDisplayModule,
    NxCopytextModule,
    NxToolbarModule,
  ]
})
export class DetectorModule {
}
