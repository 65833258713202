<nx-card class="viewer">
  <nx-card-header>
    <page-form-header [model]="model" [titleProperty]="'name'"></page-form-header>
  </nx-card-header>

  <page-form-tool-bar
    [saveSupplier]="save()"
    [cancelSupplier]="cancel()"
    [modalOptions]="toolbarAddOptions"
  >
  </page-form-tool-bar>

  <forms-measuring-point-form [model]="model" [distributors]="distributorForUsers"></forms-measuring-point-form>
  <ng-container *ngIf="contract">
    <inside-show-contract-slim-data [model]="contract"></inside-show-contract-slim-data>
  </ng-container>

</nx-card>
