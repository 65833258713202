import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {MeasuringPointRepository} from '../../repositories/measuring-point-repository';
import {IrfNotificationService} from "irf-services";
import {MeasuringPoint} from "../../models/measuring-point.model";
import {ValidatorService} from "../validator.service";
import {map} from "rxjs";
import {Contract} from "../../models/contract.model";
import {MarkerElement, OFFLINE_ICON, ONLINE_ICON} from "../../components/map/map.component";

@Injectable({
  providedIn: 'root'
})
export class MeasuringPointService extends BaseModelService<MeasuringPointRepository> {

  constructor(repo: MeasuringPointRepository, notificationService: IrfNotificationService, router: Router,) {
    super(ROUTING.MeasuringPoint, repo, notificationService, router);
  }

  getAllByDeleted(deleted: boolean) {
    return this.repo.getAllByDeleted(deleted).pipe(
      map(points => this.addExtendedPropertiesForModelList(points))
    )
  }

  getAllWithoutContract(start:string, end:string) {
    return this.repo.getAllWithoutContract(start, end).pipe(
      map(points => this.addExtendedPropertiesForModelList(points))
    )
  }

  getById(id: string) {
    return this.repo.getById(id).pipe(
      map(point => this.addExtendedPropertiesForModel(point))
    )
  }

  override addExtendedPropertiesForModel(model: any): any {
    if (model.notes) {
      model.notes = model.notes.map(note => note.note).sort((a, b) => (a.id > b.id ? -1 : 1));
    }
    if (model.attachments) {
      model.attachments = model.attachments.map(attachment => attachment.attachment);
    }
    return model;
  }


  override validate(editableRow: MeasuringPoint) {
    const attributeBlackList = ['id', 'attachments', 'notes']
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    ValidatorService.allRequired(editableRow, attributeList, 'MeasuringPoint');
    ValidatorService.validateAddress(editableRow.address, 'MeasuringPoint')
  }

  convertModelListTOMarkerElementList(measuringPoints: MeasuringPoint[]): MarkerElement[] {
    const tmp: MarkerElement[] = [];
    for (const measuringPoint of measuringPoints) {
      tmp.push(this.convertModelToMarkerElement(measuringPoint));
    }
    return tmp;
  }

  convertModelToMarkerElement(measuringPoint: MeasuringPoint): MarkerElement {
    if (measuringPoint) {
      return new MarkerElement(
        measuringPoint.latitude,
        measuringPoint.longitude,
        measuringPoint.id,
        measuringPoint.name,
        measuringPoint.description,
        OFFLINE_ICON
      );
    }
    return new MarkerElement(
      0,
      0,
      0,
      'UNKNOWN',
      'UNKNOWN',
    );
  }

}
