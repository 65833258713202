<nx-card class="table-card-viewer">
    <page-list-view-header
      [newSupplier]="new()"
      [title]="'user.headline'"
      [(viewChangers)]="viewChangers"
    >
    </page-list-view-header>

  <aquila-table [rows]="models"
               [colDefinitions]="colDef"
               [actionConfig]="actionDef"
               [showAsList]="mobile"
               [listElementComponent]="listViewCard"
  ></aquila-table>
</nx-card>

<ng-template #partner let-value="value">
  {{value | joint:'name'}}
</ng-template>



