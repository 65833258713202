import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-start-layout',
  templateUrl: './start-layout.component.html',
  styleUrls: ['./start-layout.component.scss']
})
export class StartLayoutComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }


}
