import {Injectable} from '@angular/core';
import {AttachmentUrlService} from '../attachment-url.service';
import {BaseModelService} from './base-model.service';
import {AttachmentRepository} from '../../repositories/attachment-repository';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {Attachment} from "../../models/attachment.model";
import {ValidatorService} from "../validator.service";
import {map} from "rxjs";
import {IrfNotificationService} from "irf-services";

const SCALE = 1.5;

@Injectable({
  providedIn: 'root'
})
export class AttachmentService extends BaseModelService<AttachmentRepository> {

  constructor(repo: AttachmentRepository,
              notificationService: IrfNotificationService,
              router: Router) {
    super(ROUTING.Attachment, repo, notificationService, router);
  }
  getAllByDeleted(deleted: boolean) {
    return this.repo.getAllByDeleted(deleted).pipe(
      map(attachments => this.addExtendedPropertiesForModelList(attachments))
    );
  }

  getAllDeletable() {
    return this.repo.getAllDeletable().pipe(
      map(attachments => this.addExtendedPropertiesForModelList(attachments))
    );
  }
  getAllForDetector() {
    return this.repo.getAllForDetector().pipe(
      map(attachments => this.addExtendedPropertiesForModelList(attachments))
    );
  }

  getAllIByExtension(extension: string) {
    return this.repo.getAllIByExtension(extension).pipe(
      map(attachments => this.addExtendedPropertiesForModelList(attachments))
    );
  }

  getAllImages() {
    return this.repo.getAllImages().pipe(
      map(attachments => this.addExtendedPropertiesForModelList(attachments))
    );
  }

  getById(id: string) {
    return this.repo.getById(id).pipe(
      map(attachment => this.addExtendedPropertiesForModel(attachment))
    );
  }


  override addExtendedPropertiesForModel(model: any): any {
    model.name = model.title;
    model.src = AttachmentUrlService.getScaleHeightImageUrl(model.slug, 200);
    model.srcWidth = AttachmentUrlService.getScaleWidthImageUrlBySlug(model.slug, 200);
    model.downloadLink = AttachmentUrlService.getDownloadUrl(model.slug);
    return model;
  }

  override addExtendedPropertiesForModelList(modelList: any[]): any[] {
    modelList.forEach(model => model = this.addExtendedPropertiesForModel(model));
    return modelList;
  }

  override validate(editableRow: Attachment) {
    ValidatorService.allRequired(editableRow, Object.keys(editableRow), 'Attachment');
  }

  getSrcForAttachment(image: any, orientation: "horizontal" | "vertical", scale: number): string {
    if (orientation == "horizontal") {
      return AttachmentUrlService.getScaleWidthImageUrlBySlug(image.slug, scale * SCALE);
    }
    return AttachmentUrlService.getScaleHeightImageUrl(image.slug, scale * SCALE);
  }
}

