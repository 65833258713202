<nx-card class="viewer">
  <nx-card-header>
    <page-form-header [model]="model" [titleProperty]="'name'"></page-form-header>
  </nx-card-header>

  <page-form-tool-bar
    [saveSupplier]="save()"
    [cancelSupplier]="cancel()"
  >

  </page-form-tool-bar>

  <ng-container *ngIf="!model.id && partners">
  <forms-contract-form [model]="model" [partners]="partners" [distributors]="distributorForUser" [asDistributor]="true"></forms-contract-form>
  </ng-container>
  <ng-container *ngIf="model.id">
    <forms-edit-contract-form [model]="model" [editableType]="editableType" [asDistributor]="true"></forms-edit-contract-form>
  </ng-container>

</nx-card>
