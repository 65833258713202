import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule
} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, DomSanitizer, Title} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxCheckboxModule} from '@aposin/ng-aquila/checkbox';
import {NxDocumentationIconModule} from '@aposin/ng-aquila/documentation-icons';
import {NxDropdownModule} from '@aposin/ng-aquila/dropdown';
import {NxFooterModule} from '@aposin/ng-aquila/footer';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxIconModule, NxIconRegistry} from '@aposin/ng-aquila/icon';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {NxLinkModule} from '@aposin/ng-aquila/link';
import {NxMessageModule} from '@aposin/ng-aquila/message';
import {NX_MODAL_DEFAULT_OPTIONS, NxModalModule} from '@aposin/ng-aquila/modal';
import {NX_OVERLAY_SCROLL_STRATEGY, NxOverlayModule} from '@aposin/ng-aquila/overlay';
import {NxPopoverModule} from '@aposin/ng-aquila/popover';
import {AppComponent} from './app.component';
import {environment} from "../environments/environment";
import {Utilities} from "./utilities/utilities";
import {NgPositoryModule} from "ng-pository";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {LoaderInterceptor} from "./interceptors/loader.interceptor";
import {LoaderService} from "./services/loader.service";
import {initializer} from "./utilities/app-init";
import {ToastrModule} from "ngx-toastr";
import {NxSidebarModule} from "@aposin/ng-aquila/sidebar";
import {NxHeaderModule} from "@aposin/ng-aquila/header";
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {ComponentsModule} from "./components/components.module";
import {NxActionModule} from "@aposin/ng-aquila/action";
import {NxSidepanelModule} from "@aposin/ng-aquila/sidepanel";
import {NxRadioToggleModule} from "@aposin/ng-aquila/radio-toggle";
import {NxContextMenuModule} from "@aposin/ng-aquila/context-menu";
import {NxAvatarModule} from "@aposin/ng-aquila/avatar";
import {AdminAuthGuard} from "./layouts/admin-layout/admin-auth-guard.service";
import {PartnerAuthGuard} from "./layouts/partner-layout/partner-auth-guard.service";
import {NxSmallStageModule} from "@aposin/ng-aquila/small-stage";
import {StartLayoutComponent} from './layouts/start-layout/start-layout.component';
import {NxCopytextModule} from "@aposin/ng-aquila/copytext";
import {ConfiguratorLayoutComponent} from './layouts/configurator-layout/configurator-layout.component';
import {ConfiguratorAuthGuard} from "./layouts/configurator-layout/configurator-auth-guard.service";
import {FileBaseCaptionService} from "./services/file-base-caption-service";
import {CoreIconModule, NotificationConfig} from "common";
import {NX_DATE_LOCALE} from "@aposin/ng-aquila/datefield";
import {Overlay, ScrollStrategy} from "@angular/cdk/overlay";
import {getRoutesR} from "./utilities/PageTest";
import {PageModule} from "./pages/page.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {PartnerLayoutComponent} from "./layouts/partner-layout/partner-layout.component";
import {AbstractCaptionService, IrfCaptionModule} from "irf-caption";


import {FormlyModule} from '@ngx-formly/core';
import {FormlyBootstrapModule} from '@ngx-formly/bootstrap';
import {PanelWrapperComponent} from "./components/formly/panel-type/panel-type.component";
import {ObjectTypeComponent} from "./components/formly/object-type/object-type.component";
import {ArrayTypeComponent} from "./components/formly/array-type/array-type.component";
import {IrfNotificationService, IrfServicesModule} from "irf-services";
import {ADDON_SETTINGS, JCD_URL, MGMT_URL} from "jcd-addon";
import {NgStompSockModule} from "@oril/ng-stomp-sock";
import {ErrorHandlerService} from "./services/error-handler.service";
import {DistributorLayoutComponent} from "./layouts/distributor-layout/distributor-layout.component";
import {DistributorAuthGuardService} from "./layouts/distributor-layout/distributor-auth-guard.service";
import {ADDON_ROUTES} from "./utilities/constants";

const notificationConfig = new NotificationConfig("swal");

const routesR = getRoutesR([]).map(route => {
  if (route.path == 'partner') {
    Object.keys(ADDON_ROUTES).forEach(addon => {
      route.children?.push(ADDON_ROUTES[addon]);
    });
  }
  return route;
});

@NgModule({
  declarations: [AppComponent, DistributorLayoutComponent, AdminLayoutComponent, StartLayoutComponent, PartnerLayoutComponent, ConfiguratorLayoutComponent, ObjectTypeComponent, ArrayTypeComponent, PanelWrapperComponent],
  imports: [
    ComponentsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routesR, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    }),
    NxButtonModule,
    NxCheckboxModule,
    NxDocumentationIconModule,
    NxDropdownModule,
    NxFooterModule,
    NxFormfieldModule,
    NxGridModule,
    NxHeadlineModule,
    NxIconModule,
    NxInputModule,
    NxLinkModule,
    NxMessageModule,
    NxModalModule,
    NxOverlayModule,
    NxPopoverModule,
    KeycloakAngularModule,
    NgPositoryModule.forRoot({
      baseApiUrl: environment.serviceUrl,
      errorHandler: {useClass: ErrorHandlerService, deps: [IrfNotificationService]},
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpBackend],
      }
    }),
    NxSidebarModule,
    NxHeaderModule,
    NxActionModule,
    NxSidepanelModule,
    NxRadioToggleModule,
    NxContextMenuModule,
    NxAvatarModule,
    NxSmallStageModule,
    NxCopytextModule,
    IrfCaptionModule,
    CoreIconModule,
    FontAwesomeModule,
    IrfServicesModule.forRoot(notificationConfig),
    PageModule,
    FormlyModule.forRoot({
      validationMessages: [{name: 'required', message: 'This field is required'}],
      wrappers: [{name: 'panel', component: PanelWrapperComponent}],
      types: [
        {name: 'object', component: ObjectTypeComponent},
        {name: 'array', component: ArrayTypeComponent}]
    }),
    FormlyBootstrapModule,
    NgStompSockModule.config({
      url: environment.wsUrl
    })
  ],
  providers: [
    Title,
    AdminAuthGuard,
    PartnerAuthGuard,
    ConfiguratorAuthGuard,
    DistributorAuthGuardService,
    LoaderService,
    {provide: JCD_URL, useValue: environment.jcdAddonUrl},
    {provide: MGMT_URL, useValue: environment.serviceUrl + '/mgmt'},
    {provide: AbstractCaptionService, useClass: FileBaseCaptionService, deps: [TranslateService]},
    {provide: Window, useValue: window},
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [KeycloakService], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: 'environment', useValue: environment.providableEnvironment},
    {provide: NX_MODAL_DEFAULT_OPTIONS, useValue: {width: '1100px'}},
    {provide: NX_DATE_LOCALE, useValue: 'hu-HU'},
    {provide: NX_OVERLAY_SCROLL_STRATEGY, useFactory: scrollStrategyFactory, deps: [Overlay]}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(iconRegistry: NxIconRegistry, sanitizer: DomSanitizer) {

    iconRegistry.registerFont('fal', 'fal', 'fal-');
    iconRegistry.setDefaultFont('fas');
    iconRegistry.addSvgIcon("distributors", sanitizer.bypassSecurityTrustResourceUrl("/assets/img/icons/distributor.svg"));
    iconRegistry.addSvgIcon("device-config", sanitizer.bypassSecurityTrustResourceUrl("/assets/img/icons/device-config.svg"));
    iconRegistry.addSvgIcon("statistic-addon", sanitizer.bypassSecurityTrustResourceUrl("/assets/img/icons/statistic-addon.svg"));
    iconRegistry.addSvgIcon("detectors", sanitizer.bypassSecurityTrustResourceUrl("/assets/img/icons/detector.svg"));

    ADDON_SETTINGS.ERROR_HANDLER = Utilities.handleError;
    ADDON_SETTINGS.MOCK = false;
  }
}


export function httpTranslateLoader(http: HttpBackend) {
  const client = new HttpClient(http)
  return new TranslateHttpLoader(client, './assets/i18n/', '.json');
}

export function scrollStrategyFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.close({threshold: 100});
}

