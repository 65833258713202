import {AddressModel} from "../components/modal/add-address-to-model/address.model";
import {BaseModel} from "./base.model";

export class User implements BaseModel {

  keycloakId: string;
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  userName: string = "";
  phone: string = "";
  roles: string[] = [];
  chmod: string[] = ['0', '0', '0','0'];
  address: AddressModel = new AddressModel();
  partners: any[] = [];
  distributors: any[] = [];

  get id(){
    return this.email;
  }
  set id(value){
    this.email = value;
  }
}
