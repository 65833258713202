import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AttachmentService} from '../../../services/model/attachment.service';
import {Attachment} from '../../../models/attachment.model';
import {KeycloakProfile} from 'keycloak-js';
import {lastValueFrom, Subscription} from 'rxjs';
import {AUTH_USER_KEY, ROLE_INDEX} from '../../../utilities/constants';
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {ShowAttachmentsComponent} from "../show-attachments/show-attachments.component";
import {MyStorageService} from "../../../services/my-storage.service";
import {Utilities} from "../../../utilities/utilities";
import {PageTest} from "../../../utilities/PageTest";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {ROUTING} from "../../../utilities/routing-constants";
import {TableAction, TableCol, TableColType} from "ng-aquila-datatable";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
@PageTest({
  path: ['/','/:type'],
  pathPrefix: ROUTING.Attachment.basePlural,
  layout: AdminLayoutComponent
})
export class AttachmentsComponent extends AbstractListPage<Attachment[], AttachmentService> implements OnInit,OnDestroy {

  private sub: Subscription;
  colDef: TableCol[] = [];
  actionDef: TableAction[];
  a: TableColType

  @ViewChild('template') templateRef!: TemplateRef<any>;
  @ViewChild('test')
  hdrTpl: TemplateRef<any>;

  componentDialogRef!: NxModalRef<ShowAttachmentsComponent>;
  private loadType: string;


  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: AttachmentService,
              translateService: AbstractCaptionService,
              myStorageService: MyStorageService,
              public dialogService: NxDialogService,
              private route: ActivatedRoute
  ) {
    super(viewportService, cdr, translateService, service, myStorageService);

  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      this.loadType = params['type'];
      this.loadData();
    });
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      // new TableAction('fas fa-edit', this.edit(),'table.action.edit', this.hasPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];
    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('title', await this.getTranslate('attachment.title')),
      TableCol.newString('mimeType', await this.getTranslate('attachment.mimeType')),
      TableCol.newBoolean('detectorAttachment', await this.getTranslate('attachment.detectorAttachment')),
      // TableCol.newString('fileName', 'attachment.fileName'),
      // TableCol.newString('fileName', 'attachment.fileName'),
      // TableCol.newString('slug', 'attachment.slug'),
    ];
    if(this.loadType == 'deletable') {
      this.models = await lastValueFrom(this.service.getAllDeletable());
    }else if(this.loadType == 'detector') {
      this.models = await lastValueFrom(this.service.getAllForDetector());
    }else{
      this.models = await lastValueFrom(this.service.getAllByDeleted(false));
    }
  }


  override new(): () => void {
    return () =>{
      this.service.new('attachment/');
    };
  }

  override show(): (row: any) => void {
    return (row: any) => {
      this.componentDialogRef = this.dialogService.open(
        ShowAttachmentsComponent,
        Utilities.getDefaultCloseIconModalConfig(row)
      );
    }
  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }

  private deletableAndHasPermission() {
    return (row: any) => {
      const chmodElement = this.authUser.attributes.chmod[ROLE_INDEX['admin']];
      let deletable = row.deletable;
      return chmodElement > 4 && deletable;
    }
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
