
<nx-data-display class="nx-margin-bottom-s" label="{{'contract.partner' | caption}}"
                 orientation="horizontal-columns">
  {{model.partner?.name}}
</nx-data-display>

<nx-data-display class="nx-margin-bottom-s" label="{{'contract.deviceHeading' | caption}}"
                 orientation="horizontal-columns">
  {{model.measuringDevice?.uuId}}
</nx-data-display>

<nx-data-display class="nx-margin-bottom-s" label="{{'contract.start' | caption}}"
                 orientation="horizontal">
  {{model.start | date:'yyyy.MM.dd'}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'contract.end' | caption}}"
                 orientation="horizontal">
  {{model.end | date:'yyyy.MM.dd'}}
</nx-data-display>
