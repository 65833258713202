<ng-container *ngIf="image">
  <a class="d-inline m-2" href="javascript:void(0)" (click)="openFancy()">
    <img class="d-inline" style="max-height: 600px;" [style.width]="widthStyle" [style.height]="heightStyle"
         [src]="image.src"
         (error)="image.src = 'assets/img/default.png'"
         alt="{{image.title}}"
    />
  </a>
</ng-container>

<ng-template #fancy>
  <div class="text-center w-100 mt-5">
    <img class="w-100"
         [src]="image.downloadLink"
         (error)="image.src = 'assets/img/default.png'"
         alt="{{image.title}}"
    />
  </div>
</ng-template>
