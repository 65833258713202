<ng-container *ngIf="!mobile">
  <nx-notification-panel>
    <nx-notification-header>
      <h3 nxCopytext="large" class="nx-font-weight-semibold">{{title}}</h3>
      <button nxPlainButton="small" type="button" (click)="openAddNoteModal()">
        {{'general.addNote' | caption}}
        <nx-icon name="speech-bubble-o" class="nx-margin-left-2xs"></nx-icon>
      </button>
    </nx-notification-header>

    <ng-container *ngFor="let note of notes">
      <div style="margin-bottom: 1px;" nxNotificationPanelItem [clickable]="false">
        <nx-notification-item-metadata>
          {{'general.noteAdded' | caption}} &middot; {{note.createdAt | dateAgo}}
        </nx-notification-item-metadata>
        <nx-notification-item-content>
          <span class="nx-font-weight-semibold">{{note.user?.firstName}} {{note.user?.lastName}}</span> -
          {{note.message}}
        </nx-notification-item-content>
      </div>
    </ng-container>
  </nx-notification-panel>
</ng-container>
<ng-container *ngIf="mobile">

  <div class="bar-as-toolbar-small">
    <h6 class="back-right">{{title}} </h6>
    <button nxIconButton="small" class="nx-margin-left-xs" type="button" (click)="openAddNoteModal()">
      <nx-icon name="comment-medical" ></nx-icon>
    </button>
  </div>

  <ng-container *ngFor="let note of notes">
    <div style="margin-bottom: 1px;" nxNotificationPanelItem [clickable]="false">
      <nx-notification-item-metadata>
        {{'general.noteAdded' | caption}} &middot; {{note.createdAt | dateAgo}}
      </nx-notification-item-metadata>
      <nx-notification-item-content>
        <span class="nx-font-weight-semibold">{{note.user?.firstName}} {{note.user?.lastName}}</span> -
        {{note.message}}
      </nx-notification-item-content>
    </div>
  </ng-container>

</ng-container>
