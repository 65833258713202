import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";
import {AbstractListPage} from "../../../../models/AbstractListPage";
import {MeasuringPoint} from "../../../../models/measuring-point.model";
import {TableAction, TableCol} from "ng-aquila-datatable";
import {VIEW_CHANGER_CONTAINER, ViewChanger} from "../../../../components/list-view-header/list-view-header.component";
import {MarkerElement} from "../../../../components/map/map.component";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {MyStorageService} from "../../../../services/my-storage.service";
import {lastValueFrom} from "rxjs";
import {DISTRIBUTOR_KEY} from "../../../../utilities/constants";
import {MeasuringPointDistributorService} from "../../../../services/model/measuring-point-distributor.service";

@Component({
  selector: 'app-measuring-points',
  templateUrl: './measuring-points-for-distributor.component.html',
  styleUrls: ['./measuring-points-for-distributor.component.scss']
})
@PageTest({
  path: ROUTING.MeasuringPoint.basePlural,
  layout: DistributorLayoutComponent
})
export class MeasuringPointsForDistributorComponent extends AbstractListPage<MeasuringPoint[], MeasuringPointDistributorService> implements OnInit {

  colDef: TableCol[] = [];
  actionDef: TableAction[];

  @ViewChild('test')
  hdrTpl: TemplateRef<any>;

  viewChangers: VIEW_CHANGER_CONTAINER;
  mapModels: MarkerElement[];
  countryCodesInAddress: string[] = [];


  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: MeasuringPointDistributorService,
              translateService: AbstractCaptionService, myStorageService: MyStorageService,) {

    super(viewportService, cdr, translateService, service, myStorageService);
    this.viewChangers = {
      'mapView': new ViewChanger('mapView', true, 'map'),
      'tableView': new ViewChanger('tableView', false, 'table'),
    }
  }

  ngOnInit(): void {

    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
    ];
    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('general.name')),
      TableCol.newString('distributor.name', await this.getTranslate('measuringDevice.distributor')),
      TableCol.newString('latitude', await this.getTranslate('general.latitude')),
      TableCol.newString('longitude', await this.getTranslate('general.longitude')),
      TableCol.newString('timezone', await this.getTranslate('general.timezone')),
    ];

    const distributorForUsers = this.myStorageService.getFromCookies(DISTRIBUTOR_KEY, []);
    if (distributorForUsers && distributorForUsers.length > 0) {
      let models: any[] = [];
      for (const distUser of distributorForUsers) {
        models = models.concat(await lastValueFrom(this.service.getAllByDeleted(distUser.id)));
        console.log(models);
      }
      this.models = models

      this.countryCodesInAddress = [];
      if(this.models && this.models.length>0) {
        this.mapModels = this.service.convertModelListTOMarkerElementList(this.models);
        this.countryCodesInAddress = [...new Set(this.models.map(model => model.address.countryCode.toLowerCase()))];
      }else{
        this.viewChangers = {
          'mapView': new ViewChanger('mapView', false, 'map'),
          'tableView': new ViewChanger('tableView', true, 'table'),
        }
      }
    }

  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }


  markerClick(): (event: any) => void {
    return (e) => this.service.show(e.target.options.markerId);
  }

}
