import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {Utilities} from "../../utilities/utilities";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexTheme,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts/lib/model/apex-types";
import {GANT_FILTER_COOKIE_KEY} from "../../utilities/constants";
import {MyStorageService} from "../../services/my-storage.service";
import {ContractGANTFilter} from "../../services/model/base-contract.service";
import {LoaderService} from "../../services/loader.service";


@Component({
  selector: 'app-gant-chart-option-b',
  templateUrl: './gant-chart-option-b.component.html',
  styleUrls: ['./gant-chart-option-b.component.scss']
})
export class GantChartOptionBComponent implements OnInit, OnChanges {

  @Input() models: any[];
  @Input() service: GantService<any, any>
  @Input() options: ApexOption;


  @ViewChild('search') templateRef!: TemplateRef<any>;
  dialogRef?: NxModalRef<any>;
  filterKeys:any[];
  gantFilter:{[key:string]:GANT_FILTER_ELEMENTS} = {};

  constructor( private loaderService:LoaderService,
    private myStorageService: MyStorageService,
    public dialogService: NxDialogService,) {
  }

  ngOnInit(): void {
    this.loaderService.show();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.models){
      if(!this.myStorageService.checkKey(GANT_FILTER_COOKIE_KEY)){
        this.gantFilter = this.service.createFilterObjectFromModels(this.models);
      }else{
        this.gantFilter = Object.assign(new ContractGANTFilter(), this.myStorageService.getFromCookies(GANT_FILTER_COOKIE_KEY, new ContractGANTFilter(), true));
      }
      this.options.series = this.service.convertModelListToGANTSeries(this.models, this.gantFilter);

      this.filterKeys = Object.keys(this.gantFilter);
      this.loaderService.hide();
    }
  }

  openFilter() {
    this.dialogRef = this.dialogService.open(this.templateRef, Utilities.getDefaultCloseIconModalConfigWithoutData());
  }

  changeFilterEvent() {
    this.options.series = this.service.convertModelListToGANTSeries(this.models, this.gantFilter);
    this.myStorageService.saveToCookies(GANT_FILTER_COOKIE_KEY, this.gantFilter, true);
  }

  refreshSeries() {
    this.options.series = this.service.convertModelListToGANTSeries(this.models, this.gantFilter);
  }
}

export interface GantService<T, F> {
  convertModelListToGANTSeries(models: T, filter: F);
  createFilterObjectFromModels(models: T): F;
}

export interface ApexOption {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions
  dataLabels: ApexDataLabels
  xaxis: ApexXAxis
  yaxis: ApexYAxis
  legend: ApexLegend
  tooltip: ApexTooltip
  theme?: ApexTheme,
  fill:ApexFill
}

export interface GANTFilter {

}

export interface ContractGANTFilterElement {
  name: string;
  show: boolean
}

export type GANT_FILTER_ELEMENTS = { [key: string]: ContractGANTFilterElement };
