<nx-card class="table-card-viewer">
    <page-list-view-header
      [newSupplier]="new()"
      [title]="'distributor.headline'"
    ></page-list-view-header>
  <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef" [showAsList]="mobile"></aquila-table>
</nx-card>

<ng-template #addressTpl let-value="value">
  {{value | address}}
</ng-template>
