import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AttachmentHandlerView} from '../attachment-handler/attachment-handler.model';
import {AttachmentService} from '../../../services/model/attachment.service';

@Component({
  selector: 'app-image-attachment-element',
  templateUrl: './image-attachment-element.component.html',
  styleUrls: ['./image-attachment-element.component.scss']
})
export class ImageAttachmentElementComponent implements OnInit, OnChanges, AttachmentHandlerView {

  @Input() isOptional: boolean;
  @Input() data: any;
  refreshEvent: EventEmitter<any>;

  constructor(private attachmentService: AttachmentService) {
  }

  ngOnInit(): void {
    this.data = this.attachmentService.addExtendedPropertiesForModel(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
