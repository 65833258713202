import { Component, OnInit } from '@angular/core';
import {PageTest} from "../../utilities/PageTest";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
@PageTest({
  path:'terms-and-conditions',
  singlePage:true
})
export class TermsAndConditionComponent implements OnInit {
  pdfSrc = `${environment.url}/assets/docs/terms.pdf`

  constructor() { }

  ngOnInit(): void {
  }

}
