<nx-card class="table-card-viewer" >
    <page-list-view-header
      [newSupplier]="new()"
      [title]="'measuringPoint.headline'"
      [(viewChangers)]="viewChangers"
    ></page-list-view-header>

  <ng-template [ngIf]="viewChangers['mapView'].show && mapModels">
    <app-map height="calc(100vh - 152px)" [models]="mapModels" [startZoom]="6" [markerClickSupplier]="markerClick()" [showYourPosition]="false" [countryCodes]="countryCodesInAddress"></app-map>
  </ng-template>
  <ng-template [ngIf]="viewChangers['tableView'].show">
    <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef" [showAsList]="mobile"></aquila-table>
  </ng-template>
</nx-card>

<ng-template #test let-value="value">
  <ng-container *ngFor="let role of value">
    {{role}},
  </ng-container>
</ng-template>
