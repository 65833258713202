<div class="search-box w-100" *ngIf="gantFilter">
  <div nxRow class="w-100">
    <div nxCol="12">
      <button nxPlainButton (click)="showFilter = !showFilter">
        <div class="chevrons">
          <nx-icon name="{{showFilter?'chevron-up':'chevron-down'}}"></nx-icon>
          <nx-icon class="last-chevron" name="{{showFilter?'chevron-up':'chevron-down'}}"></nx-icon>
        </div>
        <span class="filter-title">
          {{'general.showFilter' | caption}}
        </span>
      </button>
    </div>

    <ng-container *ngIf="showFilter">
      <div nxCol="12,12,12,6">
        <nx-formfield nxLabel="{{'contract.filterKey.partner' | caption}}" appearance="outline" nxFloatLabel="always">
          <nx-multi-select
            name="selectedDistributors"
            [(ngModel)]="gantFilter.activePartner"
            [options]="gantFilter._partnerControlElements"
            filter
            (selectionChange)="changeFilterEvent()"
          ></nx-multi-select>
        </nx-formfield>
      </div>
      <div nxCol="12,12,12,6">
        <nx-formfield nxLabel="{{'contract.filterKey.distributor' | caption}}" appearance="outline"
                      nxFloatLabel="always">
          <nx-multi-select
            name="selectedDistributors"
            [(ngModel)]="gantFilter.activeDistributor"
            [options]="gantFilter._distributorControlElements"
            filter
            (selectionChange)="changeFilterEvent()"
          ></nx-multi-select>
        </nx-formfield>
      </div>
      <div nxCol="12,12,12,6">
        <nx-formfield nxLabel="{{'contract.filterKey.point' | caption}}" appearance="outline" nxFloatLabel="always">
          <nx-multi-select
            name="selectedDistributors"
            [(ngModel)]="gantFilter.activePoints"
            [options]="gantFilter._pointsControlElements"
            filter
            (selectionChange)="changeFilterEvent()"
          ></nx-multi-select>
        </nx-formfield>
      </div>
      <div nxCol="12,12,12,6">
        <nx-formfield nxLabel="{{'contract.filterKey.device' | caption}}" appearance="outline" nxFloatLabel="always">
          <nx-multi-select
            name="selectedDistributors"
            [(ngModel)]="gantFilter.activeDevice"
            [options]="gantFilter._deviceControlElements"
            filter
            (selectionChange)="changeFilterEvent()"
          ></nx-multi-select>
        </nx-formfield>
      </div>
    </ng-container>
  </div>
</div>
<div class="table-container">
  <div nxRow>
    <div nxCol="12">

      <apx-chart #chartComponent
                 [series]="options.series"
                 [chart]="options.chart"
                 [plotOptions]="options.plotOptions"
                 [dataLabels]="options.dataLabels"
                 [legend]="options.legend"
                 [xaxis]="options.xaxis"
                 [yaxis]="options.yaxis"
                 [tooltip]="options.tooltip"
                 [fill]="options.fill"
      ></apx-chart>

    </div>
  </div>
</div>

