<nx-toolbar>

  <button
    nxPlainButton=""
    type="button"
    aria-label="a useful label"
    class="back-right"
    (click)="cancelSupplier()"
  >
    <nx-icon name="arrow-left" class="nx-margin-right-2xs"></nx-icon>
    <ng-container *ngIf="!mobile"> {{'general.cancel' | caption}}</ng-container>
  </button>

  <ng-container *ngIf="!mobile">
    <ng-container *ngFor="let option of modalOptions">
      <button
        nxIconButton="tertiary small"
        aria-label="a useful label"
        type="button"
        class="nx-margin-right-2xs nx-margin-bottom-0"
        [disabled]=option.disable
        (click)="option.openModalSupplier()"
      >
        <nx-icon name="{{option.iconName}}"></nx-icon>
      </button>
    </ng-container>
    <button nxButton="primary small" type="button" (click)="saveSupplier()" [disabled]="disableSave">
      <nx-icon name="save-o" class="nx-margin-right-2xs"></nx-icon>
      {{'general.save' | caption}}
    </button>
  </ng-container>

  <ng-container *ngIf="mobile">

    <button
      nxIconButton="cte small"
      [nxContextMenuTriggerFor]="menu"
      aria-label="Open menu"
      type="button"
    >
      <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
    </button>

    <nx-context-menu #menu="nxContextMenu">
      <ng-container *ngFor="let option of modalOptions">
        <button
          nxContextMenuItem
          aria-label="a useful label"
          type="button"
          class="nx-margin-right-2xs nx-margin-bottom-0"
          (click)="option.openModalSupplier()"
        >
          <nx-icon name="{{option.iconName}}"></nx-icon>
          {{option.name | caption}}
        </button>
      </ng-container>
      <button nxContextMenuItem type="button" (click)="saveSupplier()" [disabled]="disableSave">
        <nx-icon name="save-o" class="nx-margin-right-2xs"></nx-icon>
        {{'general.save' | caption}}
      </button>
    </nx-context-menu>


  </ng-container>
</nx-toolbar>
