import {NoteModel} from "./note.model";
import {AddressModel} from "../components/modal/add-address-to-model/address.model";
import {BaseModel} from "./base.model";
import {Distributor} from "./distributor.model";

export class MeasuringPoint implements BaseModel {

  id: number;
  name: string = '';
  description: string = '';
  latitude: number = 47.4725155;
  longitude: number = 19.059474;
  threshold: number = 10;
  attachment: any = null;
  attachments: any[];
  timezone: string = 'Europe/Budapest';
  note?: NoteModel;
  address: AddressModel = new AddressModel();
  distributor?: Distributor;
  distributorId?: number;
}

