<div class="container-fluid">
  <div class="row m-4">
    <div class="col-12">
      <p captionCode="test">dsadas</p>
      <p captionCode="general.success">dsadas</p>
      <p> {{'error.dontHaveRole' | caption }}</p>
      <!--      <lib-mb-datatables [className] = className [methodName]="'getData'"></lib-mb-datatables>-->

      <div class="scroll-container">
        <table nxTable class="nx-table--scrollable">
          <thead>
          <tr nxTableRow>
            <th nxHeaderCell>Product</th>
            <th nxHeaderCell>Contract Number</th>
            <th nxHeaderCell>Description</th>
            <th nxHeaderCell>Website</th>
            <th nxHeaderCell>Ending At</th>
            <th nxHeaderCell>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr nxTableRow *ngFor="let item of tableElements">
            <td nxTableCell>{{item.product}}</td>
            <td nxTableCell>{{item.contractNumber}}</td>
            <td nxTableCell>{{item.desc}}</td>
            <td nxTableCell>
              <nx-link><a routerLink="#">{{item.website}}</a></nx-link>
            </td>
            <td nxTableCell>{{item.endingAt}}</td>
            <td nxTableCell>
              <nx-badge [type]="item.status"
              >{{item.statusText}}</nx-badge
              >
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
