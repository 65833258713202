import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MeasuringPoint} from "../../../models/measuring-point.model";
import {AbstractBaseComponent} from "common";
import {Distributor} from "../../../models/distributor.model";
import  tzs from "../../../../assets/timezone.json";
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'forms-measuring-point-form',
  templateUrl: './measuring-point-form.component.html',
  styleUrls: ['./measuring-point-form.component.scss']
})
export class MeasuringPointFormComponent extends AbstractBaseComponent implements OnInit, OnChanges {

  @Input() model: MeasuringPoint;
  @Input() distributors: Distributor[];
  distributorId: number;
  timezones:any[] = tzs;
  utcOffset:number = 0;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.model.timezone){
     this.timezoneChange();
    }
    if (this.model.distributor) {
      this.distributorId = this.model.distributor.id;
    }
  }

  selectionChange() {
    this.model.distributor = this.distributors.find(d => d.id = this.distributorId);
  }

  timezoneChange(){
    const a = moment.tz("2023-11-01 8:55", this.model.timezone);
    this.utcOffset = a.utcOffset()/60;
  }

}
