import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {LoaderService} from "../services/loader.service";
import {ErrorHandlerService} from "../services/error-handler.service";
import {MyStorageService} from "../services/my-storage.service";
import {DEVICE_API_KEY} from "../utilities/constants";
import {ConfiguratorRepository} from "../repositories/configurator-repository";


@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor {
  constructor(public loaderService: LoaderService,
              private myStorageService: MyStorageService,
              private errorHandlerService: ErrorHandlerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone(
      this.requestHeaderUpdate(req)
    );
    return next.handle(clonedRequest).pipe(
      finalize(() => this.loaderService.hide())
    );
  }

  private requestHeaderUpdate(req: HttpRequest<any>) {
    const regexp=/.*:5000/gm;
    const regexpUrl=/.*.pitunnel.com/gm;
    const token = this.myStorageService.getFromStore(DEVICE_API_KEY, null);
    if(ConfiguratorRepository.showLoader){
      this.loaderService.show();
    }
    if (token != null && (regexp.test(req.url) || regexpUrl.test(req.url))) {
      return {headers: req.headers.set('Accept-Language', 'hu-HU').set('Authorization', 'Bearer ' + token)};
    }
    return {headers: req.headers.set('Accept-Language', 'hu-HU')};
  }
}
