import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-boundary-canvas';
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";

export class MarkerElement {
  id: string | number;
  title: string;
  description: string = '';
  icon: any;
  lat: number;
  lng: number;

  constructor(lat: number, lng: number, id: string | number, title: string, description: string, icon?: any,) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.icon = icon ? icon : OFFLINE_ICON;
    this.lat = lat;
    this.lng = lng;
  }
}

export const ONLINE_ICON = L.icon({
  iconUrl: 'assets/img/map/pin-green.svg',
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  popupAnchor: [0, -16]
});
export const OFFLINE_ICON = L.icon({
  iconUrl: 'assets/img/map/pin-red.svg',
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  popupAnchor: [0, -16]
});
export const ERROR_ICON = L.icon({
  iconUrl: 'assets/img/map/pin-red.svg',
  iconSize: [32, 32],
  iconAnchor: [16, 16],
  popupAnchor: [0, -16]
});
export const HOME_ICON = L.icon({
  iconUrl: 'assets/img/map/location.png',
  iconSize: [32, 32],
  iconAnchor: [16, 16]
});

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
  }
)
export class MapComponent implements OnInit, AfterViewInit {
  @Input() models: MarkerElement[];
  @Input() startZoom: number = 13;
  @Input() markerClickSupplier?: (event: any) => void;
  @Input() showYourPosition: boolean = true;
  @Input() height: string = '500px';

  @Input() countryCodes: string[] = ['al', 'at', 'ba', 'be', 'bg', 'by', 'ch', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gr', 'hu', 'hr', 'ie', 'it', 'lt', 'lu', 'lv', 'mk', 'nl', 'no', 'pl', 'pt', 'ro', 'rs', 'se', 'si', 'sk', 'tr', 'ua', 'vn', 'id', 'th'];
  mapPolygons: any[] = [];
  private map: any;
  center: number[];

  constructor(private readonly httpClient: HttpClient,) {
  }

  ngOnInit(): void {

  }

  private async initCountryMaps() {
    this.mapPolygons = [];
    for (const countryCode of this.countryCodes) {
      const result: any[] = await lastValueFrom(this.httpClient.get<any[]>(`assets/maps/${countryCode.toLowerCase()}.json`))
      if (result && result.length > 0) {
        this.mapPolygons.push(result[0]);
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
//https://nominatim.openstreetmap.org/search?country=th&polygon_geojson=1&format=json
    await this.initCountryMaps();
    if (navigator.geolocation && this.showYourPosition) {
      navigator.geolocation.getCurrentPosition((result) => {
        this.initMap([result.coords.latitude, result.coords.longitude])
      }, (a) => {
        const lat = this.models[0].lat;
        const lng = this.models[0].lng;
        this.initMap([lat, lng])
      });
    } else {
      const lat = this.models[0].lat;
      const lng = this.models[0].lng;
      this.initMap([lat, lng])
    }
  }

  private async initMap(center: number[]): Promise<void> {
    this.map = L.map('map');
    // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // }).addTo(this.map);
    const geoJSON: { type: string, features: any[] } = {
      type: "FeatureCollection",
      features: []
    };
    const geoJSONList = this.mapPolygons;
    // console.log(geoJSONListd);
    // const geoJSONList = [HU_MAP, DE_MAP];
    geoJSONList.forEach(countryJSON => {
      geoJSON.features.push({
        type: "Feature",
        properties: {},
        geometry: countryJSON.geojson //geometry: countryJSON[0].geojson
      });
    })
    const osm = new L.TileLayer.BoundaryCanvas("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      boundary: geoJSON,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
    });
    this.map.addLayer(osm);
    const ukLayer = L.geoJSON(geoJSON);
    this.map.fitBounds(ukLayer.getBounds());


    if (this.showYourPosition) {
      const marker = L.marker(center, {
        icon: HOME_ICON
      });
      marker.addTo(this.map);
    }
    this.initMarkers();
    this.map.setView(center, this.startZoom)
  }

  private initMarkers() {
    this.models.forEach(markerModel => {
      const marker = L.marker([markerModel.lat, markerModel.lng], {
        icon: markerModel.icon,
        markerId: markerModel.id
      });
      if (this.markerClickSupplier) {
        marker.on("click", this.markerClickSupplier);
      } else {
        marker.bindPopup(this.createMeasuringPointPopUp(markerModel), {
          closeOnClick: false,
          autoClose: false,
          closeButton: true
        });
      }
      marker.addTo(this.map);
    });
  }

  createMeasuringPointPopUp(markerModel: MarkerElement): string {
    return `<p>${markerModel.title}</p><p>${markerModel.description}</p>`;
  }
}
