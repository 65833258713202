<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.uuId' | caption}}" [orientation]="orientation$ | async">
  {{model.externalId}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.uuId' | caption}}" [orientation]="orientation$ | async">
  {{model.uuId}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.ssid' | caption}}" [orientation]="orientation$ | async">
  {{model.ssid}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.macAddress' | caption}}" [orientation]="orientation$ | async">
  {{model.macAddress}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.wifiPassword' | caption}}" [orientation]="orientation$ | async">
  <app-toggle-hidden-visibility [data]="model.wifiPassword" placeholder="PASSWORD"></app-toggle-hidden-visibility>
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.online' | caption}}" [orientation]="orientation$ | async">
  {{(model.online ? 'general.yes' : 'general.no') | caption}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s"  label="{{'measuringDevice.virtual' | caption}}" [orientation]="orientation$ | async">
  {{(model.virtual ? 'general.yes' : 'general.no') | caption}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.localAddress' | caption}}"  [orientation]="orientation$ | async">
  {{model.localAddress}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.globalAddress' | caption}}" [orientation]="orientation$ | async">
  {{model.globalAddress}}
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s" label="{{'measuringDevice.apiKey' | caption}}" [orientation]="orientation$ | async">
  <app-toggle-hidden-visibility [data]="model.apiKey" placeholder="API_KEY"></app-toggle-hidden-visibility>
</nx-data-display>
<nx-data-display size="medium" class="nx-margin-bottom-s"  label="{{'measuringDevice.healthCheckEndpoint' | caption}}" [orientation]="orientation$ | async">
  {{model.healthCheckEndpoint}}
</nx-data-display>
<nx-data-display *ngIf="model.distributor" size="medium" class="nx-margin-bottom-s"  label="{{'measuringDevice.distributor' | caption}}" [orientation]="orientation$ | async">
  {{model.distributor.name}}
</nx-data-display>
