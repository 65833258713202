import {Partner} from "./partner.model";
import {MeasuringDevice} from "./measuring-device.model";
import {MeasuringPoint} from "./measuring-point.model";
import {BaseModel} from "./base.model";
import {Distributor} from "./distributor.model";

export class Contract implements BaseModel {

  createdAt:string;
  id: number;
  name: string;
  start: string;
  end: string;
  partnerId: number | null = null;
  distributorId: number | null = null;
  measuringDeviceId: number | null = null;
  measuringPointId: number | null = null;
  partner?: Partner;
  distributor?: Distributor
  measuringDevice!: MeasuringDevice;
  measuringPoint!: MeasuringPoint;
  locked: boolean = false;
  onlineStatus: ONLINE_STATUS = "offline";
  checkResult: any;
  interval: any;
  chartTypes: string[] =[];


  constructor(measuringPoint?: MeasuringPoint) {
    if (measuringPoint) {
      this.measuringPoint = measuringPoint;
      this.measuringPointId = measuringPoint.id;
    }
  }
}

export type ONLINE_STATUS = "offline" | "reachable" | "online";
export const ONLINE_STATUS_LIST = [
  {label: "Offline", value: 'offline'},
  {label: "InService", value: 'online'},
  {label: "Faulty", value: 'reachable'}
]
