import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../models/user.model";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {map, Observable} from "rxjs";
import {NxDataDisplayOrientation} from "@aposin/ng-aquila/data-display";
import {ListElementComponent, TableAction} from "ng-aquila-datatable";

@Component({
  selector: 'card-user',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent extends ListElementComponent implements OnInit {
  @Input() override row: User;
  @Input() override actionConfig: TableAction[]
  readonly mobileViewport$ = this.viewportService.max(
    NxBreakpoints.BREAKPOINT_MEDIUM,
    100,
  );
  readonly orientation$: Observable<NxDataDisplayOrientation> =
    this.mobileViewport$.pipe(
      map(mobile => (mobile ? 'vertical' : 'horizontal-columns')),
    );

  constructor(private readonly viewportService: NxViewportService) {
    super();
  }

  ngOnInit(): void {
  }

}
