<nx-toolbar>
  <h4 class=" back-right nx-margin-y-s nx-font-weight-light" [ngClass]="{'mobile-hading':mobile}"> {{title}}</h4>


  <ng-container *ngIf="!mobile">
    <button
      *ngIf="editSupplier && hasPermission"
      nxIconButton="tertiary small"
      aria-label="a useful label"
      class="nx-margin-right-s nx-margin-bottom-0"
      type="button"
      (click)="editSupplier()"
    >
      <nx-icon name="pencil" ></nx-icon>
    </button>
    <button
      nxPlainButton=""
      type="button"
      aria-label="a useful label"
      (click)="backSupplier()"
    >
      <nx-icon name="arrow-left" class="nx-margin-right-2xs"></nx-icon
      >
      {{'general.back' | caption}}
    </button>
  </ng-container>

  <ng-container *ngIf="mobile">

    <button
      nxIconButton="cte small"
      [nxContextMenuTriggerFor]="menu"
      aria-label="Open menu"
      type="button"
    >
      <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
    </button>

    <nx-context-menu #menu="nxContextMenu">
      <button
        *ngIf="editSupplier"
        nxContextMenuItem
        aria-label="a useful label"
        type="button"
        (click)="editSupplier()"
      >
        <nx-icon name="pencil" ></nx-icon>
        {{'general.edit' | caption}}
      </button>
      <button
        nxContextMenuItem
        type="button"
        aria-label="a useful label"
        (click)="backSupplier()"
      >
        <nx-icon name="arrow-left"></nx-icon
        >
        {{'general.back' | caption}}
      </button>
    </nx-context-menu>
  </ng-container>
</nx-toolbar>

