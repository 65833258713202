import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NoteModel} from "../../models/note.model";
import {AddNoteToModelComponent} from "../modal/add-note-to-model/add-note-to-model.component";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {NoteRepository} from "../../repositories/note-repository";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-note-handler',
  templateUrl: './note-handler.component.html',
  styleUrls: ['./note-handler.component.scss']
})
export class NoteHandlerComponent implements OnInit {

  @Input() notes: NoteModel[];
  @Input() addNoteSupplier: (note: NoteModel) => void;
  @Input() title: string;
  componentDialogRef!: NxModalRef<any>;
  public mobile: boolean = false;
  protected readonly _destroyed = new Subject<void>();

  constructor(private noteRepo: NoteRepository,
              public dialogService: NxDialogService,
              protected viewportService: NxViewportService,
              protected _cdr: ChangeDetectorRef,) {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_MEDIUM)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
  }

  openAddNoteModal() {
    this.componentDialogRef = this.dialogService.open(
      AddNoteToModelComponent,
      {
        showCloseIcon: false,
      },
    );
    this.componentDialogRef.afterClosed().subscribe(result => {
      if (result != 'cancel') {
        this.noteRepo.saveAsAdmin(result).subscribe(note =>{
          this.addNoteSupplier(note);
        });
      }
    });
  }
}
