import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MeasuringDevice} from '../../../models/measuring-device.model';
import {lastValueFrom} from 'rxjs';
import {AUTH_USER_KEY, DEVICE_STATUSES, DISTRIBUTOR_KEY} from '../../../utilities/constants';
import {MeasuringDeviceService} from '../../../services/model/measuring-device.service';
import {MyStorageService} from "../../../services/my-storage.service";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {TableAction, TableCol, TableFilter, TableSort} from 'ng-aquila-datatable';
import {AbstractCaptionService} from "irf-caption";
import {DistributorService} from "../../../services/model/distributor.service";
import {DistributorLayoutComponent} from "../../../layouts/distributor-layout/distributor-layout.component";

@Component({
  selector: 'app-measuring-devices-for-distributor',
  templateUrl: './measuring-devices-for-distributor.component.html',
  styleUrls: ['./measuring-devices-for-distributor.component.scss']
})
@PageTest({
  path: ROUTING.MeasuringDevice.basePlural,
  layout: DistributorLayoutComponent
})
export class MeasuringDevicesForDistributorComponent extends AbstractListPage<MeasuringDevice[], MeasuringDeviceService> implements OnInit {
  statuses = DEVICE_STATUSES;
  tableSort: TableSort = new TableSort('externalId', 'asc');
  colDef: TableCol[] = [];
  actionDef: TableAction[];
  componentDialogRef!: NxModalRef<any>;
  @ViewChild('colorTemplate') colorTemplate: TemplateRef<any>;
  @ViewChild('translateTemplate') translateTemplate: TemplateRef<any>;
  listView: boolean = false;
  selected: MeasuringDevice;


  constructor(viewportService: NxViewportService,
              cdr: ChangeDetectorRef,
              translateService: AbstractCaptionService,
              service: MeasuringDeviceService, myStorageService: MyStorageService,
              private distributorService: DistributorService,
              public dialogService: NxDialogService,) {
    super(viewportService, cdr, translateService, service, myStorageService);

  }

  ngOnInit(): void {

    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
    ];
    this.colDef = [
      TableCol.newString('externalId', await this.getTranslate('measuringDevice.externalId')),
      TableCol.newString('uuId', await this.getTranslate('measuringDevice.uuId')),
      TableCol.newString('status', await this.getTranslate('measuringDevice.status'), TableFilter.newDefaultText(), () => {
        return this.translateTemplate
      }),

      TableCol.newString('distributor.name', await this.getTranslate('measuringDevice.distributor')),
      TableCol.newBoolean('virtual', await this.getTranslate('measuringDevice.virtual')),
      TableCol.newBoolean('online', await this.getTranslate('measuringDevice.online')),
      TableCol.newString('color', await this.getTranslate('measuringDevice.color'), TableFilter.newDefaultText(), () => {
        return this.colorTemplate
      }),
    ];

    const distributorForUsers = this.myStorageService.getFromCookies(DISTRIBUTOR_KEY, []);
    if (distributorForUsers) {

      this.models = await lastValueFrom(this.service.getAllByForDistributor(distributorForUsers.map(d => d.id)));
    }
  }

  override show(): (row: any) => void {
    return (row: any) => {
      this.service.navigateToUrl('distributor/measuring-devices/show/' + row.id)
    }
  }
}
