import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DetectorService} from '../../../services/model/detector.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'app-show-detectors',
  templateUrl: './show-detectors.component.html',
  styleUrls: ['./show-detectors.component.scss'],
})
@PageTest({
  path: 'show/:id',
  pathPrefix: ROUTING.Detector.basePlural,
  layout: AdminLayoutComponent
})
export class ShowDetectorsComponent extends AbstractShowPage<any, DetectorService> implements OnInit {

  constructor(service: DetectorService, route: ActivatedRoute,
              myStorageService:MyStorageService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

}
