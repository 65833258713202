import {BaseModel} from "./base.model";
import {Attachment} from "./attachment.model";

export class Detector implements BaseModel {

  id: number;
  name: string = '';
  description: string = '';
  versionNumber: string = '';
  remoteInstall: boolean = false;
  uuId: string = '';
  config: string = '';
  defaultConfig?:any;
  algorithm:Attachment;
  algorithmId:number = 0;
}

