<nx-card class="table-card-viewer">
    <page-list-view-header
      [title]="'measuringDevice.headline'"
    >
    </page-list-view-header>
  <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef" [showAsList]="mobile"
               [sort]="tableSort"></aquila-table>
</nx-card>



<ng-template #colorTemplate let-value="value">
  <div [style]="'width:50px; height: 20px; background:'+value">
  </div>
</ng-template>
<ng-template #translateTemplate let-value="value">
  {{'measuringDevice.' + value | caption}}
</ng-template>

