import {Partner} from "./partner.model";
import {BaseModel} from "./base.model";

export class Addon implements BaseModel{

  id: number = 0;
  name: string = '';
  description: string = '';
  companyName: string = '';
  moduleName: string = '';
  partners!:Partner[];
}

