import {NoteModel} from "./note.model";
import {DEVICE_STATUSES} from "../utilities/constants";
import {BaseModel} from "./base.model";
import {Distributor} from "./distributor.model";
export type MEASURING_DEVICE_STATUSES = 'ACTIVE'| 'UNDER_CONSTRUCTION' |'FAULTY' | 'UNDER_REPAIR';
export class MeasuringDevice implements BaseModel {
  id: number;
  externalId:number;
  macAddress: string = 'dddd';
  ssid: string = '';
  wifiPassword: string = '';
  online: boolean = false;
  virtual: boolean = false;
  apiKey: string = '';
  uuId: string = '';
  healthCheckEndpoint:string='';
  detectorId: number;
  note?: NoteModel;
  notes:NoteModel[];
  localAddress: string = '';
  globalAddress: string = '';
  attachments: any[];
  attachment?: any = null;
  detector?: any;
  status:MEASURING_DEVICE_STATUSES = 'ACTIVE';
  color:string='';
  distributor:Distributor;
  distributorId:number;
}

