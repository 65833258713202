import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MeasuringPoint} from "../../../models/measuring-point.model";
import {AttachmentService} from "../../../services/model/attachment.service";
import {MeasuringPointService} from "../../../services/model/measuring-point.service";
import {MarkerElement, OFFLINE_ICON, ONLINE_ICON} from "../../map/map.component";
import {NoteModel} from "../../../models/note.model";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'inside-show-measuring-point',
  templateUrl: './show-measuring-point.component.html',
  styleUrls: ['./show-measuring-point.component.scss']
})
export class ShowMeasuringPointComponent implements OnInit, OnChanges {
  @Input() model: any | MeasuringPoint;
  @Input() withNavigation: boolean = true;
  @Input() showMap:boolean = false;
  mapModel: MarkerElement;
  countryCode: string='hu';

  constructor(private attachmentService: AttachmentService, private service: MeasuringPointService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initModel();
  }

  private initModel() {
    if (this.model) {
      this.model.attachments = this.initAttachments();
      this.mapModel = new MarkerElement(
        this.model.latitude,
        this.model.longitude,
        this.model.id,
        this.model.name,
        this.model.description
      );
      this.countryCode = this.model.address.countryCode;
      console.log(this.model);
    }
  }

  private initAttachments(): any[] {
    const extraAttachments: any[] = [];
    if (this.model.attachments && this.model.attachments.length > 0) {
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachments[0]);

      for (let i = 0; i < this.model.attachments.length; i++) {
        extraAttachments.push(
          this.attachmentService.addExtendedPropertiesForModel(this.model.attachments[i])
        );
      }
    } else {
      this.model.attachment = {
        src: 'assets/img/default.png',
        srcWidth: 'assets/img/default.png'
      }
    }
    return extraAttachments;
  }

  addNote(): (note:NoteModel)=>void {
    return async (note: NoteModel) => {
      await lastValueFrom(this.service.repo.addNote(this.model.id, note.id));
      this.model = await lastValueFrom(this.service.getById(this.model.id));
      this.initModel();
    };
  }


}
