import {ChangeDetectorRef, Component, NgZone, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";
import {AbstractListPage} from "../../../../models/AbstractListPage";
import {Contract} from "../../../../models/contract.model";
import {ContractDistributorService} from "../../../../services/model/contract-distributor.service";
import {MarkerElement} from "../../../../components/map/map.component";
import {TableAction, TableCol} from "ng-aquila-datatable";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {ApexOption, GantChartComponent} from "../../../../components/gant-chart/gant-chart.component";
import {VIEW_CHANGER_CONTAINER, ViewChanger} from "../../../../components/list-view-header/list-view-header.component";

import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {MyStorageService} from "../../../../services/my-storage.service";
import {lastValueFrom, map} from "rxjs";
import {
  SelectContractEditTypeComponent
} from "../../../../components/modal/select-contract-edit-type/select-contract-edit-type.component";
import {Utilities} from "../../../../utilities/utilities";
import {
  AddContractToModelComponent
} from "../../../../components/modal/add-contract-to-model/add-contract-to-model.component";

import * as HU_LOCALE from "apexcharts/dist/locales/hu.json";
import {DISTRIBUTOR_KEY, GANT_DISTRIBUTOR_FILTER_COOKIE_KEY} from "../../../../utilities/constants";
import {Distributor} from "../../../../models/distributor.model";
import {PartnerService} from "../../../../services/model/partner.service";
import {ContractGANTFilter} from "../../../../services/model/base-contract.service";
import {LoaderService} from "../../../../services/loader.service";



@Component({
  selector: 'app-contracts',
  templateUrl: './contracts-for-distributor.component.html',
  styleUrls: ['./contracts-for-distributor.component.scss']
})
@PageTest({
  path: ROUTING.Contract.basePlural,
  layout: DistributorLayoutComponent
})
export class ContractsForDistributorComponent extends AbstractListPage<Contract[], ContractDistributorService> implements OnInit {

  mapModels: MarkerElement[];
  colDef: TableCol[] = [];
  actionDef: TableAction[];
  componentDialogRef?: NxModalRef<any>;

  @ViewChild('test') hdrTpl: TemplateRef<any>;
  @ViewChild('gantChartComponent') chart: GantChartComponent;

  viewChangers: VIEW_CHANGER_CONTAINER;
  gantFilter: ContractGANTFilter = new ContractGANTFilter();
  private distributorForUsers: Distributor[];
  private partners: any[];

  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: ContractDistributorService,
              translateService: AbstractCaptionService, myStorageService: MyStorageService,
              private ngZone: NgZone,
              private partnerService:PartnerService,
              private loaderService:LoaderService,
              public dialogService: NxDialogService,) {
    super(viewportService, cdr, translateService, service, myStorageService);

    this.viewChangers = {
      'calendarView': new ViewChanger('calendarView', true, 'calendar'),
      'tableView': new ViewChanger('tableView', false, 'table'),
    }
    this.eventWhereMobileIsTrue = () => {
      this.viewChangers['tableView'].show = this.mobile;
      this.viewChangers['calendarView'].show = !this.mobile;
    }
  }

  ngOnInit(): void {
    this.loadData();
  }

  markerClick(): (event: any) => void {
    return (e) => this.service.show(e.target.options.markerId);
  }

  chartClick(): (event, chartContext, config) => void {
    return (event, chartContext, config) => {
      const series: any = this.options.series[config.seriesIndex]
      setTimeout(() => {
        this.ngZone.run(() => {
          this.service.show(series.data[config.dataPointIndex].meta.id);
        });
      }, 10);
    };
  }

  private async loadData(): Promise<void> {
    this.loaderService.show();
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.isDeletable()),
      new TableAction('unlock', this.unlock(), await this.getTranslate('table.action.unlock'), this.isLock()),
    ];
    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('partner.name', await this.getTranslate('contract.partner')),
      TableCol.newString('name', await this.getTranslate('contract.name')),
      TableCol.newDate('start', await this.getTranslate('contract.start')),
      TableCol.newDate('end', await this.getTranslate('contract.end')),
    ];
    this.distributorForUsers = this.myStorageService.getFromCookies(DISTRIBUTOR_KEY, []);
    this.partners = await lastValueFrom(this.partnerService.getAllByDeletedAsDistributor());
    if ( this.distributorForUsers &&  this.distributorForUsers.length > 0) {
      let models: any[] = [];
      for (const distUser of  this.distributorForUsers) {
        models = models.concat(await lastValueFrom(this.service.getAllByDeleted(distUser.id)));
        console.log(models);
      }
      this.models = models

      this.mapModels = this.service.convertModelListTOMarkerElementList(this.models);

      this.loaderService.hide();
    }

  }


  override edit(): (row: any) => void {
    return (contract: any) => {

      this.componentDialogRef = this.dialogService.open(SelectContractEditTypeComponent,
        Utilities.getDefaultModalConfig({
          model: contract
        }));
      this.componentDialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.service.edit(contract.id, result);
        }
        this.componentDialogRef = undefined;
      });
    };

  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }

  openAddContractModal(): (event, chartContext, config) => void {
    return (event, chartContext, config) => {
      if (this.hasPermission) {
        const labelName = config.globals.labels[config.labelIndex];
        let dates: Date[] = [];
        const contracts = this.models.filter(contract => {
          const result = contract.measuringPoint?.name == labelName;
          if (result) {
            dates = dates.concat(Utilities.getDatesInRange(new Date(contract.start), new Date(contract.end)));
          }
          return result
        });

        if (contracts.length > 0) {
          const measuringPoint = contracts[0].measuringPoint;
          this.ngZone.run(() => {
            this.componentDialogRef = this.dialogService.open(AddContractToModelComponent,
              Utilities.getDefaultModalConfig({
                model: new Contract(measuringPoint),
                denyDates: dates,
                asDistributor:true,
                distributors:this.distributorForUsers,
                partners:this.partners
              }));
            this.componentDialogRef.afterClosed().subscribe(result => {
              if (result != 'cancel') {
                this.saveProcess(result);
              }
              this.componentDialogRef = undefined;
            });
          });
        } else {
          this.service.notificationService.showWarning('general.warning', 'error.measuringPointIsUndefined');
        }
      }
    };
  }

  options: ApexOption = {
    series: [],
    tooltip: {
      x: {
        show: true
      }
    },
    chart: {
      locales: [HU_LOCALE],
      defaultLocale: 'hu',
      height: 750,
      type: 'rangeBar',
      events: {
        dataPointSelection: this.chartClick(),
        xAxisLabelClick: this.openAddContractModal()
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: true
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",

        rangeBarGroupRows: true
      }
    },
    fill: {},
    dataLabels: {
      enabled: true,

      formatter: (val, opts) => {
        const series: any = this.options.series[opts.seriesIndex];
        const contract: Contract = series.data[opts.dataPointIndex].meta;
        return contract.measuringDevice?.uuId + ' (' + contract.partner?.name + ')';
      }
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: "yyyy MMM",
          day: 'MMM dd',
          hour: 'HH:mm',
        },
      }
    },
    yaxis: {
      labels: {
        style: {
          cssClass: 'apexcharts-yaxis-label'
        }
      }
    },
    legend: {
      show:false,
      position: "bottom",
    },
  };

  private async saveProcess(result: Contract) {
    try {
      result.measuringPointId = result.measuringPoint ? result.measuringPoint.id : 0;
      result.start = this.service.setStartOfDay(result.start);
      result.end = this.service.setEndOfDay(result.end)
      this.service.validate(result, true);
      result = await lastValueFrom(this.service.repo.save(result));
      this.models.push(result);
      this.chart.refreshSeries();
    } catch (e: any) {
      this.service.validationErrorHandler(e);
    }

  }

  private isLock() {
    return (row: any) => {
      return row.locked && this.hasPermission;
    };
  }
  private isDeletable() {
    return (row: any) => {
      return this.hasPermission &&  (new Date(row.end)).getTime()>(new Date()).getTime();
    };
  }

  private unlock() {
    return async (row: any) => {
      await lastValueFrom(this.service.adminUnlock(row.id));
      this.loadData();
    };
  }

  public readonly GANT_DISTRIBUTOR_FILTER_COOKIE_KEY = GANT_DISTRIBUTOR_FILTER_COOKIE_KEY;
}

