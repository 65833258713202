import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {Partner} from "../../../models/partner.model";
import {PartnerService} from "../../../services/model/partner.service";
import {lastValueFrom} from "rxjs";
import {User} from "../../../models/user.model";

@Component({
  selector: 'app-add-partner-to-model',
  templateUrl: './add-partner-to-model.component.html',
  styleUrls: ['./add-partner-to-model.component.scss']
})
export class AddPartnerToModelComponent implements OnInit {
  modelPartners: Partner[];
  partners: Partner[] = [];
  selectedPartnerKeys: any[] = [];

  constructor(@Inject(NX_MODAL_DATA) public inputData: any, private partnerService: PartnerService) {
  }

  ngOnInit(): void {
    if (this.inputData.addon.partners) {
      this.modelPartners = [...this.inputData.addon.partners];
      this.selectedPartnerKeys = this.modelPartners.map(p => p.id);
    } else {
      this.modelPartners = []
    }
    if(this.inputData.partners){
      this.partners = this.inputData.partners;
    }else{
      this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    this.partners = await lastValueFrom(this.partnerService.getAllByDeleted());
  }

  getLabel() : (option: Partner) => string {
    return (option: Partner) => {
      return option.name
    };
  }

  partnerSelectionChange() {
    this.modelPartners = this.partners.filter(p => this.selectedPartnerKeys.includes(p.id));
  }
}
