import {Component, OnInit} from '@angular/core';
import {PageTest} from "../../utilities/PageTest";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
@PageTest({
  path: 'privacy-policy',
  singlePage: true
})
export class PrivacyPolicyComponent implements OnInit {

  pdfSrc = `${environment.url}/assets/docs/privacy.pdf`

  constructor() {
  }

  ngOnInit(): void {
  }

}
