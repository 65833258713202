import {Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {Partner, PartnerUser} from '../models/partner.model';
import {BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from "ng-pository";

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class PartnerRepository extends BaseRepository<PostPayloadInterface | any> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/admin/partners?deleted={deleted}')
  getAllByDeleted(deleted: boolean) {
    return super.get();
  }

  @Path('/distributor/partners?deleted={deleted}')
  getAllByDeletedAsDistributor(deleted: boolean) {
    return super.get();
  }

  @Path('/admin/partners?deleted={deleted}&dtoType={dtoType}')
  getAllByDeletedAndDtoType(deleted: boolean, dtoType: string) {
    return super.get();
  }

  @Path('/admin/partners/{id}')
  getById(id: string) {
    return super.get();
  }

  @Path('/admin/partners/by-user-key/{userKey}')
  getPartnersForUser(userKey: string) {
    return super.get();
  }
  
  @Path('/partner/partners/by-user-key/{userKey}')
  getPartnersForUserPartner(userKey: string) {
    return super.get();
  }

  @Path('/partner/partners/{id}/addon/generate-api-key')
  generateApiKey(id: number) {
    return super.patch();
  }

  @Path('/admin/partners')
  save(partner: Partner) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: partner
    });
  }

  @Path('/admin/partners/{id}')
  update(id: number, partner: Partner) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: partner
    });
  }

  @Path('/admin/partners/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  @Path('/admin/partners/{id}/users/add')
  addUsers(id: number, partnerUsers: PartnerUser[]) {
    return super.patch({id: id, users: partnerUsers});
  }

  @Path('/admin/partners/{id}/users/remove')
  removeUsers(id: number, userKeys: string[]) {
    return super.patch({id: id, userKeys: userKeys});
  }

  @Path('/admin/partners/{id}/addons/{addonId}/add')
  addAddon(id: number, addonId: number) {
    return super.patch();
  }

  @Path('/admin/partners/{id}/addons/{addonId}/remove')
  removeAddon(id: number, addonId: number) {
    return super.patch();
  }

}

