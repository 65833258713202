import {Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';
import {BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {ValidatorService} from '../services/validator.service';
import {Addon} from '../models/addon.model';

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class AddonRepository extends BaseRepository<PostPayloadInterface> {
  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/admin/addons?deleted={deleted}')
  getAllByDeleted(deleted: boolean) {
    return super.get();
  }

  @Path('/admin/addons/users/{userKey}')
  getAllForUser(userKey: string) {
    return super.get();
  }

  @Path('/partner/addons/users/{userKey}')
  getAllForUserAsPartner(userKey: string) {
    return super.get();
  }

  @Path('/admin/addons/{id}')
  getById(id: string) {
    return super.get();
  }

  @Path('/admin/addons')
  save(addon: Addon) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: addon
    });
  }

  @Path('/admin/addons/{id}')
  update(id: number, addon: Addon) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: addon
    });
  }

  @Path('/admin/addons/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  validate(editableRow: Addon) {
    ValidatorService.allRequired(editableRow, Object.keys(editableRow), 'Addon');
  }

}

