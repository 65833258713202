<div nxLayout="grid ">
  <div nxRow="">
    <div nxCol="3">
      <figure nxFigure="">
        <img
          [src]="model.attachment.srcWidth"
          [alt]="model.name"
        />
      </figure>
    </div>
    <div nxCol="9">
      <h3 nxHeadline="subsection-medium" class="bar-as-toolbar nx-margin-bottom-2xs">
        {{model.name}}
        <ng-container *ngIf="withNavigation">
          <a href="javascript:void(0)" (click)="goPartner()" class="back-left">
            <nx-icon [name]="'eye'"></nx-icon>
          </a>
        </ng-container>
      </h3>
      <nx-data-display  *ngIf="showUser" class="nx-margin-bottom-s" label="{{'partner.contacts' | caption}}" orientation="vertical">
        <div nxRow="">
          <ng-container *ngFor="let partnerUser of model.users">
            <div nxCol="6">
              <inside-show-user-contact [model]="partnerUser.user"></inside-show-user-contact>
            </div>
          </ng-container>
        </div>
      </nx-data-display>

    </div>
  </div>
</div>
