import {Component, OnInit} from '@angular/core';
import {PageTest} from "../../utilities/PageTest";
import {ROUTING} from "../../utilities/routing-constants";
import {PartnerLayoutComponent} from "../../layouts/partner-layout/partner-layout.component";
import {PartnerService} from "../../services/model/partner.service";
import {Partner} from "../../models/partner.model";
import {lastValueFrom} from "rxjs";
import {AUTH_USER_KEY} from "../../utilities/constants";
import {MyStorageService} from "../../services/my-storage.service";

@Component({
  selector: 'app-partner-api-key',
  templateUrl: './partner-api-key.component.html',
  styleUrls: ['./partner-api-key.component.scss']
})

@PageTest({
  path: ROUTING.ApiKeys,
  layout: PartnerLayoutComponent,
})
export class PartnerApiKeyComponent implements OnInit {
  partners: Partner[] = [];

  constructor(private partnerService: PartnerService, private myStorageService: MyStorageService) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    let authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, undefined);
    if (authUser) {
      const partnerForUser = await lastValueFrom(this.partnerService.getPartnersForUserPartner(authUser.id));
      this.partnerService.$partnerOfUser.next(partnerForUser);
      this.partners = partnerForUser;
    }
  }

  generateApiKey(partner: Partner) {
    this.partnerService.generateApiKey(partner.id).subscribe(result => {
      this.loadData()
    })
  }

  copyToClipboard(text: string) {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    tempTextArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
  }

}
