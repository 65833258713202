<div nxModalContent>
  <nx-card class="w-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.selectContractEditType' | caption}}
      </h3>
    </nx-card-header>
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'contract.editTypes' | caption}}">
            <nx-dropdown
              #ngModelDropdown
              name="test"
              [(ngModel)]="selectedEditType"
            >
              <ng-container *ngFor="let editType of editTypes">

                <nx-dropdown-item
                  [nxValue]="editType">{{'contract.selectEditType' + editType | caption}}</nx-dropdown-item>
              </ng-container>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <ng-container *ngIf="availableDateModifyingType && availableDateModifyingType != 'date'">
          <div nxCol="12">
            <nx-message nxContext="info" class="nx-margin-bottom-s">
              <span class="nx-font-weight-bold">{{'general.info' | caption}}</span><br/>
              {{'contract.' + availableDateModifyingType + 'Info' | caption}}
            </nx-message>
          </div>
        </ng-container>
        <div nxCol="12">
          <nx-message nxContext="info">
            <span class="nx-font-weight-bold">{{'contract.selectEditTypeHead' | caption}}</span><br/>
            {{'contract.selectEditType' | caption}}
          </nx-message>
        </div>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="selectedEditType"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.select' | caption}}
  </button>
</div>
