import {BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from "ng-pository";
import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {PostPayloadInterface} from "../utilities/post-payload.interface";
import {Observable} from "rxjs";

@RepoPath('/api')
@Injectable({
  providedIn: 'root'
})
export class ConfiguratorRepository extends BaseRepository<PostPayloadInterface | any> {
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  getImageUrl() {
    return this.getApiUrl() + '/api/current_image';
  }

  setUrl(url) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }

  @Path('/stats')
  getDeviceStatus() {
    return super.get();
  }

  @Path('/camera_stats')
  getCameraStat() {
    return super.get();
  }

  @Path('/detectors')
  getDetectors() {
    return super.get();
  }

  @Path('/logread')
  getLogs(name: string, type: string, seconds: number) {
    const body = {
      name: name,
      type: type,
      seconds: seconds
    };
    return super.post(JSON.stringify(body));
  }

  @Path('/logfile')
  getLogFile(name: string, type: string, seconds: number): Observable<HttpResponse<Blob>> {
    const body = {
      name: name,
      type: type,
      seconds: seconds
    };
    return super.post( JSON.stringify(body), { responseType: 'blob', observe: 'response' });
  }


  @Path('/config')
  saveConfig(body: any) {
    return super.post(body);
  }

  @Path('/config')
  getConfig() {
    return super.get();
  }
}
