<div nxModalContent>
  <nx-card class="w-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.addUserToModel' | caption}}
      </h3>
    </nx-card-header>
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12">
          <forms-user-form [user]="user" [withRoles]="false" [showAddress]="false"></forms-user-form>
        </div>
      </div>
<!--      <div nxRow="">-->
<!--        <div nxCol="12">-->
<!--          <forms-address-form [address]="user.address"></forms-address-form>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="user"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | caption}}
  </button>
</div>
