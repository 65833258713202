import {Component, OnInit} from '@angular/core';
import {PageLayout} from "../../utilities/PageTest";
import {ConfiguratorAuthGuard} from "./configurator-auth-guard.service";
import {StartLayoutComponent} from "../start-layout/start-layout.component";

@Component({
  selector: 'app-configurator-layout',
  templateUrl: './configurator-layout.component.html',
  styleUrls: ['./configurator-layout.component.css']
})
@PageLayout({
  path: 'config',
  authGuard: ConfiguratorAuthGuard,
  layout:StartLayoutComponent,
  data: {title: 'general.title.config'}
})
export class ConfiguratorLayoutComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {

  }

}


