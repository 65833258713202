import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CookieService} from 'ngx-cookie-service';
import {Utilities} from '../utilities/utilities';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MyStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
              private cookieService: CookieService) {
  }

  /**
   * Elmenti az kapott adatot a kapott kulcsra a cookie-k közé. A parse kapcsolót az adatot JSON stringify-olva menti a cookieba (ez az alapértelmezett működés)
   * @param key: Süti kulcsa
   * @param object: Süti mentendő adat
   * @param parse: True-> JSON.stringify metódus hívva az adaton. False-> a kapott adat átalakítás nélküli mentése
   */
  saveToCookies(key: string, object: any, parse = true) {
    const expireDate = Utilities.addHoursToDate(new Date(), environment.cookiesValidityHours);
    this.cookieService.set(key, (parse ? JSON.stringify(object) : object), {expires: expireDate, path:'/'});
  }

  /**
   * Lekéri a kapott kulcson tárolt adatot a cookie-k közül, ha nincs a kulcson adat akkor a fallback objectet adja vissza.
   * @param key: Süti kulcsa
   * @param fallbackObject: Adat nem létezése ezt adja vissza.
   * @param parse: True-> JSON.parse hívva az adaon. False -> A kiolvasott adat visszaadása.
   */
  getFromCookies(key: string, fallbackObject: any, parse = true) {
    const result = this.cookieService.get(key);
    return (result.length > 0 ? (parse ? JSON.parse(result) : result) : fallbackObject);
  }

  deleteFromCookies(key: string) {
    this.cookieService.delete(key, '/');
  }

  saveToStore(key: string, object: any) {
    this.storage.set(key, object)
  }

  getFromStore<T>(key: string, fallbackObject: T): T {
    return this.storage.has(key) ? this.storage.get(key) : fallbackObject;
  }

  deleteFromStore(key: string) {
    this.storage.remove(key);
  }

  checkKey(key: string) {
    return this.cookieService.check(key);
  }

}
