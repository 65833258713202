import {Injectable} from "@angular/core";
import {BaseModelService} from "./base-model.service";
import {UserRepository} from "../../repositories/user-repository";
import {Router} from "@angular/router";
import {ROUTING} from "../../utilities/routing-constants";
import {User} from "../../models/user.model";
import {ValidatorService} from "../validator.service";
import {map} from "rxjs";
import {IrfNotificationService} from "irf-services";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseModelService<UserRepository> {
  constructor(repo: UserRepository, notificationService: IrfNotificationService, router: Router,) {
    super(ROUTING.User, repo, notificationService, router);
  }

  getUsersByRole(role: string) {
    return this.repo.getUsersByRole(role).pipe(
      map(users => this.addExtendedPropertiesForModelList(users))
    );
  }

  getUsers() {
    return this.repo.getUsers().pipe(
      map(users => this.addExtendedPropertiesForModelList(users))
    );
  }

  getById(email: string) {
    return this.repo.getUserById(email).pipe(
      map(user => this.addExtendedPropertiesForModel(user))
    )
  }


  override addExtendedPropertiesForModel(model: any): any {
    if (model) {
      model.name = model.firstName + " " + model.lastName;
      model.id = model.keycloakId;
    }
    return model;
  }

  override validate(editableRow: User) {
    const attributeBlackList = ['partners','distributors']
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    //ValidatorService.validateAddress(editableRow.address, "UserInfo");
    ValidatorService.allRequired(editableRow, attributeList, "UserInfo");
  }

}
