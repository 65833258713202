import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";
import {AUTH_USER_KEY, ROLE_CARD} from "../../utilities/constants";
import {MyStorageService} from "../../services/my-storage.service";
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";

@Component({
  selector: 'page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public authUser!: KeycloakProfile;
  public roleCards!: any[];
  @Input() withNavigation: boolean = true;
  private readonly _destroyed = new Subject<void>();
  mobil: boolean = true;

  constructor(private keycloakService: KeycloakService,
              private myStorageService: MyStorageService,
              public viewportService: NxViewportService,
              private _cdr: ChangeDetectorRef,) {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobil = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
    this.authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, {});
  }

  async logout() {
    this.myStorageService.deleteFromCookies(AUTH_USER_KEY);
    await this.keycloakService.logout();
  }
}
