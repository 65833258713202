import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'joint'
})
export class JointPipe implements PipeTransform {

  transform(input: any[], propertyName?: string, separator = ', '): string {
    if (input != undefined) {
      if (propertyName) {
        input = input.map(data => data[propertyName]);
      }
      return input.join(separator);
    }
    return "";
  }

}
