import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PageTest} from "../../utilities/PageTest";
import {ROUTING} from "../../utilities/routing-constants";
import {PartnerLayoutComponent} from "../../layouts/partner-layout/partner-layout.component";
import {Webhook, WebhookType} from "../../models/webhook.model";
import {AbstractListPage} from "../../models/AbstractListPage";
import {WebhookService} from "../../services/model/webhook-service";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {MyStorageService} from "../../services/my-storage.service";
import {NxDialogService} from "@aposin/ng-aquila/modal";
import {AddNewWebhookComponent} from "../../components/modal/add-new-webhook/add-new-webhook.component";
import {DATA_TYPE_FORMATTER_MAP, JCD_ADDON_MENU} from "jcd-addon";
import {Utilities} from "../../utilities/utilities";
import {PartnerService} from "../../services/model/partner.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.scss']
})
@PageTest({
  path: ROUTING.Webhook.basePlural,
  layout: PartnerLayoutComponent,
})
export class WebhookComponent extends AbstractListPage<Webhook[], WebhookService> implements OnInit {
  readonly WebhookType = WebhookType;
  private partnerIds: number[];

  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, translateService: AbstractCaptionService, service: WebhookService, myStorageService: MyStorageService,
              private readonly dialogService: NxDialogService, private partnerService: PartnerService) {
    super(viewportService, cdr, translateService, service, myStorageService);

    this.partnerIds = this.partnerService.$partnerOfUser.getValue().map(p => p.id);
  }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.models = [];

    this.service.getAllByPartners(this.partnerIds).subscribe(result => {
      this.models = result;
    });
  }

  openNewDialog(type: string) {
    const webhook = new Webhook();
    webhook.type = type as WebhookType;
    this.dialogService.open(
      AddNewWebhookComponent,
      Utilities.getDefaultModalConfig(webhook)
    ).afterClosed().subscribe(result => {
      if (result instanceof Webhook) {
        try {
          this.service.validate(result);
          this.service.repo.save(result).subscribe(result => {
            this.loadData();
          })
        } catch (e: any) {
          this.service.validationErrorHandler(e);
        }
      }
    });
  }

  openEditDialog(webhook: Webhook) {
    this.dialogService.open(
      AddNewWebhookComponent,
      Utilities.getDefaultModalConfig(webhook)
    ).afterClosed().subscribe(result => {
      if (typeof result == "object") {
        try {
          this.service.validate(result);
          this.service.repo.update(webhook.id, result).subscribe(result => {
            this.loadData();
          })
        } catch (e: any) {
          this.service.validationErrorHandler(e);
        }
      }
    });
  }

  delete(webhook: Webhook) {
    this.service.notificationService.confirm('Warning', 'Are u sure?', 'general.ok', 'general.cancel').then(async result => {
      if (result.value) {
        await lastValueFrom(this.service.repo.deleteById(webhook.id.toString()));
        this.loadData();
      }
    });
  }
}
