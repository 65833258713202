<div nxModalContent>
  <nx-card class="w-100 h-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'qrcodeModal.qrCodeModal' | caption}}
      </h3>
    </nx-card-header>
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12,12,6" class="text-center">

          <nx-data-display label="{{'qrcodeModal.updateLevel' | caption}}" orientation="vertical" class="nx-hidden-m nx-hidden-s nx-hidden-xs">
            <ng-container *ngFor="let l of levelOptions">
              <button class="nx-margin-s " nxButton="{{l == level?'secondary':'cta'}} small" type="button" (click)="qrLevel(l)">{{l}}</button>
            </ng-container>
          </nx-data-display>
        </div>
        <div nxCol="12,12, 6" class="text-center">
          <nx-data-display label="{{'qrcodeModal.updateWidth' | caption}}" orientation="vertical" class="nx-hidden-m nx-hidden-s nx-hidden-xs">
            <ng-container *ngFor="let w of widthOptions">
              <button class="nx-margin-s " nxButton="{{w == width?'secondary':'cta'}} small" type="button" (click)="qrWidth(w)">{{w}}</button>
            </ng-container>
          </nx-data-display>

        </div>
        <div nxCol="12" class="text-center">
          <qr-code [value]="value" [size]="width" [level]="level"></qr-code>
        </div>

      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
</div>
