<nx-card class="viewer">
  <nx-card-header>
    <page-form-header [model]="model" [titleProperty]="'uuId'"></page-form-header>
  </nx-card-header>

  <page-form-tool-bar
    [saveSupplier]="save()"
    [cancelSupplier]="cancel()"
    [modalOptions]="toolbarAddOptions"
  ></page-form-tool-bar>

  <forms-measuring-device-form [model]="model"></forms-measuring-device-form>

</nx-card>
