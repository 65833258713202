<!--<div class="search-bar">-->
<!--  <button nxIconButton="primary" (click)="openFilter()">-->
<!--    <nx-icon name="filter"></nx-icon>-->
<!--  </button>-->
<!--</div>-->
<apx-chart #chartComponent [series]="options.series"
           [chart]="options.chart"
           [plotOptions]="options.plotOptions"
           [dataLabels]="options.dataLabels"
           [legend]="options.legend"
           [xaxis]="options.xaxis"
           [yaxis]="options.yaxis"
           [tooltip]="options.tooltip"
[fill]="options.fill"
></apx-chart>

<ng-template #search>
  <div nxModalContent class="nx-margin-top-m">
    <nx-card class="w-100 h-100">
      <nx-card-header>
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
          {{'contract.filter' | caption}}
        </h3>
      </nx-card-header>
      <div nxLayout="grid">
        <ng-container *ngFor="let filterKey of filterKeys">
        <div nxRow="">
          <div nxCol="12" class="nx-margin-bottom-s">  <h3 nxHeadline="subsection-small">{{'contract.filterKey.'+filterKey | caption}}</h3></div>
          <ng-container *ngFor="let element of gantFilter[filterKey] | keyvalue">
            <div nxCol="3">
              <nx-switcher class="nx-margin-bottom-s" (checkedChange)="changeFilterEvent()" labelSize="small" [(ngModel)]="element.value!.show">{{element.key}}</nx-switcher>
            </div>
          </ng-container>
        </div>
        </ng-container>
      </div>
    </nx-card>

  </div>
</ng-template>
