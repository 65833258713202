<ng-container *ngIf="model">
  <nx-card class="p-0 w-100">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.name"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>
    <div nxRow="" class="nx-margin-top-s">
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <img [src]="model.attachment.srcWidth" alt="{{model.name}}" width="50"/>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.name}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,8,9">
        <div nxRow="">
          <ng-container *ngFor="let userEntry of model.users">
            <div nxCol="12,12,6,4,3">
              <nx-card class="box">
                <nx-card-header>
                  <p>{{userEntry.user.email}}<br> {{userEntry.user.phone}}</p>
                  <h3 class="nx-margin-y-s">
                    {{userEntry.user.firstName}} {{userEntry.user.lastName}}
                  </h3>
                </nx-card-header>
              </nx-card>
            </div>
          </ng-container>
        </div>
      </div>
      <div nxCol="12,12,6">
        <h4 nxHeadline="subsection-small">{{'measuringDevice.apiKey' | caption}}</h4>
        {{model.apiKey}}

      </div>
      <div nxCol="12,12,6">
        {{model.address | address}}
      </div>
      <div nxCol="12">
        <hr>
        <ng-container *ngFor="let emailType of notExistType">
          <button nxPlainButton class="nx-margin-right-2m" (click)="add(emailType)">
            {{'general.add' | caption}} {{'partner.emailType.' + emailType | caption}}
          </button>
        </ng-container>

        <h4 nxHeadline="subsection-small">{{'partner.existingReportEmail' | caption}}</h4>
        <ul nxList>
          <ng-container *ngFor="let email of model.reportEmails">
            <li class="d-flex align-items-end">
              <span>{{'partner.emailType.' + email.type |caption}}
                <ng-container *ngIf="email.lastUpdate">
                  ({{email.lastUpdate | date:'yyyy.MM.dd'}})
                </ng-container> </span>
              <button nxPlainButton class="nx-margin-left-s" (click)="removeEmail(email)">
                <nx-icon name="trash"></nx-icon>
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nx-card>
</ng-container>
