import {Component, Input, OnInit} from '@angular/core';
import {Partner} from "../../../models/partner.model";
import {AttachmentService} from "../../../services/model/attachment.service";
import {PartnerService} from "../../../services/model/partner.service";

@Component({
  selector: 'inside-show-partner',
  templateUrl: './show-partner.component.html',
  styleUrls: ['./show-partner.component.scss']
})
export class ShowPartnerComponent implements OnInit {

  @Input() model: any | Partner;
  @Input() withNavigation: boolean = true;
  @Input() showUser: boolean = true;

  constructor(private attachmentService: AttachmentService, private service: PartnerService) {
  }

  ngOnInit(): void {
    if (this.model.attachment) {
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachment);
    } else {
      this.model.attachment = {
        src: 'assets/img/default.png',
        srcWidth: 'assets/img/default.png'
      }
    }
  }

  goPartner() {
    this.service.show(this.model.id);
  }
}
