import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {PartnerRepository} from '../../repositories/partner-repository';
import {IrfNotificationService} from "irf-services";
import {Partner, PartnerUser} from "../../models/partner.model";
import {ValidatorService} from "../validator.service";
import {PARTNER_USER_TYPES} from "../../utilities/constants";
import {BehaviorSubject, map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends BaseModelService<PartnerRepository> {
  $partnerOfUser: BehaviorSubject<Partner[]> = new BehaviorSubject<Partner[]>([]);

  constructor(repo: PartnerRepository, notificationService: IrfNotificationService, router: Router,) {
    super(ROUTING.Partner, repo, notificationService, router);
  }

  getAllByDeleted(dtoType?: string, deleted = false) {
    if (dtoType) {
      return this.repo.getAllByDeletedAndDtoType(deleted, dtoType).pipe(
        map(partners => this.addExtendedPropertiesForModelList(partners))
      );
    } else {
      return this.repo.getAllByDeleted(deleted).pipe(
        map(partners => this.addExtendedPropertiesForModelList(partners))
      );
    }
  }

  getAllByDeletedAsDistributor() {
    return this.repo.getAllByDeletedAsDistributor(false).pipe(
      map(partners => this.addExtendedPropertiesForModelList(partners))
    );
  }

  getById(id: string) {
    return this.repo.getById(id).pipe(
      map(partner => this.addExtendedPropertiesForModel(partner))
    );
  }

  getPartnersForUser(userKey: string) {
    return this.repo.getPartnersForUser(userKey);
  }
  
  getPartnersForUserPartner(userKey: string) {
    return this.repo.getPartnersForUserPartner(userKey);
  }

  generateApiKey(id: number) {
    return this.repo.generateApiKey(id);
  }

  override addExtendedPropertiesForModel(model: any): any {
    if (model.users) {
      const tmp = model.users.map((user: any) => {
        if (user.user) {
          const a = new PartnerUser(user.user.keycloakId);
          a.user = user.user;
          a.type = user.type;
          return a;
        } else {
          return new PartnerUser(user.userKey);
        }
      });
      model.users = [...tmp];
      const contacts = model.users.filter((user: any) => user.type == PARTNER_USER_TYPES.CONTACT);
      model.contact = contacts.length > 0 ? contacts[0].user : undefined;


    }
    return model;
  }

  override validate(editableRow: Partner) {
    const attributeBlackList = ['id', 'slug', 'createdAt', 'updatedAt', 'rules', 'measuringDevices', 'measuringPoints', 'addons', 'contracts', 'contact', 'attachment','apiKey','reportEmails'];
    const requiredList = ['attachmentId', 'users'];
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    requiredList.forEach(key => {
      if (!attributeList.includes(key)) {
        attributeList.push(key);
      }
    });
    ValidatorService.allRequired(editableRow, attributeList, 'Partner');
  }

}
