import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Addon} from '../../../models/addon.model';
import {lastValueFrom} from 'rxjs';
import {AUTH_USER_KEY} from '../../../utilities/constants';
import {AddonService} from '../../../services/model/addon.service';
import {MyStorageService} from "../../../services/my-storage.service";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {TableAction, TableCol} from "ng-aquila-datatable";
import {AbstractListPage} from "../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {AbstractCaptionService} from "irf-caption";
import {Utilities} from "../../../utilities/utilities";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {
  AddPartnerToModelComponent
} from "../../../components/modal/add-partner-to-model/add-partner-to-model.component";
import {PartnerService} from "../../../services/model/partner.service";
import {Partner} from "../../../models/partner.model";

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss']
})
@PageTest({
  path: ROUTING.Addon.basePlural
})
export class AddonsComponent extends AbstractListPage<Addon[], AddonService> implements OnInit {

  colDef: TableCol[] = [];
  actionDef: TableAction[];

  componentDialogRef!: NxModalRef<any>;
  @ViewChild('test') hdrTpl: TemplateRef<any>;
  private partners: Partner[];


  constructor(viewportService: NxViewportService,
              cdr: ChangeDetectorRef,
              translateService: AbstractCaptionService,
              service: AddonService,
              myStorageService: MyStorageService,
              private partnerService: PartnerService,
              public dialogService: NxDialogService,) {
    super(viewportService, cdr, translateService, service, myStorageService);

  }

  ngOnInit(): void {

    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show')),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.rowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.rowPermission()),
      new TableAction('people-group', this.addPartner(), await this.getTranslate('table.action.addPartner'), this.rowPermission()),
    ];

    this.colDef = [
      TableCol.newString('id', await this.getTranslate('general.id')),
      TableCol.newString('name', await this.getTranslate('general.name')),
      TableCol.newString('companyName', await this.getTranslate('addon.companyName')),
      TableCol.newString('moduleName', await this.getTranslate('addon.moduleName')),
    ];
    this.models = await lastValueFrom(this.service.repo.getAllByDeleted(false));
    this.partners = await lastValueFrom(this.partnerService.getAllByDeleted());
    this.models.forEach(model => {
      model.partners = this.partners.filter((p: any) => p.addons.map(a => a.addon.id).includes(model.id))
    });
  }

  private delete(): (row: any) => void {
    return async (row: any) => {
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteById(row.id));
          this.loadData();
        }
      });
    }
  }

  private addPartner() {
    return (addon: Addon) => {
      this.componentDialogRef = this.dialogService.open(
        AddPartnerToModelComponent,
        Utilities.getDefaultModalConfig({addon: addon, partners: this.partners})
      );
      this.componentDialogRef.afterClosed().subscribe(async result => {
        if (result != 'cancel') {
          addon.partners.forEach(async partner => {
            if (result.find(p => p.id == partner.id) == undefined) {
              await lastValueFrom(this.partnerService.repo.removeAddon(partner.id, addon.id));
            }
          });
          result.forEach(async partner => {
            if (addon.partners.find(p => p.id == partner.id) == undefined) {
              await lastValueFrom(this.partnerService.repo.addAddon(partner.id, addon.id));
            }
          })
          addon.partners = result;
        }
      });
    };
  }
}
