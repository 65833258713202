import {Injectable} from "@angular/core";
import {PostPayloadInterface} from "../utilities/post-payload.interface";
import {HttpClient} from "@angular/common/http";
import {BaseRepository, Path, PositoryErrorHandlerService, RepoPath} from "ng-pository";

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class TestRepository extends BaseRepository<PostPayloadInterface> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/actuator/mappings')
  rawMappings(param: any) {
    return super.get();
  }
}

