<nx-data-display class="nx-margin-bottom-s" label="{{'general.name' | caption}}" orientation="horizontal-columns">
  {{model.name}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'general.description'| caption}}"
                 orientation="horizontal-columns">
  {{model.description}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'detector.versionNumber'| caption}}"
                 orientation="horizontal-columns">
  {{model.versionNumber}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'detector.uuId'| caption}}" orientation="horizontal-columns">
  {{model.uuId}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'general.createdAt'| caption}}"
                 orientation="horizontal-columns">
  {{model.createdAt | date:'yyyy.MM.dd HH:mm'}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'general.updatedAt'| caption}}"
                 orientation="horizontal-columns">
  {{model.updatedAt | date:'yyyy.MM.dd HH:mm'}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'detector.algorithmFile'| caption}}"
                 orientation="horizontal-columns">
  {{model.algorithm.title}}
</nx-data-display>
<nx-data-display class="nx-margin-bottom-s" label="{{'detector.remoteInstall'| caption}}"
                 orientation="horizontal-columns">
  {{(model.remoteInstell ? 'general.yes' : 'general.no') | caption}}
</nx-data-display>
