import {Component, OnInit} from '@angular/core';
import {PageLayout} from "../../utilities/PageTest";
import {PartnerAuthGuard} from "./partner-auth-guard.service";
import {MyStorageService} from "../../services/my-storage.service";
import {AddonRepository} from "../../repositories/addon-repository";
import {AbstractBaseComponent} from "common";
import {Router} from "@angular/router";
import {StartLayoutComponent} from "../start-layout/start-layout.component";


@Component({
  selector: 'app-partner-layout',
  templateUrl: './partner-layout.component.html',
  styleUrls: ['./partner-layout.component.css']
})
@PageLayout({
  path: 'partner',
  authGuard: PartnerAuthGuard,
  layout:StartLayoutComponent,
  data: {title: 'general.title.partner'}
})
export class PartnerLayoutComponent extends AbstractBaseComponent implements OnInit {
  constructor(private myStorageService: MyStorageService, private addonRepository: AddonRepository, private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

}
