<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="row mb-3">
        <div class="col-6">
          <div class="form-floating mb-3">
            <select class="form-select no-outline" id="logType" [(ngModel)]="logTypeIndex" (change)="getLogs()">
              <option value=0>Service</option>
              <option value=1>RVC</option>
              <option value=2>Uploader</option>
            </select>
            <label for="logType" class="form-label">{{ 'logs.type' | translate }}</label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-floating mb-3">
          <input type="number" class="form-control" id="days" [(ngModel)]="days" (change)="getLogs()"/>
            <label for="days" class="form-label">{{ 'logs.days' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 ">
      <div class="bg-dark p-3 flex-grow-1 " style="overflow: auto;">
        <pre class="text-white  min-height" style="font-size:small;">
            <span *ngFor="let log of logs">{{log}}<br></span>
        </pre>
      </div>
    </div>
    <div class="col-6 mt-3">
      <button nxButton="secondary small" (click)="getLogFile()"> {{ 'logs.download' | translate }} </button>
    </div>
  </div>
</div>
