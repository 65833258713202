import {KeycloakProfile} from "keycloak-js";
import {MyStorageService} from "../services/my-storage.service";
import {AUTH_USER_KEY, ROLE_INDEX} from "../utilities/constants";
import {AbstractBaseComponent} from "common";

export abstract class PermissionCheckComponent extends AbstractBaseComponent {

  authUser: KeycloakProfile | any;

  hasPermission: boolean;

  protected constructor(protected myStorageService: MyStorageService,) {
    super();
  }

  protected initPermission() {
    this.authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, {});
    this.authUser.attributes.chmod = JSON.parse(this.authUser.attributes.chmod);
    this.hasPermission = this.permission();

  }

  protected permission(): boolean {
    const chmodElement = this.authUser.attributes.chmod[ROLE_INDEX['admin']];
    console.log(this.authUser, chmodElement);
    return chmodElement > 4;
  }

  protected rowPermission(): (row: any) => boolean {
    return (row: any) => {
      return this.hasPermission;
    }
  }
}
