import {Component, Inject, OnInit, Type} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {AttachmentService} from "../../../services/model/attachment.service";
import {
  ImageAttachmentElementComponent
} from "../../attachment/image-attachment-element/image-attachment-element.component";
import {lastValueFrom} from "rxjs";

export class AddImageModalInput {
  model: any;
  multiple: boolean = false;
  maxElement: number = 1;

  constructor(model: any, multiple = false, maxElement = 1) {
    this.model = model;
    this.multiple = multiple;
    this.maxElement = maxElement;
  }
}

@Component({
  selector: 'app-add-image-to-model',
  templateUrl: './add-image-to-model.component.html',
  styleUrls: ['./add-image-to-model.component.scss']
})
export class AddImageToModelComponent implements OnInit {

  model: any;
  availableAttachments: any[];
  itemAttachments: any[] = [];
  selectedAttachment: any;
  imgViewComponent: Type<any> = ImageAttachmentElementComponent;

  constructor(@Inject(NX_MODAL_DATA) public componentConfig: AddImageModalInput,
              private attachmentService: AttachmentService,) {
    this.model = this.componentConfig.model;
  }

  async ngOnInit(): Promise<void> {
    this.availableAttachments = await lastValueFrom(this.attachmentService.repo.getAllImages());
    this.availableAttachments = this.attachmentService.addExtendedPropertiesForModelList(this.availableAttachments);
    if (this.model.attachment) {
      this.itemAttachments = [{...this.model.attachment}];
    }
    if (this.model.attachments && this.model.attachments.length > 0) {
      this.itemAttachments = [{...this.model.attachments[0].attachment}];
    }
    if (this.itemAttachments.length > 0) {
      this.selectedAttachment = this.itemAttachments[0];
    }
    this.availableAttachments = this.availableAttachments.filter(attachment => {
      return !this.itemAttachments.map(a => a.id).includes(attachment.id);
    });
  }

  attachmentRefresh(): void {
    if (this.componentConfig.multiple) {
      this.selectedAttachment = this.itemAttachments;
    } else {
      this.selectedAttachment = this.itemAttachments[0];
    }
  }

  canBeAddedToItemList() {
    return this.itemAttachments.length < this.componentConfig.maxElement;
  }

  attachmentSave(attachment: any): void {
    attachment = this.attachmentService.addExtendedPropertiesForModel(attachment);
    if (this.canBeAddedToItemList()) {
      this.itemAttachments.push(attachment);
    } else {
      this.availableAttachments.push(attachment);
    }
  }

}
