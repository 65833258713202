import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {KeycloakProfile} from "keycloak-js";
import {AUTH_USER_KEY, ROLE_INDEX} from "../../utilities/constants";
import {MyStorageService} from "../../services/my-storage.service";
import {PermissionCheckComponent} from "../../models/PermissionCheckComponent";

export class ViewChanger {
  static VIEW_NAMES = {LIST:'LIST',TABLE:'TABLE',MAP:'',GANT:''}
  viewName: string;
  show: boolean = false;
  icon: string;

  constructor(viewName: string, show: boolean, icon: string) {
    this.viewName = viewName;
    this.show = show;
    this.icon = icon;
  }
}

export type VIEW_CHANGER_CONTAINER = { [key: string]: ViewChanger }

@Component({
  selector: 'page-list-view-header',
  templateUrl: './list-view-header.component.html',
  styleUrls: ['./list-view-header.component.scss']
})
export class ListViewHeaderComponent extends PermissionCheckComponent implements OnInit {
  @Input() newSupplier: () => void;
  @Input() title: string;
  @Input() viewChangers: VIEW_CHANGER_CONTAINER;
  @Output() viewChangersChange: EventEmitter<VIEW_CHANGER_CONTAINER> = new EventEmitter<VIEW_CHANGER_CONTAINER>();

  mobile: boolean = false;

  private readonly _destroyed = new Subject<void>();

  constructor(myStorageService:MyStorageService,
              public viewportService: NxViewportService,
              private _cdr: ChangeDetectorRef,) {
    super(myStorageService);
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
   this.initPermission();
  }

  changeView(setKey: string) {
    Object.keys(this.viewChangers).forEach(key => {
      this.viewChangers[key].show = key == setKey;
    });
    this.viewChangersChange.emit(this.viewChangers);
  }

}
