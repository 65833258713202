import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NxDatepickerComponent} from "@aposin/ng-aquila/datefield";
import * as moment from "moment/moment";
import {Contract} from "../../../models/contract.model";
import {MeasuringDeviceService} from "../../../services/model/measuring-device.service";
import {MeasuringPointService} from "../../../services/model/measuring-point.service";
import {PartnerService} from "../../../services/model/partner.service";
import {lastValueFrom} from "rxjs";
import {CONTRACT_EDIT_TYPES} from "../../modal/select-contract-edit-type/select-contract-edit-type.component";
import {ContractService} from "../../../services/model/contract.service";
import {MyStorageService} from "../../../services/my-storage.service";
import {DENY_DATE_KEYS} from "../../../utilities/constants";
import {Moment} from "moment";
import {MeasuringPointDistributorService} from "../../../services/model/measuring-point-distributor.service";
import {ContractDistributorService} from "../../../services/model/contract-distributor.service";
import {AbstractCaptionService} from "irf-caption";
import {DATA_TYPE_FORMATTER_MAP, JCD_ADDON_MENU} from "jcd-addon";
import {Utilities} from "../../../utilities/utilities";
@Component({
  selector: 'forms-edit-contract-form',
  templateUrl: './edit-contract-form.component.html',
  styleUrls: ['./edit-contract-form.component.scss']
})
export class EditContractFormComponent implements OnInit {
  @ViewChild('endDatePicker') endDatepicker!: NxDatepickerComponent<Moment>;
  @Input() model: Contract;
  @Input() editableType: CONTRACT_EDIT_TYPES;
  @Input() asDistributor:boolean = false;
  measuringDevices?: any[];
  measuringPoints?: any[];
  partners?: any[];
  denyDates: string[] = [];
  firstStartDenyDate: Moment | null;
  firstEndDenyDate: Moment | null;
  modelStart: Moment;
  modelEnd: Moment;
  chartTypes: any[];
  menuConfig: any[];

  constructor(private measuringDeviceService: MeasuringDeviceService,
              private measuringPointService: MeasuringPointService,
              private measuringPointDistributorService: MeasuringPointDistributorService,
              private contractService: ContractService,
              private contractDistributorService: ContractDistributorService,
              private myStorageService: MyStorageService,
              private partnersService: PartnerService,
              private captionService: AbstractCaptionService) {
  }

  async ngOnInit(): Promise<void> {
    if (this.asDistributor) {
      if (this.model.distributor) {
        this.loadDataAsDistributor();
      } else {
        this.partnersService.notificationService.showWarning('general.warning', 'error.noDistributor');
      }
    } else {
      this.loadData();
    }
    this.chartTypes = await Utilities.getChartTypesForForms();
    this.menuConfig = await Utilities.getAddOnMenuOptionsForForms();
  }
  private async loadDataAsDistributor(): Promise<void> {
    this.partners = await lastValueFrom(this.partnersService.getAllByDeletedAsDistributor());
    if (this.editableType == 'measuringDevice' && this.model.distributor) {
      this.measuringDevices = await lastValueFrom(this.measuringDeviceService.getAllWithoutContractForDistributor(this.model.distributor.id,this.model.start, this.model.end));
    }
    if (this.editableType == 'measuringPoint'&& this.model.distributor) {
      this.measuringPoints = await lastValueFrom(this.measuringPointDistributorService.getAllWithoutContract(this.model.distributor.id,this.model.start, this.model.end));
    }
    if (this.editableType == 'date') {
      this.initDenyDates()
    }
  }

  private async loadData(): Promise<void> {
    this.partners = await lastValueFrom(this.partnersService.getAllByDeleted());
    if (this.editableType == 'measuringDevice') {
      this.measuringDevices = await lastValueFrom(this.measuringDeviceService.getAllWithoutContract(this.model.start, this.model.end));
    }
    if (this.editableType == 'measuringPoint') {
      this.measuringPoints = await lastValueFrom(this.measuringPointService.getAllWithoutContract(this.model.start, this.model.end));
    }
    if (this.editableType == 'date') {
      this.initDenyDates()
    }
  }

  private async initDenyDates(): Promise<void> {
    this.modelStart = moment(this.model.start);
    this.modelEnd = moment(this.model.end);
    this.denyDates = this.myStorageService.getFromStore(DENY_DATE_KEYS.DENY_DATES, []);
    this.firstStartDenyDate = this.myStorageService.getFromStore(DENY_DATE_KEYS.FIRST_START_DENY_DATE, null);
    if(this.firstStartDenyDate != null){
      this.firstStartDenyDate = moment(this.firstStartDenyDate);
    }
    this.firstEndDenyDate = this.myStorageService.getFromStore(DENY_DATE_KEYS.FIRST_END_DENY_DATE, null);
    if(this.firstEndDenyDate != null){
      this.firstEndDenyDate = moment(this.firstEndDenyDate);
    }
  }

  updatePartner(id: any) {
    this.model.partner = this.partners?.find(p => p.id == id);
  }

  updatePoint(id: any) {
    this.model.measuringPoint = this.measuringDevices?.find(p => p.id == id);
  }

  updateDevice(id: any) {
    this.model.measuringDevice = this.measuringDevices?.find(md => md.id == id);
  }

  denyDate(): (date: Moment | null) => boolean {

    return (date) => {
      if (!date) {
        return true;
      }
      return this.denyDates.map(d => moment(d)).find(m => m.isSame(date, 'day')) === undefined;
    };
  }
}
