import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexTheme,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts/lib/model/apex-types";
import {GANT_FILTER_COOKIE_KEY} from "../../utilities/constants";
import {MyStorageService} from "../../services/my-storage.service";
import {ContractGANTFilter} from "../../services/model/base-contract.service";

@Component({
  selector: 'app-gant-chart',
  templateUrl: './gant-chart.component.html',
  styleUrls: ['./gant-chart.component.scss']
})
export class GantChartComponent implements OnChanges {

  @Input() models: any[];
  @Input() service: GantService<any, any>
  @Input() options: ApexOption;
  @Input() cookieKey:string = GANT_FILTER_COOKIE_KEY;

  filterKeys: any[];
  gantFilter!: ContractGANTFilter;

  constructor(
    private myStorageService: MyStorageService,) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.models) {
      if (!this.myStorageService.checkKey(this.cookieKey)) {
        this.gantFilter = this.service.createFilterObjectFromModels(this.models);
      } else {
        this.gantFilter = Object.assign(new ContractGANTFilter(), this.myStorageService.getFromCookies(this.cookieKey, new ContractGANTFilter(), true));
      }
      console.log(this.gantFilter);
      this.options.series = this.service.convertModelListToGANTSeries(this.models, this.gantFilter);

      this.filterKeys = Object.keys(this.gantFilter);
    }
  }

  changeFilterEvent() {
    this.options.series = this.service.convertModelListToGANTSeries(this.models, this.gantFilter);
    this.myStorageService.saveToCookies(this.cookieKey, this.gantFilter, true);
  }

  refreshSeries() {
    this.options.series = this.service.convertModelListToGANTSeries(this.models, this.gantFilter);
  }

  protected readonly getComputedStyle = getComputedStyle;
  showFilter: boolean = true;
}

export interface GantService<T, F> {
  convertModelListToGANTSeries(models: T, filter: F);

  createFilterObjectFromModels(models: T): F;
}

export interface ApexOption {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions
  dataLabels: ApexDataLabels
  xaxis: ApexXAxis
  yaxis: ApexYAxis
  legend: ApexLegend
  tooltip: ApexTooltip
  theme?: ApexTheme,
  fill: ApexFill
}

export interface GANTFilter {

}

export interface ContractGANTFilterElement {
  name: string;
  show: boolean
}

export type GANT_FILTER_ELEMENTS = { [key: string]: ContractGANTFilterElement };
