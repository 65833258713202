<ng-container *ngIf="withImage">
<div nxRow="">
  <div nxCol="3">
    <div nxRow="">
      <div nxCol="12">
        <figure nxFigure="">
          <img
            [src]="model.attachment.srcWidth"
            [alt]="model.uuId"
          />
        </figure>
      </div>
    </div>
  </div>
  <div nxCol="9">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>
</ng-container>

<ng-container *ngIf="!withImage">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <h3 nxHeadline="subsection-large" class="bar-as-toolbar nx-margin-bottom-2xs">
    {{model.name}}
    <ng-container *ngIf="withNavigation">
      <a href="javascript:void(0)" (click)="goPartner()" class="back-left">
        <nx-icon [name]="'eye'"></nx-icon>
      </a>
    </ng-container>
  </h3>
  <nx-data-display class="nx-margin-bottom-s" label="{{'measuringPoint.address' | caption}}"
                   orientation="vertical">
    {{model.address | address}}
  </nx-data-display>
  <nx-data-display class="nx-margin-bottom-s" label="{{'general.description' | caption}}"
                   orientation="vertical">
    {{model.description}}
  </nx-data-display>
  <nx-data-display class="nx-margin-bottom-s" label="{{'general.timezone' | caption}} & {{'general.threshold' | caption}}"
                   orientation="vertical">
    {{model.timezone}}, {{model.threshold}} sec
  </nx-data-display>
</ng-template>
