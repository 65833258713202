import {Component, Input, OnInit} from '@angular/core';
import {MeasuringDevice} from "../../../models/measuring-device.model";
import {Utilities} from "../../../utilities/utilities";
import {lastValueFrom} from "rxjs";
import {DetectorService} from "../../../services/model/detector.service";
import {Detector} from "../../../models/detector.model";
import {AbstractBaseComponent, OptionItemModel} from "common";
import {ColorEvent} from "ngx-color";
import {Distributor} from "../../../models/distributor.model";
import {DistributorService} from "../../../services/model/distributor.service";

@Component({
  selector: 'forms-measuring-device-form',
  templateUrl: './measuring-device-form.component.html',
  styleUrls: ['./measuring-device-form.component.scss']
})
export class MeasuringDeviceFormComponent extends AbstractBaseComponent implements OnInit {

  @Input() model: MeasuringDevice = new MeasuringDevice();
  detectors: Detector[];
  distributors: Distributor[];
  detectorDataSource: Promise<Array<OptionItemModel>>;

  constructor(private detectorService: DetectorService,private distributorService:DistributorService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.detectorDataSource = new Promise<Array<OptionItemModel>>(async resolve => {
      this.detectors = await lastValueFrom(this.detectorService.getAllByDeleted(false));
      const tmp = this.detectors.map(detector => {
        return {'code': detector.id.toString(), 'name': detector.name, 'description': detector.name}
      });
      resolve(tmp);
    })
    this.distributors = await lastValueFrom(this.distributorService.getAllByDeleted());
  }

  huToEn() {
    this.model.uuId = Utilities.huToEn(this.model.uuId);
  }

  //
  // changeDetector(selected: OptionItemModel) {
  //   this.model.detectorId = selected.code;
  // }
  changeColor($event: ColorEvent) {
    this.model.color = $event.color.hex;
  }

  trim(varName: string): string {
    const whitespaceRegExp = / /g
    return this.model[varName].replace(whitespaceRegExp, '')
  }
}
