import {Component, OnInit} from '@angular/core';
import { ColumnDef, GridOptions} from "common";
import {UserRepository} from "../../../repositories/user-repository";
import {MeasuringDeviceService} from "../../../services/model/measuring-device.service";
import {CaptionParentComponent} from "irf-caption";
import {IrfNotificationService} from "irf-services";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent extends CaptionParentComponent implements OnInit {
  public data!: any[];
  public className = "TestCucc";
  tableElements = [
    {
      product: 'Car',
      contractNumber: 1234,
      desc: 'This is a contract',
      website: 'www.example.com',
      endingAt: '1/3/2020',
      status: 'negative',
      statusText: 'open',
    },
    {
      product: 'Health',
      contractNumber: 2423,
      desc: 'This is another contract',
      website: 'www.allianz.com',
      endingAt: '4/2/2020',
      status: 'active',
      statusText: 'accepted',
    },
    {
      product: 'Car',
      contractNumber: 353455,
      desc: 'Lorem ipsum dolor sit amet, csis libero. ',
      website: 'www.example.com',
      endingAt: '6/2/2020',
      status: 'positive',
      statusText: 'accepted',
    },
    {
      product: 'Home',
      contractNumber: 22344,
      desc: 'This is a description of a contract',
      website: 'www.example.org',
      endingAt: '1/2/2027',
      status: 'critical',
      statusText: 'rejected',
    },
  ];
  gridOptions: Partial<GridOptions> = {
    pagination: {
      noPagination: false
    }
  };

  public colDef: ColumnDef[] = [
    {field: 'firstName', captionCode: 'general.id', minWidth: 100, width: 180},
  ]


  constructor(private userRepository: UserRepository,
              private noti: IrfNotificationService,
              private deviceserivce:MeasuringDeviceService
  ) {
    super();

    this.test();
  }

  async ngOnInit(): Promise<void> {
    this.gridOptions.columnDefs = this.colDef;
    // this.data = await lastValueFrom(this.userRepository.getUsers());
    // this.data = await lastValueFrom(this.userRepository.getUsersdd());
    // this.data = await lastValueFrom(this.userRepository.getUsersddd());
  }

  private test() {
    // Swal.fire(
    //   "das",
    //   "'dasdas",
    //   'success'
    // ).then(rs =>{
    //   console.log(rs);
    // // });
    // this.noti.showSuccess("general.success", "Kecske");
    // this.noti2.showSuccess("asd", "das");

  }
}
