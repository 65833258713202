import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';

@RepoPath('/mgmt/configurator/contracts')
@Injectable({
  providedIn: 'root'
})
export class ConfiguratorContactRepository extends BaseRepository<any> {


  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }
  @Path('/active/with-online-device')
  getAllActiveWithOnlineDevice(distributorId:number[]) {
    return super.post(distributorId);
  }

  @Path('/{id}/lock/{lockId}')
  lock(id: number, lockId: string) {
    return super.patch();
  }

  @Path('/{id}/unlock/{lockId}')
  unlock(id: number, lockId: string) {
    return super.patch();
  }

  @Path('/{id}/{lockId}/configJson')
  updateConfigJson(id: number, lockId: string, config: any) {
    return super.patch(config);
  }
}

