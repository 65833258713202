import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-toggle-hidden-visibility',
  templateUrl: './toggle-hidden-visibility.component.html',
  styleUrls: ['./toggle-hidden-visibility.component.scss']
})
export class ToggleHiddenVisibilityComponent implements OnInit {
  @Input() data: any;
  @Input() placeholder: string = '**********';s
  showData: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
