import {BaseModel} from "./base.model";

export class NoteModel implements BaseModel {
  id: number
  userKey: string
  message: string
  user?: { firstName: string; lastName: string; email: string; };
  createdAt:string;

  constructor(userKey: string, message?: string) {
    this.userKey = userKey;
    this.message = message ? message : '';
  }
}
