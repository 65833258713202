import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PartnerService} from '../../../services/model/partner.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {AttachmentService} from "../../../services/model/attachment.service";
import {MyStorageService} from "../../../services/my-storage.service";
import {ReportEmailService} from "../../../services/model/report-email.service";
import {ReportEmailType} from "../../../models/report-email.model";

@Component({
  selector: 'app-show-partners',
  templateUrl: './show-partners.component.html',
  styleUrls: ['./show-partners.component.scss']
})
@PageTest({
  path: 'show/:id',
  pathPrefix: ROUTING.Partner.basePlural,
  layout: AdminLayoutComponent
})
export class ShowPartnersComponent extends AbstractShowPage<any, PartnerService> implements OnInit {

  notExistType: string[] = [];

  constructor(service: PartnerService, route: ActivatedRoute, myStorageService: MyStorageService,
              private attachmentService: AttachmentService,
              private reportEmailService: ReportEmailService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }


  protected override initProcess() {
    this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachment);
    this.model.address = JSON.parse(this.model.address);
    const existMailType = this.model.reportEmails.map(re => re.type);
    this.notExistType = Object.keys(ReportEmailType).filter(re => !existMailType.includes(re));
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  removeEmail(email: any) {
    this.reportEmailService.repo.deleteById(email.id).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'partner.successDeleteEmail');
      this.onInit();

    });
  }

  add(emailType: string) {
    this.reportEmailService.repo.save({
      id: 0,
      type: emailType as ReportEmailType,
      partnerId: this.model.id
    }).subscribe(result => {
      this.service.notificationService.showSuccess('general.success', 'partner.successAdd');
      this.onInit();
    });
  }
}
