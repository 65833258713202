import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Contract} from '../../../models/contract.model';
import {ContractService} from '../../../services/model/contract.service';
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {MarkerElement} from "../../../components/map/map.component";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {
  SelectContractEditTypeComponent
} from "../../../components/modal/select-contract-edit-type/select-contract-edit-type.component";
import {Utilities} from "../../../utilities/utilities";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'app-show-contracts',
  templateUrl: './show-contracts.component.html',
  styleUrls: ['./show-contracts.component.scss']
})
@PageTest({
  path: '/show/:id',
  pathPrefix: ROUTING.Contract.basePlural,
  layout: AdminLayoutComponent
})
export class ShowContractsComponent extends AbstractShowPage<Contract, ContractService> implements OnInit, OnDestroy {
  componentDialogRef?: NxModalRef<any>;
  mapModel: MarkerElement;


  constructor(service: ContractService, route: ActivatedRoute,
              myStorageService:MyStorageService,
  public dialogService: NxDialogService,) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }


  protected override initProcess() {
    this.mapModel = this.service.convertModelToMarkerElement(this.model);

  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  override edit(): () => void {
    return () => {

      this.componentDialogRef = this.dialogService.open(SelectContractEditTypeComponent,
        Utilities.getDefaultModalConfig({
          model: this.model
        }));
      this.componentDialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.service.edit(this.model.id,result);
        }
        this.componentDialogRef = undefined;
      });
    };

  }


}
