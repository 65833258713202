class MyFlatTreeNode {
}

export const PARTNER_MENU: MyFlatTreeNode[] = [
  {
    icon: 'repeat',
    label: 'menu.webhook',
    query: 'webhooks',
  },
  {
    icon: 'key',
    label: 'menu.apiKey',
    query: 'api-keys'
  }
]
