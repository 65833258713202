<ng-container *ngIf="model">
  <div nxLayout="grid" class="nx-margin-top-s">
    <div nxRow="">
      <div nxCol="12,12,12,4">
        <div nxRow="">
          <div nxCol="12">
            <app-show-measuring-point-data [withNavigation]="withNavigation"
                                           [model]="model"></app-show-measuring-point-data>
          </div>
          <div nxCol="12">
            <app-note-handler *ngIf="showMap" [notes]="model.notes" [addNoteSupplier]="addNote()"
                              title="{{'measuringDevice.notes' | caption}}"></app-note-handler>
          </div>

        </div>
      </div>
      <div nxCol="12,12,12,8" class="nx-margin-top-s">
        <app-map *ngIf="showMap" height="calc(50vh - 110px)" [models]="[mapModel]" [showYourPosition]="false"
                 [startZoom]="13"
                 [countryCodes]="[countryCode]"></app-map>
      </div>

      <div nxCol="12" class="nx-margin-top-m">
        <nx-notification-panel class="w-100 text-center">
          <div>
          <ng-container *ngFor="let attachment of model.attachments">
            <app-show-image [image]="attachment" height="200"></app-show-image>
          </ng-container>
          </div>
        </nx-notification-panel>

      </div>

    </div>
  </div>
</ng-container>
