import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {formatDate} from '@angular/common';
import * as moment from "moment";
import {Moment} from "moment";
import {DATA_TYPE_FORMATTER_MAP, JCD_ADDON_MENU} from "jcd-addon";
import {AbstractCaptionService, IrfCaptionModule} from "irf-caption";
import {NgPositoryModule} from "ng-pository";


export class Utilities {
  constructor(public captionService:AbstractCaptionService) {
  }
  static handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }


  static getServiceUrl() {
    return environment.serviceUrl;
  }

  static dateToBackendString(date: Date) {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  static onlyTimeToBackendString(date: Date) {
    return formatDate(date, 'HH:mm:ss', 'en-US');
  }

  static dateToBackendDateTimeString(date: Moment, addTdelimiter?: boolean) {
    return date.format('YYYY-MM-DD HH:mm:ss').replace(' ', addTdelimiter ? 'T' : ' ');
  }

  static dateToBackendDateTimeStringByDate(date: Date, addTdelimiter?: boolean) {
    return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'en-US').replace(' ', addTdelimiter ? 'T' : ' ');
  }

  static setStartOfDay(start: string) {
    let date: Moment =  moment.utc(start).utcOffset(0);
    date = date.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    return Utilities.dateToBackendDateTimeString(date, false);
  }

  static setEndOfDay(end: string) {
    const date: Moment =  moment.utc(end).utcOffset(0);
    date.set({hour: 23, minute: 59, second: 59, millisecond: 0})
    return Utilities.dateToBackendDateTimeString(date, false);
  }


  static timeToBackendString(timeString: string) {
    const time = timeString.split('-');
    return time[0].trim() + ':00';
  }

  static addDays(date: Date, days: number) {
    date.setDate(date.getDate() + days);
    return date;
  }

  static minusDays(date: Date, days: number) {
    date.setDate(date.getDate() - days);
    return date;
  }

  static addMinutesToDate(date: Date, minutes: number): Date {
    let timeStamp = date.getTime() + (minutes * 60000);
    return new Date(timeStamp);
  }

  static addHoursToDate(date: Date, hours: number): Date {
    let timeStamp = date.getTime() + (hours * 1000 * 60 * 60);
    return new Date(timeStamp);
  }

  static isProduction() {
    return environment.production;
  }

  static findMin(array: number[]): number {
    return Math.min(...array)
  }

  static findMax(array: number[]): number {
    return Math.max(...array)
  }

  static validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static dateToPath(date: Date) {
    return formatDate(date, 'yyyy_MM_dd_HH_mm_ss', 'en-US')
  }

  static huToEn(str) {

    const ekezet = ["Ö", "ö", "Ü", "ü", "Ó", "ó", "O", "o", "Ú", "ú", "Á", "á", "U", "u", "É", "é", "Í", "í",
      "/", "ő", "Ű", "ű", "Ő", "Ä",
      " ", ",", "(", ")", "{", "}",
      ".", "@", "?", "#", '"', "'"];
    const nekezet = ["O", "o", "U", "u", "O", "o", "O", "o", "U", "u", "A", "a", "U", "u", "E", "e", "I", "i",
      "", "o", "U", "u", "O", "A",
      "-", "-", "", "", "", "",
      "", "", "", "", "", ""];

    const map = {};
    ekezet.forEach((el, index) => {
      map[el] = nekezet[index];
    })
    return str.replace(/[^A-Za-z0-9]/g, (ch) => {
      return map[ch] || ch;
    });
  }

  static getDefaultModalConfig(data: any, id: string = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: false,
      data: data
    }
  }

  static getDefaultCloseIconModalConfig(data: any, id: string = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: true,
      data: data
    }
  }

  static getDefaultCloseIconModalConfigWithoutData(id: string = "modal-ka") {
    return {
      id: id,
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: true,
      showCloseIcon: true,
    }
  }

  static getDatesInRange(startDate: Date, endDate: Date): Date[] {
    const date = new Date(startDate.getTime());

    const dates: Date[] = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  static convertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No, ';
    for (let index in headerList) {
      row += headerList[index] + ', ';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '& #039;&# 039;';
      for (let index in headerList) {
        let head = headerList[index];
        line += '& #039;', '&# 039;' + array[i][head];
      }
      str += line + '& #039;\r\n&# 039';
    }
    return str;
  }

  static downloadFile(data, filename = 'data') {
    let blob = new Blob([JSON.stringify(data)], {
      type: 'application/json;charset=utf-8;'
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf(
        'Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;

    //if Safari open in new window to save file with random filename.
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".json");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  static async getChartTypesForForms() {
    const captionService:AbstractCaptionService = NgPositoryModule.injector.get(AbstractCaptionService);
    let chartTypes:any= [];
    for (let chartType of Object.keys(DATA_TYPE_FORMATTER_MAP)) {
      chartTypes.push({
        label: (await captionService.getCaption('chart.chartType.' + chartType)).nev,
        id: chartType
      });
    }
    return chartTypes;
  }
  static async getAddOnMenuOptionsForForms(){
    const captionService:AbstractCaptionService = NgPositoryModule.injector.get(AbstractCaptionService);
    let menuConfig:any= [];
    for (const menuElement of JCD_ADDON_MENU) {
      console.log(menuElement.label);
      menuConfig.push({
        label: (await captionService.getCaption(menuElement.label)).nev,
        id: menuElement.query
      });
    }
    return menuConfig;
  }

}
