import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {NoteModel} from "../../../models/note.model";
import {AUTH_USER_KEY} from "../../../utilities/constants";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'app-add-note-to-model',
  templateUrl: './add-note-to-model.component.html',
  styleUrls: ['./add-note-to-model.component.scss']
})
export class AddNoteToModelComponent implements OnInit {
  private authUser: any;
  note: NoteModel;

  constructor(@Inject(NX_MODAL_DATA) public model: NoteModel,
              private myStorageService: MyStorageService,) {

  }

  async ngOnInit(): Promise<void> {
    if (!this.model || this.model.message.length < 1) {
      this.authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, {});
      this.note = new NoteModel(this.authUser.id);
    } else {
      this.note = new NoteModel(this.model.userKey, this.model.message);
    }
  }

}
