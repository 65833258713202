<form class="form-example mt-3">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,4">
        <app-input
          captionCode="general.name"
          type="text"
          name="modelName"
          [required]="true"
          [(ngModel)]="model.name"
        ></app-input>
      </div>
      <div nxCol="12,12,12,4">
        <app-input
          captionCode="addon.companyName"
          type="text"
          name="companyName"
          [required]="true"
          [(ngModel)]="model.companyName"
        ></app-input>
      </div>
      <div nxCol="12,12,12,4">
        <app-input
          captionCode="addon.moduleName"
          type="text"
          name="moduleName"
          [required]="true"
          [(ngModel)]="model.moduleName"
        ></app-input>
      </div>
      <div nxCol="12">
        <nx-formfield nxLabel="{{'general.description'|caption}}">
            <textarea name="description" [(ngModel)]="model.description" nxInput cdkTextareaAutosize
                      required></textarea>
        </nx-formfield>
      </div>


    </div>
  </div>
</form>
