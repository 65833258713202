import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {ContractRepository} from '../../repositories/contract-repository';
import {Contract} from "../../models/contract.model";
import {ValidatorService} from "../validator.service";
import {map, of} from "rxjs";
import {ConfiguratorContactRepository} from "../../repositories/configurator-contact-repository";
import {MarkerElement, OFFLINE_ICON, ONLINE_ICON} from "../../components/map/map.component";

import {Utilities} from "../../utilities/utilities";
import {ApexAxisChartSeries} from "ng-apexcharts/lib/model/apex-types";
import * as moment from "moment";
import {Moment} from "moment";
import {GANT_FILTER_ELEMENTS, GANTFilter, GantService} from "../../components/gant-chart/gant-chart.component";
import {IrfNotificationService} from "irf-services";
import {catchError} from "rxjs/operators";
import {ContractDistributorRepository} from "../../repositories/contract-distributor-repository";
import {BaseContractService} from "./base-contract.service";

@Injectable({
  providedIn: 'root'
})
export class ContractDistributorService extends BaseContractService<ContractDistributorRepository> {


  constructor(repo: ContractDistributorRepository, notificationService: IrfNotificationService, router: Router, configuratorRepo: ConfiguratorContactRepository) {
    super(ROUTING.ContractDistributor, repo, notificationService, router, configuratorRepo);
  }

  getAllByDeleted(distributorId: number, deleted: boolean = false) {
    return this.repo.getAllByDeleted(distributorId, deleted).pipe(
      map(contracts => this.addExtendedPropertiesForModelList(contracts))
    )
  }

  getAllByMeasuringDeviceIdOrMeasuringPointId(distributorId: number, pointId: number, deviceId: number) {
    return this.repo.getAllByMeasuringDeviceIdOrMeasuringPointId(distributorId, pointId, deviceId).pipe(
      map(contracts => this.addExtendedPropertiesForModelList(contracts))
    )
  }

  getById(id: number) {
    return this.repo.getById(id).pipe(
      map(contract => this.addExtendedPropertiesForModel(contract))
    )
  }


  lock(id: number, lockId: string) {
    return this.configuratorRepo.lock(id, lockId);
  }

  unlock(id: number, lockId: string) {
    return this.configuratorRepo.unlock(id, lockId);
  }

  adminUnlock(id: number) {
    return this.repo.adminUnlock(id);
  }

  updateConfigJson(id: number, lockId: string, configJSON: string) {
    return this.configuratorRepo.updateConfigJson(id, lockId, {
      id: id, lockId: lockId, rawConfigJson: configJSON
    });
  }

  override addExtendedPropertiesForModel(model: any): any {
    model.name = model.measuringPoint.name + ' - ' + model.measuringDevice.uuId;
    return model;
  }

  override validate(editableRow: Contract, startCanBeInPast = false, extendedAttributeBlackList: string[] = []) {
    const attributeBlackList = ['id', 'measuringDevice', 'measuringPoint', 'partners', 'actualConfig', 'distributor'].concat(extendedAttributeBlackList);
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    ValidatorService.allRequired(editableRow, attributeList, 'Contract');
    ValidatorService.validateStartEndDate(new Date(Utilities.dateToBackendString(new Date(editableRow.start))), new Date(editableRow.end), startCanBeInPast);
  }
}
