import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

@Component({
  selector: 'app-show-attachments',
  templateUrl: './show-attachments.component.html',
  styleUrls: ['./show-attachments.component.scss']
})
export class ShowAttachmentsComponent implements OnInit {
  constructor(@Inject(NX_MODAL_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.data.isImage = this.data.mimeType.includes('image');
    }
  }
}
