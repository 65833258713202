import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";
import {Contract} from "../../../../models/contract.model";
import {
  CONTRACT_EDIT_TYPES
} from "../../../../components/modal/select-contract-edit-type/select-contract-edit-type.component";
import {ContractDistributorService} from "../../../../services/model/contract-distributor.service";
import {Utilities} from "../../../../utilities/utilities";
import {Partner} from "../../../../models/partner.model";
import {PartnerService} from "../../../../services/model/partner.service";
import {DISTRIBUTOR_KEY} from "../../../../utilities/constants";
import {MyStorageService} from "../../../../services/my-storage.service";

@Component({
  selector: 'app-edit-contracts',
  templateUrl: './edit-contracts-for-distributor.component.html',
  styleUrls: ['./edit-contracts-for-distributor.component.scss']
})
@PageTest({
  path: ['edit', 'edit/:id/:editableType'],
  pathPrefix: ROUTING.Contract.basePlural,
  layout: DistributorLayoutComponent
})
export class EditContractsForDistributorComponent implements OnInit {

  private sub: Subscription;
  model: Contract = new Contract();
  editableType: CONTRACT_EDIT_TYPES;
  partners: Partner[];
  distributorForUser: any[];


  constructor(private service: ContractDistributorService,
              private partnerService:PartnerService,
              private myStorageService:MyStorageService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      this.editableType = params['editableType'];
      await this.loadData(id);
      if (this.editableType == 'date') {
      }
    });
  }


  private async loadData(id: number) {

    this.distributorForUser = this.myStorageService.getFromCookies(DISTRIBUTOR_KEY, []);
    if (id) {
      this.model = await lastValueFrom(this.service.getById(id));
      this.initModel();
    }
    if (this.model == null) {
      this.model = new Contract();
    }
    this.partners = await lastValueFrom(this.partnerService.getAllByDeletedAsDistributor());
  }

  private initModel() {
    if (this.model.measuringDevice) {
      this.model.measuringDeviceId = this.model.measuringDevice.id;
    }
    if (this.model.measuringPoint) {
      this.model.measuringPointId = this.model.measuringPoint.id;
    }
    if (this.model.partner) {
      this.model.partnerId = this.model.partner.id;
    }
  }

  save(): () => void {
    return async () => {
      try {
        this.service.validate(this.model, true);
        this.model.start = this.service.setStartOfDay(this.model.start);
        this.model.end = this.service.setEndOfDay(this.model.end)
        if (!this.model.id) {
          await lastValueFrom(this.service.repo.save(this.model));
        } else {
          await this.updateProcess()
        }
        this.service.navigateToBase();
      } catch (e: any) {
        this.service.validationErrorHandler(e);
      }
    };
  }

  cancel(): () => void {
    return () => this.service.navigateToBase();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private async updateProcess(): Promise<void> {
    if (this.editableType == 'date') {
      const dto = {
        id: this.model.id,
        start: Utilities.setStartOfDay(this.model.start),
        end: Utilities.setEndOfDay(this.model.end)
      }
      await lastValueFrom(this.service.repo.updateInterval(this.model.id, dto));
    }
    if (this.editableType == 'measuringPoint') {
      if(this.model.measuringPointId == null){
        this.service.notificationService.showWarning('general.warning', 'contract.notSetDevice');
        return;
      }
      await lastValueFrom(this.service.repo.updatePoint(this.model.id, this.model.measuringPointId));
    }

    if (this.editableType == 'measuringDevice') {
      if(this.model.measuringDeviceId == null){
        this.service.notificationService.showWarning('general.warning', 'contract.notSetDevice');
        return;
      }
      await lastValueFrom(this.service.repo.updateDevice(this.model.id, this.model.measuringDeviceId));
    }

    if (this.editableType == 'partner') {
      const dto = {
        id: this.model.id,
        partnerId: this.model.partnerId,
      }
      await lastValueFrom(this.service.repo.updatePartner(this.model.id, dto));
    }
  }
}
