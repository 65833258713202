import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MeasuringDevice} from '../../../models/measuring-device.model';
import {MeasuringDeviceService} from '../../../services/model/measuring-device.service';
import {Detector} from "../../../models/detector.model";
import {DetectorService} from "../../../services/model/detector.service";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {AddNoteToModelComponent} from "../../../components/modal/add-note-to-model/add-note-to-model.component";
import {
  AddImageModalInput,
  AddImageToModelComponent
} from "../../../components/modal/add-image-to-model/add-image-to-model.component";
import {FormToolBarOption} from "../../../components/form-tool-bar/form-tool-bar-option.model";
import {AttachmentService} from "../../../services/model/attachment.service";
import {Utilities} from "../../../utilities/utilities";
import {PageTest} from "../../../utilities/PageTest";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {ROUTING} from "../../../utilities/routing-constants";
import {OptionItemModel} from "common";

@Component({
  selector: 'app-edit-measuring-devices',
  templateUrl: './edit-measuring-devices.component.html',
  styleUrls: ['./edit-measuring-devices.component.scss']
})
@PageTest({
  path: ['edit', 'edit/:id'],
  pathPrefix: ROUTING.MeasuringDevice.basePlural,
  layout: AdminLayoutComponent
})
export class EditMeasuringDevicesComponent implements OnInit {

  private sub: Subscription;
  model: MeasuringDevice = new MeasuringDevice();
  a: string = 'dsa';
  detectors: Detector[];
  componentDialogRef!: NxModalRef<any>;
  toolbarAddOptions: FormToolBarOption[];
  detectorOptions: OptionItemModel[];

  constructor(private service: MeasuringDeviceService,
              private detectorService: DetectorService,
              public dialogService: NxDialogService,
              private attachmentService: AttachmentService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      if (id) {
        this.model = await lastValueFrom(this.service.getById(id));
        this.model.detectorId = (this.model.detector ? this.model.detector.id : null);
        this.initAttachments();
      }
      if (this.model == null) {
        this.model = new MeasuringDevice();
      }
      this.detectors = await lastValueFrom(this.detectorService.getAllByDeleted(false));
      this.toolbarAddOptions = [
        new FormToolBarOption(this.openAddImageModal(), 'file', 'general.addImageModalButton'),

      ]
      if(!this.model.id){
        this.toolbarAddOptions.push(new FormToolBarOption(this.openAddNoteModal(), 'speech-bubble-o', 'general.addNoteModalButton'))
      }
    });

  }

  private initAttachments() {
    if (this.model.attachments && this.model.attachments.length > 0) {
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachments[0]);
    }
  }

  save(): () => void {
    return async () => {
      try {

        if (!this.model.note?.message || this.model.note.message.length < 1) {
          delete this.model.note;
        }

        this.service.validate(this.model);
        if (!this.model.id) {
          await lastValueFrom(this.service.repo.save(this.model));
        } else {
          await lastValueFrom(this.service.repo.update(this.model.id, this.model));
        }
        this.service.navigateToBase();
      } catch (e: any) {
        this.service.validationErrorHandler(e);
        this.model.healthCheckEndpoint = this.model.healthCheckEndpoint.substring(8);
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  cancel(): () => void {
    return () => {
      this.service.navigateToBase();
    };
  }

  reInitModel(): () => void {
    return () => {
      this.model = new MeasuringDevice();
      this.service.notificationService.showSuccess('general.success', 'measuringDevice.successReInit')
    };
  }

  openAddNoteModal(): () => void {
    return () => {
      if (!this.model.id) {
        this.componentDialogRef = this.dialogService.open(
          AddNoteToModelComponent,
          Utilities.getDefaultModalConfig(this.model.note)
        );
        this.componentDialogRef.afterClosed().subscribe(result => {
          if (result != 'cancel') {
            this.model.note = result;
          }
        });
      } else {
        this.service.notificationService.showInfo('general.info', 'measuringDevice.addNoteInShowView');
      }
    };
  }

  openAddImageModal(): () => void {
    return () => {
      this.componentDialogRef = this.dialogService.open(
        AddImageToModelComponent,
        Utilities.getDefaultModalConfig(new AddImageModalInput(this.model))
      );
      this.componentDialogRef.afterClosed().subscribe(async result => {
        if (result != 'cancel') {
          if (!this.model.id) {
            this.model.attachment = result;
          } else {
            for (let modelAttachment of this.model.attachments) {
              await lastValueFrom(this.service.repo.removeAttachment(this.model.id, modelAttachment.id));
            }

            await lastValueFrom(this.service.repo.addAttachment(this.model.id, result.id));
            this.model.attachments = [result];
            this.initAttachments();
          }
        }
      });
    };
  }

  huToEn() {
    this.model.uuId = Utilities.huToEn(this.model.uuId);
  }
}
