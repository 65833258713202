<form class="nx-margin-top-s">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,6">
        <div nxRow="">

          <div nxCol="12,12,12,12,6">
            <div nxRow="">
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.uuId"
                  type="text"
                  name="uuId"
                  [required]="true"
                  [(ngModel)]="model.uuId"
                  (ngModelChange)="huToEn()"
                ></app-input>
              </div>
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.externalId"
                  type="number"
                  name="externalId"
                  [disabled] =" model.id != undefined"
                  [required]="true"
                  [(ngModel)]="model.externalId"
                  (ngModelChange)="trim('externalId')"
                ></app-input>
              </div>
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.ssid"
                  type="text"
                  name="ssid"
                  [required]="true"
                  [(ngModel)]="model.ssid"
                ></app-input>
              </div>
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.wifiPassword"
                  type="password"
                  name="wifiPassword"
                  [required]="true"
                  [(ngModel)]="model.wifiPassword"
                ></app-input>
              </div>
              <div nxCol="12">
                <app-mask-input
                  captionCode="measuringDevice.macAddress"
                  type="text"
                  name="macAddress"
                  [required]="true"
                  mask="AA:AA:AA:AA:AA:AA"
                  [(ngModel)]="model.macAddress"
                ></app-mask-input>
              </div>
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.localAddress"
                  type="text"
                  name="localAddress"
                  [required]="true"
                  [(ngModel)]="model.localAddress"
                ></app-input>
              </div>
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.globalAddress"
                  type="text"
                  name="globalAddress"
                  [required]="true"
                  [(ngModel)]="model.globalAddress"
                  (keyup)="model.globalAddress = trim('globalAddress')"
                ></app-input>
              </div>
              <div nxCol="12">
                <app-input
                  captionCode="measuringDevice.healthCheckEndpoint"
                  type="text"
                  name="healthCheckEndpoint"
                  [required]="true"
                  [(ngModel)]="model.healthCheckEndpoint"
                  (keyup)="model.healthCheckEndpoint = trim('healthCheckEndpoint')"
                ></app-input>
              </div>
            </div>
          </div>

          <div nxCol="12,12,12,12,6">
            <div nxRow="">
              <div nxCol="12">
                <nx-formfield nxLabel="{{'measuringDevice.detector' | caption}}">
                  <nx-dropdown
                    name="detectorId"
                    [(ngModel)]="model.detectorId"
                  >
                    <ng-container *ngFor="let detector of detectors">
                      <nx-dropdown-item [nxValue]="detector.id"> {{detector.name}}</nx-dropdown-item>
                    </ng-container>
                  </nx-dropdown>
                </nx-formfield>
              </div>
              <div nxCol="12">
                <nx-formfield nxLabel="{{'measuringDevice.apiKey'|caption}}">
            <textarea name="apiKey" [(ngModel)]="model.apiKey" nxInput cdkTextareaAutosize rows="6"
                      (keyup)="model.apiKey = trim('apiKey')"
                      required></textarea>
                </nx-formfield>
              </div>
              <div nxCol="12">
                <nx-formfield nxLabel="{{'measuringDevice.distributor' | caption}}">
                  <nx-dropdown
                    name="distributorId"
                    [(ngModel)]="model.distributorId"
                  >
                    <ng-container *ngFor="let distributor of distributors">
                      <nx-dropdown-item [nxValue]="distributor.id"> {{distributor.name}}</nx-dropdown-item>
                    </ng-container>
                  </nx-dropdown>
                </nx-formfield>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div nxCol="12,12,12,6">
        <div nxRow="">
          <div nxCol="12,12,12,12,6">
            <div nxRow="">
              <div nxCol="12" class="nx-margin-bottom-s">
                <nx-switcher labelSize="small" name="virtual"
                             [(ngModel)]="model.virtual">{{'measuringDevice.virtual' | caption}}</nx-switcher>
              </div>
              <div nxCol="12" class="nx-margin-bottom-s">
                <nx-switcher labelSize="small" name="online"
                             [(ngModel)]="model.online">{{'measuringDevice.online' | caption}}</nx-switcher>

              </div>
              <div nxCol="12">
                <p>{{'measuringDevice.color' | caption}}</p>
                <color-chrome [color]="model.color" (onChangeComplete)="changeColor($event)"></color-chrome>
              </div>
            </div>
          </div>
          <div nxCol="12,12,12,12,6">
            <div nxRow="">
              <div nxCol="12">
                <p>{{'measuringDevice.attachment' | caption}}</p>
                <ng-container *ngIf="model.attachment">
                  <figure nxFigure="">
                    <img
                      [src]="model.attachment.srcWidth"
                      [alt]="model.uuId"
                      (error)="model.attachment.srcWidth = 'assets/img/default.png'"
                    />
                  </figure>
                </ng-container>
              </div>
              <ng-container *ngIf="model.note">
                <div nxCol="12">
                  <p>{{'measuringDevice.note' | caption}}</p>
                  <p class="nx-margin-left-s">"{{model.note.message}}"</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
