<ng-container *ngIf="model">
  <nx-card class="viewer">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.uuId"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>
    <div nxRow="" class="nx-margin-s">
      <div nxCol="12,12,12, 6">
        <h6 class="nx-margin-bottom-s">
          {{'measuringDevice.baseInfo' | caption}}
        </h6>
        <inside-show-measuring-device-data [model]="model"></inside-show-measuring-device-data>
      </div>
      <div nxCol="12,12,12, 6">
        <h6 class="nx-margin-bottom-s">
          {{'measuringDevice.detectorInfo' | caption}}
        </h6>
        <ng-container *ngIf="model.detector">
          <inside-show-detector [model]="model.detector"></inside-show-detector>
        </ng-container>
        <ng-container *ngIf="!model.detector">
          <nx-message nxContext="warning">
            <span class="nx-font-weight-bold">{{'general.warning' | caption}}</span><br/>
            {{'measuringDevice.noInformationFromDetector' | caption}}
          </nx-message>
        </ng-container>
      </div>
      <div nxCol="12,12,12, 6" class="nx-margin-y-2m">
        <app-note-handler [notes]="model.notes" [addNoteSupplier]="addNote()"
                          title="{{'measuringDevice.notes' | caption}}"></app-note-handler>
      </div>
      <div nxCol="12,12,12, 6">
        <ng-container *ngFor="let attachment of model.attachments">
          <app-show-image [image]="attachment"></app-show-image>
        </ng-container>
      </div>
    </div>
  </nx-card>

</ng-container>
