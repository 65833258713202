import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MeasuringPointsComponent} from './measuring-points/measuring-points.component';
import {RouterModule} from '@angular/router';
import {EditMeasuringPointsComponent} from './edit-measuring-points/edit-measuring-points.component';
import {ShowMeasuringPointsComponent} from './show-measuring-points/show-measuring-points.component';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {ComponentsModule} from '../../components/components.module';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    MeasuringPointsComponent,
    EditMeasuringPointsComponent,
    ShowMeasuringPointsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NxCardModule,
    NxIconModule,
    ComponentsModule,
    NxHeadlineModule,
    NxGridModule,
    NxButtonModule,
    NxFormfieldModule,
    NxInputModule,
    FormsModule,
  ]
})
export class MeasuringPointModule {
}
