import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfigurationStartComponent} from './configuration-start/configuration-start.component';
import {RouterModule} from "@angular/router";
import {ComponentsModule} from "../../../components/components.module";
import {PipeModule} from "../../../pipe/pipe.module";
import {NxCardModule} from "@aposin/ng-aquila/card";
import {NxGridModule} from "@aposin/ng-aquila/grid";
import {TranslateModule} from "@ngx-translate/core";
import {NxTableModule} from "@aposin/ng-aquila/table";
import {NxLinkModule} from "@aposin/ng-aquila/link";
import {NxBadgeModule} from "@aposin/ng-aquila/badge";
import {NxProgressbarModule} from "@aposin/ng-aquila/progressbar";
import {NxTooltipModule} from "@aposin/ng-aquila/tooltip";
import {NxHeadlineModule} from "@aposin/ng-aquila/headline";
import {NxTabsModule} from "@aposin/ng-aquila/tabs";
import {NxFormfieldModule} from "@aposin/ng-aquila/formfield";
import {NxDropdownModule} from "@aposin/ng-aquila/dropdown";
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    ConfigurationStartComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    PipeModule,
    NxCardModule,
    NxGridModule,
    TranslateModule,
    NxTableModule,
    NxLinkModule,
    NxBadgeModule,
    NxProgressbarModule,
    NxTooltipModule,
    NxHeadlineModule,
    NxTabsModule,
    NxFormfieldModule,
    NxDropdownModule,
    FormsModule,
  ]
})
export class ConfigurationModule {
}
