import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ComponentsModule} from '../../components/components.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../directives/directives.module';
import {CommonModule} from '@angular/common';
import {AttachmentsComponent} from './attachments/attachments.component';
import {NxCardModule} from "@aposin/ng-aquila/card";
import {NxGridModule} from "@aposin/ng-aquila/grid";
import {NxHeadlineModule} from "@aposin/ng-aquila/headline";
import {NxIconModule} from "@aposin/ng-aquila/icon";
import {NxButtonModule} from "@aposin/ng-aquila/button";
import {EditAttachmentsComponent} from './edit-attachments/edit-attachments.component';
import {ShowAttachmentsComponent} from './show-attachments/show-attachments.component';
import {NxImageModule} from "@aposin/ng-aquila/image";
import {NxModalModule} from "@aposin/ng-aquila/modal";


@NgModule({
  declarations: [
    AttachmentsComponent,
    EditAttachmentsComponent,
    ShowAttachmentsComponent,
  ],
  exports: [],
  imports: [
    FormsModule,
    ComponentsModule,
    DirectivesModule,
    RouterModule,
    TranslateModule,
    CommonModule,
    NxCardModule,
    NxGridModule,
    NxHeadlineModule,
    NxIconModule,
    NxButtonModule,
    NxImageModule,
    NxModalModule
  ]
})
export class AttachmentModule {
}
