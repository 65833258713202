import {Component, Input, OnInit} from '@angular/core';
import {Webhook} from "../../../models/webhook.model";
import {PartnerService} from "../../../services/model/partner.service";
import {Partner} from "../../../models/partner.model";
import {CaptionParentComponent} from "irf-caption";

@Component({
  selector: 'form-webhook-form',
  templateUrl: './webhook-form.component.html',
  styleUrls: ['./webhook-form.component.scss']
})
export class WebhookFormComponent extends CaptionParentComponent implements OnInit {
  @Input() model: Webhook

  partners: Partner[] = [];

  constructor(protected partnerService: PartnerService) {
    super()
  }

  ngOnInit(): void {
    this.partners = this.partnerService.$partnerOfUser.getValue();
    if (this.partners.length > 0) {
      this.model.partner = this.partners[0];
      this.model.partnerId = this.partners[0].id;
    }
  }

  updatePartnerId() {
    this.model.partnerId = this.model.partner.id;
  }
}
