import {Injectable} from '@angular/core';
import {StompSockService, WsCommand} from "@oril/ng-stomp-sock";
import {filter, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private readonly connect:Observable<boolean>
  constructor(private stompSockService: StompSockService,) {
    this.connect = this.stompSockService.connected$.pipe(filter(connected => connected));
  }

  public connectToEndpoint(endpoint:string, supplier:(response:BroadcastPayload)=>void) {
    this.connect.subscribe(() =>{
      this.stompSockService.getWebSocket(endpoint).on<any>(WsCommand.MESSAGE)
        .subscribe((response: BroadcastPayload) => {
          supplier(response);
        });
    })
  }
  public disconnectFromEndpoint(endpoint:string){
    this.stompSockService.unsubscribe(endpoint);
  }
}
export interface BroadcastPayload {
  type: string;
  subscribePath: string;
  subscriber: string;
  attribute: any;
}
export const WS_ENDPOINTS = {
  LOCK:'/output/device-lock',
  UNLOCK:'/output/device-unlock'
}
