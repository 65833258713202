import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakBaseAuthGuard} from "../../utilities/keycloak-base-auth-guard";
import {MyStorageService} from "../../services/my-storage.service";
import {AbstractCaptionService} from "irf-caption";

@Injectable()
export class ConfiguratorAuthGuard extends KeycloakBaseAuthGuard {

  constructor(protected override router: Router,
              protected override keycloakAngular: KeycloakService,
              protected override translateService: AbstractCaptionService,
              protected override myStorageService: MyStorageService) {
    super(router, keycloakAngular, translateService, myStorageService, "configurator");
  }
}
