import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakBaseAuthGuard} from "../../utilities/keycloak-base-auth-guard";
import {MyStorageService} from "../../services/my-storage.service";
import {AbstractCaptionService} from "irf-caption";
import {AddonRepository} from "../../repositories/addon-repository";
import {lastValueFrom} from "rxjs";

@Injectable()
export class PartnerAuthGuard extends KeycloakBaseAuthGuard {

  constructor(protected override router: Router,
              protected override keycloakAngular: KeycloakService,
              protected override translateService: AbstractCaptionService,
              protected override myStorageService: MyStorageService,
              private addonRepo: AddonRepository) {
    super(router, keycloakAngular, translateService, myStorageService, "Partner");
  }

  override isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      let authUser = await this.keycloakAngular.getKeycloakInstance().loadUserProfile();
      const b: any = await this.keycloakAngular.getKeycloakInstance().loadUserInfo();
      authUser.id = b.sub;
      const userInRole = this.keycloakAngular.isUserInRole(this.role);
      if (authUser.id) {
        const addons = await lastValueFrom(this.addonRepo.getAllForUserAsPartner(authUser.id));
        if (addons.length < 1) {
          await this.resolveWithWarning(userInRole, resolve, '/');
        }
      }
      if (userInRole) {
        await this.checkPermission(state, resolve);
      } else {
        await this.resolveWithWarning(userInRole, resolve, '/');
      }
    });
  }
}
