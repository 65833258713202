import {Component, OnInit} from '@angular/core';
import {PageLayout} from "../../utilities/PageTest";
import {DistributorAuthGuardService} from "./distributor-auth-guard.service";
import {DISTRIBUTOR_MENU} from "./distributor-menu.const";
import {AUTH_USER_KEY, DISTRIBUTOR_KEY} from "../../utilities/constants";
import {lastValueFrom} from "rxjs";
import {MyStorageService} from "../../services/my-storage.service";
import {DistributorService} from "../../services/model/distributor.service";
import {StartLayoutComponent} from "../start-layout/start-layout.component";

@Component({
  selector: 'app-configurator-layout',
  templateUrl: './distributor-layout.component.html',
  styleUrls: ['./distributor-layout.component.css']
})
@PageLayout({
  path: 'distributor',
  authGuard: DistributorAuthGuardService,
  layout:StartLayoutComponent,
  data: {title: 'general.title.distributor'}
})
export class DistributorLayoutComponent implements OnInit {

  actions = DISTRIBUTOR_MENU;

  constructor(private myStorageService:MyStorageService, private distributorService:DistributorService) {
  }

  async ngOnInit(): Promise<void> {
    const authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, undefined);
    if (authUser) {
      const distributorsForUser = await lastValueFrom(this.distributorService.getDistributorForUser(authUser.id));
      this.myStorageService.saveToCookies(DISTRIBUTOR_KEY, distributorsForUser)
    }
  }

}


