import {NgModule} from '@angular/core';
import {AddonModule} from "./addon/addon.module";
import {AttachmentModule} from "./attachment/attachment.module";
import {ConfigurationModule} from "./config/configuration/configuration.module";
import {DetectorModule} from "./detector/detector.module";
import {MeasuringPointModule} from "./measuring-point/measuring-point.module";
import {MeasuringDeviceModule} from "./measuring-device/measuring-device.module";
import {PartnerModule} from "./partner/partner.module";
import {UserModule} from "./user/user.module";
import {WelcomeModule} from "./welcome/welcome.module";
import {ContractModule} from "./contract/contract.module";
import {DistributorModule} from "./distributor/distributor.module";
import {DistributorPageModule} from "./distributorPage/distributor-page.module";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import {PdfViewerModule} from "ng2-pdf-viewer";
import {BrowserModule} from "@angular/platform-browser";
import {ComponentsModule} from "../components/components.module";
import {NxCardModule} from "@aposin/ng-aquila/card";
import {NgAquilaDatatableModule} from "ng-aquila-datatable";
import { WebhookComponent } from './webhook/webhook.component';
import {NxButtonModule} from "@aposin/ng-aquila/button";
import {NxContextMenuModule} from "@aposin/ng-aquila/context-menu";
import {NxIconModule} from "@aposin/ng-aquila/icon";
import {NxToolbarModule} from "@aposin/ng-aquila/toolbar";
import {NxHeadlineModule} from "@aposin/ng-aquila/headline";
import {PipeModule} from "../pipe/pipe.module";
import {NxDataDisplayModule} from "@aposin/ng-aquila/data-display";
import { PartnerApiKeyComponent } from './partner-api-key/partner-api-key.component';

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    WebhookComponent,
    PartnerApiKeyComponent
  ],
  exports: [],
  imports: [
    AddonModule,
    AttachmentModule,
    ConfigurationModule,
    ContractModule,
    DetectorModule,
    MeasuringPointModule,
    MeasuringDeviceModule,
    PartnerModule,
    UserModule,
    WelcomeModule,
    DistributorModule,
    DistributorPageModule,
    PdfViewerModule,
    BrowserModule,
    ComponentsModule,
    NgAquilaDatatableModule,
    NxCardModule,
    NxButtonModule,
    NxContextMenuModule,
    NxIconModule,
    NxToolbarModule,
    NxHeadlineModule,
    PipeModule,
    NxDataDisplayModule
  ],
})
export class PageModule {
}
