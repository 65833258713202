import {Component, Input, OnInit} from '@angular/core';
import {MeasuringDevice} from "../../../models/measuring-device.model";
import {MeasuringPointService} from "../../../services/model/measuring-point.service";
import {AttachmentService} from "../../../services/model/attachment.service";
import {MeasuringPoint} from "../../../models/measuring-point.model";

@Component({
  selector: 'app-show-measuring-point-data',
  templateUrl: './show-measuring-point-data.component.html',
  styleUrls: ['./show-measuring-point-data.component.scss']
})
export class ShowMeasuringPointDataComponent implements OnInit {
  @Input() model: any | MeasuringPoint;
  @Input() withNavigation: boolean = true;
  @Input() withImage: boolean = false;

  constructor(private service: MeasuringPointService, private attachmentService: AttachmentService) {
  }

  ngOnInit(): void {
    this.model.attachments = this.initAttachments();
  }

  goPartner() {
    this.service.show(this.model.id);
  }

  private initAttachments(): any[] {
    const extraAttachments: any[] = [];
    if (this.model.attachments && this.model.attachments.length > 0) {
      let attachment = this.model.attachments[0].attachment ?? this.model.attachments[0];
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(attachment);

      for (let i = 0; i < this.model.attachments.length; i++) {
        attachment = this.model.attachments[i].attachment ?? this.model.attachments[i];
        extraAttachments.push(
          this.attachmentService.addExtendedPropertiesForModel(attachment)
        );
      }
    } else {
      this.model.attachment = {
        src: 'assets/img/default.png',
        srcWidth: 'assets/img/default.png'
      }
    }
    return extraAttachments;
  }
}
