import {Component, OnInit} from '@angular/core';
import {ADMIN_MENU} from "./admin-menu.const";
import {PageLayout} from "../../utilities/PageTest";
import {AdminAuthGuard} from "./admin-auth-guard.service";
import {StartLayoutComponent} from "../start-layout/start-layout.component";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],

})
@PageLayout({
  path: 'admin',
  default: true,
  authGuard: AdminAuthGuard,
  layout:StartLayoutComponent,
  data: {title: 'general.title.admin'}
})
export class AdminLayoutComponent implements OnInit {
  actions = ADMIN_MENU;
  constructor() {

  }
  ngOnInit(): void {
  }


}
