<form class="form-example nx-margin-top-s">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="12,12,12,4">
        <div nxRow="">
          <div nxCol="12">
            <nx-formfield nxLabel="{{'general.name'|caption}}">
              <input name="name" type="text" nxInput [(ngModel)]="model.name" required/>
            </nx-formfield>
          </div>
          <div nxCol="12">
            <nx-formfield nxLabel="{{'general.description'|caption}}">
            <textarea name="description" [(ngModel)]="model.description" nxInput cdkTextareaAutosize
                      required></textarea>
            </nx-formfield>
          </div>
          <div nxCol="12">
            <nx-formfield  nxLabel="{{'detector.versionNumber'|caption}}">

              <input name="versionNumber" type="number" nxInput [(ngModel)]="model.versionNumber" required/>
            </nx-formfield>
          </div>
          <div nxCol="12">
            <nx-formfield nxLabel="{{'detector.algorithm' | caption}}">
              <nx-dropdown
                name="measuringDeviceId"
                [(ngModel)]="model.algorithmId"
              >
                <ng-container *ngFor="let device of attachments">
                  <nx-dropdown-item [nxValue]="device.id"> {{device.title}}({{device.fileName}})</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </div>
          <div nxCol="12">
            <nx-switcher labelSize="small"  class="nx-margin-m" name="remoteInstall"
                         [(ngModel)]="model.remoteInstall">{{'detector.remoteInstall'|caption}}</nx-switcher>
          </div>
        </div>
      </div>
      <div nxCol="12,12,12,8">
        <div nxRow="">
          <div nxCol="12">
            <nx-formfield nxLabel="{{'detector.configJSON'|caption}}">
                <textarea style="min-height: 300px" name="config" [(ngModel)]="model.config" nxInput cdkTextareaAutosize
                          required cols="4"></textarea>
            </nx-formfield>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
