import {BaseModel} from "./base.model";
import {Partner} from "./partner.model";

export class ReportEmailModel implements BaseModel {
  id: number;
  type: ReportEmailType;
  lastUpdate?: Date;
  partnerId?: number;
}

export enum ReportEmailType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  TWO_WEEKLY = 'TWO_WEEKLY',
  MONTHLY = 'MONTHLY'

}
