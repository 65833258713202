<ng-container *ngIf="partners">
  <form class="form-example mt-3">
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12">
          <ng-container *ngIf="editableType != 'partner'">
            <nx-formfield nxLabel="{{'contract.point' | caption}}">
              <input type="text" nxInput disabled
                     [value]="model.partner?.name"/>
            </nx-formfield>
          </ng-container>

          <ng-container *ngIf="editableType == 'partner'">
            <nx-formfield nxLabel="{{'contract.partner' | caption}}">
              <nx-dropdown
                name="partnerId"
                [(ngModel)]="model.partnerId"
                (ngModelChange)="updatePartner($event)"
              >
                <ng-container *ngFor="let partner of partners">
                  <nx-dropdown-item [nxValue]="partner.id"> {{partner.name}}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </ng-container>
        </div>
        <div nxCol="12,12,12,6">
          <ng-container *ngIf="editableType != 'measuringPoint'">
            <nx-formfield nxLabel="{{'contract.point' | caption}}">
              <input type="text" nxInput disabled
                     [value]="model.measuringPoint?.name"/>
            </nx-formfield>
          </ng-container>
          <ng-container *ngIf="editableType == 'measuringPoint'">
            <nx-formfield nxLabel="{{'contract.measuringPoint' | caption}}">
              <nx-dropdown
                name="measuringPointId"
                [(ngModel)]="model.measuringPointId"
                (ngModelChange)="updatePoint($event)"
              >
                <ng-container *ngFor="let point of measuringPoints">
                  <nx-dropdown-item [nxValue]="point.id"> {{point.name}}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </ng-container>
        </div>

        <div nxCol="12,12,12,6">
          <ng-container *ngIf="editableType != 'measuringDevice'">
            <nx-formfield nxLabel="{{'contract.device' | caption}}">
              <input type="text" nxInput disabled
                     [value]="model.measuringDevice?.uuId"/>
            </nx-formfield>
          </ng-container>
          <ng-container *ngIf="editableType == 'measuringDevice'">
            <nx-formfield nxLabel="{{'contract.device' | caption}}">
              <nx-dropdown
                name="measuringDeviceId"
                [(ngModel)]="model.measuringDeviceId"
                (ngModelChange)="updateDevice($event)"
              >
                <ng-container *ngFor="let device of measuringDevices">
                  <nx-dropdown-item [nxValue]="device.id"> {{device.uuId}}</nx-dropdown-item>
                </ng-container>
              </nx-dropdown>
            </nx-formfield>
          </ng-container>
        </div>
        <ng-container *ngIf="editableType != 'date'">

          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'contract.start' | caption}}">
              <input type="text" nxInput disabled
                     [value]="model.start"/>
            </nx-formfield>
          </div>

          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'contract.end' | caption}}">
              <input type="text" nxInput disabled
                     [value]="model.end"/>
            </nx-formfield>
          </div>
        </ng-container>
        <ng-container *ngIf="editableType == 'config'">
          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'addon.chartType' | caption}} *">
              <nx-multi-select [(ngModel)]="model.chartTypes" name="chartTypes"
                               [options]="chartTypes" selectValue="id" selectLabel="label"
              ></nx-multi-select>
            </nx-formfield>
          </div>
        </ng-container>
        <ng-container *ngIf="editableType == 'date'">
          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'contract.start' | caption}}">
              <input name="start" type="local-datetime" nxInput nxDatefield [nxDatepicker]="startDatePicker"
                     [nxMin]="firstStartDenyDate"
                     [nxMax]="modelEnd"
                     [(ngModel)]="model.start" required/>
              <nx-datepicker-toggle
                [for]="startDatePicker"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #startDatePicker></nx-datepicker>
            </nx-formfield>

          </div>
          <div nxCol="12,12,12,6">
            <nx-formfield nxLabel="{{'contract.end' | caption}}">
              <input name="end" type="datetime" nxInput nxDatefield [nxDatepicker]="endDatePicker"
                     [nxMin]="modelStart"
                     [nxMax]="firstEndDenyDate"
                     [(ngModel)]="model.end" required/>
              <nx-datepicker-toggle
                [for]="endDatePicker"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #endDatePicker></nx-datepicker>
            </nx-formfield>
          </div>

        </ng-container>

      </div>
    </div>
  </form>
</ng-container>
