import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MeasuringDevicesComponent} from './measuring-devices/measuring-devices.component';
import {RouterModule} from '@angular/router';
import {EditMeasuringDevicesComponent} from './edit-measuring-devices/edit-measuring-devices.component';
import {ShowMeasuringDevicesComponent} from './show-measuring-devices/show-measuring-devices.component';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {ComponentsModule} from '../../components/components.module';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {FormsModule} from '@angular/forms';
import {NxSwitcherModule} from "@aposin/ng-aquila/switcher";
import {NxDropdownModule} from "@aposin/ng-aquila/dropdown";
import {TextFieldModule} from "@angular/cdk/text-field";
import {NxToolbarModule} from "@aposin/ng-aquila/toolbar";
import {NxImageModule} from "@aposin/ng-aquila/image";
import {NxMessageModule} from "@aposin/ng-aquila/message";
import {IrfComponentsModule} from "common";
import {NxDatefieldModule} from "@aposin/ng-aquila/datefield";
import {NxModalModule} from "@aposin/ng-aquila/modal";


@NgModule({
  declarations: [
    MeasuringDevicesComponent,
    EditMeasuringDevicesComponent,
    ShowMeasuringDevicesComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NxCardModule,
        NxIconModule,
        ComponentsModule,
        NxHeadlineModule,
        NxGridModule,
        NxButtonModule,
        NxFormfieldModule,
        NxInputModule,
        FormsModule,
        NxSwitcherModule,
        NxDropdownModule,
        TextFieldModule,
        NxToolbarModule,
        NxImageModule,
        NxMessageModule,
        IrfComponentsModule,
        NxDatefieldModule,
        NxModalModule,
    ]
})
export class MeasuringDeviceModule {
}
