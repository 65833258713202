import {Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {ValidatorService} from '../services/validator.service';
import {Addon} from '../models/addon.model';
import {FilterPayloadInterface} from "../services/filterer/filter-payload-interface";
import {Webhook} from "../models/webhook.model";
import {ReportEmailModel, ReportEmailType} from "../models/report-email.model";

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class ReportEmailRepository extends BaseRepository<PostPayloadInterface> {
  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/partner/report-emails/filter')
  getAllByFilter(filter: FilterPayloadInterface) {
    const options = {params: new HttpParams().append('filter', JSON.stringify(filter))};
    return super.get(undefined, options);
  }

  @Path('/partner/report-emails/{id}')
  getById(id: string) {
    return super.get();
  }

  @Path('/partner/report-emails')
  save(email: ReportEmailModel) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: email
    });
  }

  @Path('/partner/report-emails/{id}')
  update(id: number, email: ReportEmailModel) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: email
    });
  }

  @Path('/partner/report-emails/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  validate(editableRow: Addon) {
    ValidatorService.allRequired(editableRow, Object.keys(editableRow), 'Addon');
  }

}

