import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {Webhook} from "../../../models/webhook.model";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {PartnerLayoutComponent} from "../../../layouts/partner-layout/partner-layout.component";

@Component({
  selector: 'app-add-new-webhook',
  templateUrl: './add-new-webhook.component.html',
  styleUrls: ['./add-new-webhook.component.scss']
})
export class AddNewWebhookComponent implements OnInit {
  webhook: Webhook;

  constructor(@Inject(NX_MODAL_DATA) public model: Webhook,) {
  }

  ngOnInit(): void {
    if (this.model) {
      this.webhook = {...this.model};
    } else {
      this.webhook = new Webhook();
    }
  }
}
