import {BaseModel} from "./base.model";
import {Partner} from "./partner.model";

export class Webhook implements BaseModel {
  id: number;
  type: WebhookType;
  securityKey: string;
  url: string;
  signatureUrl: string;
  partner: Partner;
  partnerId: number;

}

export enum WebhookType {
  STATISTIC = 'STATISTIC',
  CIRCUS = 'CIRCUS',
}
