import {
  AfterContentChecked, AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {lastValueFrom, Subject, takeUntil} from "rxjs";
import {NxSidebarComponent} from "@aposin/ng-aquila/sidebar";
import {
  NxFlatTreeControl,
  NxFlatTreeNode,
  NxTreeComponent,
  NxTreeFlatDataSource,
  NxTreeNode
} from "@aposin/ng-aquila/tree";
import {AUTH_USER_KEY} from "../../utilities/constants";
import {KeycloakProfile} from "keycloak-js";
import {KeycloakService} from "keycloak-angular";
import {MyStorageService} from "../../services/my-storage.service";
import {AddonRepository} from "../../repositories/addon-repository";
import {ADDON_MENUS, ROLES_MENU_MAP} from "../../app.component";
import {Router} from "@angular/router";
import {PartnerRepository} from "../../repositories/partner-repository";

export interface MyTreeNode extends NxTreeNode {
  label: string;
  icon?: string;
  query?: any;
  role: string;
  expandable?: boolean;
  level?: number
  children?: MyTreeNode[];
}

interface MyFlatTreeNode extends NxFlatTreeNode {
  label: string;
  icon?: string;
  query?: any;
  prefix?: string;
}

@Component({
  selector: 'page-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnDestroy, OnInit, AfterViewInit {

  MOBILE_BREAK = 430;
  actions: MyTreeNode[] = [];
  prefix: string = '';
  applyTopScrollShadow: boolean = false;
  applyBottomScrollShadow: boolean = false;

  @ViewChild('treeComponent') treeComponent: NxTreeComponent<any>;
  @ViewChild('menus') menus!: ElementRef;

  dataSource: NxTreeFlatDataSource<MyTreeNode, MyFlatTreeNode>;
  treeControl: NxFlatTreeControl<MyFlatTreeNode>;

  private readonly _destroyed = new Subject<void>();

  @ViewChild('sidebar') sidebar!: NxSidebarComponent;
  @ViewChild('sidebar') sideBarElement!: ElementRef;
  mobile: boolean = false;
  tablet: boolean = false;


  public authUser!: KeycloakProfile;
  public roleCards!: any[];

  constructor(
    private addonRepository: AddonRepository,
    private myStorageService: MyStorageService,
    private keycloakService: KeycloakService,
    public viewportService: NxViewportService,
    public router: Router,
    private _cdr: ChangeDetectorRef,
    private partnerRepository: PartnerRepository
    ) {
  }

  ngOnInit(): void {
    this.authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, undefined);
    this.initPartnerMenu();
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  hasChild = (_: number, node: NxFlatTreeNode) => node.expandable;

  async logout() {
    this.myStorageService.deleteFromCookies(AUTH_USER_KEY);
    await this.keycloakService.logout();
  }

  private initActions() {
    ROLES_MENU_MAP.filter(rm => this.keycloakService.isUserInRole(rm.role)).forEach(rm => {
      const menuItems = rm.card;
      console.log(rm.card);
      if (!Array.isArray(menuItems)) {
        this.addAction(menuItems)
      } else {
        console.log(menuItems);
        menuItems.forEach(menuItem => this.addAction(menuItems));
      }
    });
    this.treeControl = new NxFlatTreeControl();
    this.dataSource = new NxTreeFlatDataSource(
      this.treeControl,
      this.actions,
    );
    this.initView();
    setTimeout(() => {
      const focusableMainMenu = this.treeComponent._getChildrenList().find(element => {
        return this.router.url.includes(element.role);
      })
      if (focusableMainMenu != undefined) {
        this.treeComponent.updateFocusedData(focusableMainMenu);
      }
      this.treeComponent.focus();
      this.treeComponent.expandCurrentFocusedNode();
      this._cdr.detectChanges();
      this.onScroll();
    }, 200);
  }
  initPartnerMenu() {
    const actions: any[] = [];
    if (this.authUser && this.authUser.id && this.keycloakService.isUserInRole('Partner')) {
      const partnerCard = ROLES_MENU_MAP.find(rm => rm.role == 'Partner' && rm.mode == 'addon');
      let userKey=this.authUser.id;
      this.addonRepository.getAllForUserAsPartner(this.authUser.id).subscribe(async result => {
        let partners = await lastValueFrom(this.partnerRepository.getPartnersForUserPartner(userKey));
        let unionArray = [...new Set(partners.reduce((acc, partnerConfig) => [...acc, ...partnerConfig.menuConfig], []))];

        result.forEach(addon => {
          if (Object.keys(ADDON_MENUS).includes(addon.moduleName)) {
            let modifiedAddon= {...ADDON_MENUS[addon.moduleName], children: ADDON_MENUS[addon.moduleName].children.filter(child => unionArray.includes(child.query))};
            actions.push(modifiedAddon);
          }
        });
        partnerCard.card = actions
        this.initActions();
      });
    } else {
      this.initActions();
    }
  }

  private addAction(menuItems: any) {
    Object.values(menuItems).forEach((menuItem: any) => {
      this.actions.push(menuItem);
    })
  }

  initView() {

    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanMedium => {
        console.log(isGreaterThanMedium);
        this.mobile = !isGreaterThanMedium;
        if (!isGreaterThanMedium && this.sidebar.open) {
          this.sidebar.close();
        } else {
          this.sidebar.expand(280);
        }
      });


    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanSmall => {
      });

  }

  onScroll() {
    this.applyTopScrollShadow = this.menus.nativeElement.scrollTop > 5;
    this.applyBottomScrollShadow = this.menus.nativeElement.scrollHeight - this.menus.nativeElement.scrollTop - 5 > this.menus.nativeElement.offsetHeight;
  }

  toggleSidebar() {
    if (this.sidebar.open) {
      this.sidebar.close();
    } else {
      this.sidebar.expand(280);
    }
  }
}
