import {Contract} from "../../models/contract.model";
import {MarkerElement, OFFLINE_ICON, ONLINE_ICON} from "../../components/map/map.component";
import {ApexAxisChartSeries} from "ng-apexcharts/lib/model/apex-types";
import * as moment from "moment/moment";
import {Moment} from "moment/moment";
import {Utilities} from "../../utilities/utilities";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import {GANTFilter, GantService} from "../../components/gant-chart/gant-chart.component";
import {BaseModelService} from "./base-model.service";
import {IrfNotificationService} from "irf-services";
import {Router} from "@angular/router";
import {ConfiguratorContactRepository} from "../../repositories/configurator-contact-repository";
import {ModelPath} from "../../utilities/routing-constants";
import {BaseRepository} from "ng-pository";

export abstract class BaseContractService<REPO extends BaseRepository<any>> extends BaseModelService<REPO> implements GantService<Contract[], ContractGANTFilter> {
  protected constructor(modelPath: ModelPath, repo: REPO, notificationService: IrfNotificationService, router: Router,
                        protected configuratorRepo: ConfiguratorContactRepository) {
    super(modelPath, repo, notificationService, router);
  }

  convertModelListTOMarkerElementList(contracts: Contract[]): MarkerElement[] {
    const tmp: MarkerElement[] = [];
    for (const contract of contracts) {
      tmp.push(this.convertModelToMarkerElement(contract));
    }
    return tmp;
  }

  convertModelToMarkerElement(contract: Contract): MarkerElement {
    if (contract.measuringPoint) {
      return new MarkerElement(
        contract.measuringPoint.latitude,
        contract.measuringPoint.longitude,
        contract.id,
        contract.measuringPoint.name,
        contract.measuringPoint.description,
        contract.onlineStatus != "offline" ? ONLINE_ICON : OFFLINE_ICON //TODO sárga icon
      );
    }
    return new MarkerElement(
      0,
      0,
      contract.id,
      'UNKNOWN',
      'UNKNOWN',
    );
  }

  convertModelListToGANTSeries(models: Contract[], filter: ContractGANTFilter): ApexAxisChartSeries {
    models = models.filter(model =>
      model.partner
      && model.distributor
      && filter.getPartnerElementList().includes(model.partner.name)
      && filter.getDistributorElementList().includes(model.distributor.name)
    );
    let groupedModels: { [key: string]: { color: string, data: Contract[] } } = {};
    models.forEach(model => {
      if (model.measuringPoint && filter.getPointElementList().includes(model.measuringPoint.name)) {
        if (!Object.keys(groupedModels).includes(model.measuringPoint.name)) {
          groupedModels[model.measuringPoint.name] = {color: model.measuringDevice.color, data: []};
        }
        groupedModels[model.measuringPoint.name].data.push(model);
      }
    });
    const result: ApexAxisChartSeries = [];
    Object.entries(groupedModels).forEach(([key, value], index) => {
      const element: { name: string, data: any[], color: string } = {name: key, data: [], color: value.color};
      value.data.forEach(contract => {
        if (contract.measuringDevice && filter.getDeviceElementList().includes(contract.measuringDevice.uuId)) {
          element.data.push({
            x: contract.measuringDevice.uuId,
            y: [
              new Date(contract.start).getTime(),
              new Date(contract.end).getTime()
            ],
            meta: contract
          });
        }
      });
      result.push(element);
    });
    return result;
  }

  createFilterObjectFromModels(models: Contract[]): ContractGANTFilter {
    const filter = new ContractGANTFilter();
    models.forEach(contract => {
      filter.addPoint(contract);
      filter.addDevice(contract);
      filter.addPartner(contract);
      filter.addDistributor(contract);
    });
    return filter;
  }

  setStartOfDay(start: string) {
    const date: Moment = moment.utc(start).utcOffset(0);
    date.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    return Utilities.dateToBackendDateTimeString(date, false);
  }

  setEndOfDay(end: string) {
    const date: Moment = moment.utc(end).utcOffset(0);
    date.set({hour: 23, minute: 59, second: 59, millisecond: 0})
    return Utilities.dateToBackendDateTimeString(date, false);
  }


  checkUrlIsAvailable(url, token?: string) {
    return new Promise((resolve, reject) => {
      let config = {};
      if (token) {
        config = {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        };
      }
      this.repo.httpClient.get(url, config).pipe(catchError((err, caught) => {
        if ([404, 425].includes(err.status)) {
          resolve(err.error)
        }
        if (err.status == 404) {
          resolve({message: err.error.detail})
        }
        resolve('NOPE')
        return of(err);
      })).subscribe(response => {
        resolve({message: 'Online'})
      })
    });
  }
}


export class ContractGANTFilter implements GANTFilter {
  _pointsControlElements: string[] = [];
  activePoints: string[] = [];
  _deviceControlElements: string[] = [];
  activeDevice: string[] = [];
  _partnerControlElements: string[] = [];
  activePartner: string[] = [];
  _distributorControlElements: string[] = [];
  activeDistributor: string[] = [];

  public addPoint(contract: Contract) {
    if (contract.measuringPoint && !this._pointsControlElements.includes(contract.measuringPoint.name)) {
      this._pointsControlElements.push(contract.measuringPoint.name);
      this.activePoints.push(contract.measuringPoint.name)
    }
  }

  public addDevice(contract: Contract) {
    if (contract.measuringDevice && !this._deviceControlElements.includes(contract.measuringDevice.uuId)) {
      this._deviceControlElements.push(contract.measuringDevice.uuId);
      this.activeDevice.push(contract.measuringDevice.uuId);
    }
  }

  public addPartner(contract: Contract) {
    if (contract.partner && !this._partnerControlElements.includes(contract.partner.name)) {
      this._partnerControlElements.push(contract.partner.name);
      this.activePartner.push(contract.partner.name)
    }
  }

  public addDistributor(contract: Contract) {
    if (contract.distributor && !this._distributorControlElements.includes(contract.distributor.name)) {
      this._distributorControlElements.push(contract.distributor.name);
      this.activeDistributor.push(contract.distributor.name);
    }
  }

  public getDeviceElementList(): string[] {
    return this.activeDevice;
  }

  public getPointElementList(): string[] {
    return this.activePoints;
  }

  public getPartnerElementList(): string[] {
    return this.activePartner;
  }

  public getDistributorElementList(): string[] {
    return this.activeDistributor;
  }


}
