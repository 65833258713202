import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Partner, PartnerUser} from '../../../models/partner.model';
import {PartnerService} from '../../../services/model/partner.service';
import {
  AddImageModalInput,
  AddImageToModelComponent
} from "../../../components/modal/add-image-to-model/add-image-to-model.component";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {UserService} from "../../../services/model/user.service";
import {PARTNER_USER_TYPES, USER_ROLES} from "../../../utilities/constants";
import {User} from "../../../models/user.model";
import {FormToolBarOption} from "../../../components/form-tool-bar/form-tool-bar-option.model";
import {Utilities} from "../../../utilities/utilities";
import {AddUserToModelComponent} from "../../../components/modal/add-user-to-model/add-user-to-model.component";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {AttachmentService} from "../../../services/model/attachment.service";
import {AddressModel} from "../../../components/modal/add-address-to-model/address.model";
import {ValidatorService} from "../../../services/validator.service";
import {Distributor} from "../../../models/distributor.model";

@Component({
  selector: 'app-edit-partners',
  templateUrl: './edit-partners.component.html',
  styleUrls: ['./edit-partners.component.scss']
})
@PageTest({
  path: ['edit', 'edit/:id'],
  pathPrefix: ROUTING.Partner.basePlural,
  layout: AdminLayoutComponent
})
export class EditPartnersComponent implements OnInit {

  private sub: Subscription;
  model: Partner = new Partner();
  componentDialogRef!: NxModalRef<any>;
  toolbarAddOptions: FormToolBarOption[];
  users: any[];
  selectedUserKeys: string[];
  CONTACT: string = PARTNER_USER_TYPES.CONTACT;
  private originalUserKeys: string[];
  private originalUsers: PartnerUser[];
  address: AddressModel = new AddressModel();
  menuConfig: any[];

  constructor(private service: PartnerService,
              private userService: UserService,
              private attachmentService: AttachmentService,
              public dialogService: NxDialogService,
              private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {

    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      await this.loadData(id);
    });
    this.toolbarAddOptions = [
      new FormToolBarOption(this.openAddImageModal(), 'file', 'general.addImageModalButton'),
      new FormToolBarOption(this.openAddUserModel(), 'user-circle', 'general.addUserModalButton')
    ]
    this.menuConfig = await Utilities.getAddOnMenuOptionsForForms();
  }

  private async loadData(id: string) {
    if (id) {
      this.model = await lastValueFrom(this.service.getById(id));
      this.selectedUserKeys = this.model.users.map(partnerUser => partnerUser.userKey);
      this.originalUserKeys = [...this.selectedUserKeys];
      this.originalUsers = [...this.model.users];
      this.model.attachmentId = this.model.attachment ? this.model.attachment.id : null;
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachment);
      if (this.model.address && this.model.address.length > 0) {
        this.address = JSON.parse(this.model.address);
      }
    }
    if (this.model == null) {
      this.model = new Partner();
      this.model.users = [];
    }
    this.users = await lastValueFrom(this.userService.getUsersByRole(USER_ROLES.PARTNER));
  }

  save(): () => void {
    return async () => {
      try {
        ValidatorService.validateAddress(this.address, Distributor.name);
        this.model.address = JSON.stringify(this.address);
        this.service.validate(this.model);
        if (!this.model.id) {
          await this.saveProcess();
        } else {
          await this.updateProcess();
        }
        this.service.navigateToBase();
      } catch (e: any) {
        this.service.validationErrorHandler(e);
      }
    };
  }

  private async updateProcess() {
    await lastValueFrom(this.service.repo.update(this.model.id, this.model));
    if (this.originalUserKeys.length > 0) {
      await lastValueFrom(this.service.repo.removeUsers(this.model.id, this.originalUserKeys));
      // this.originalUsers.forEach(user => {
      //   const partnerIndex = user.user.roles.indexOf(USER_ROLES.PARTNER);
      //   if (partnerIndex > -1) {
      //     user.user.roles.splice(partnerIndex, 1);
      //   }
      //   this.userService.repo.updateUser(user.user).subscribe();
      // })
    }
    for (const partnerUser of this.model.users) {
      await lastValueFrom(this.service.repo.addUsers(this.model.id, [partnerUser]));
      partnerUser.user.roles.push(USER_ROLES.PARTNER);
      this.userService.repo.updateUser(partnerUser.user).subscribe();
    }
  }

  private async saveProcess() {
    this.model.slug = this.model.name;
    await lastValueFrom(this.service.repo.save(this.model));
  }

  cancel(): () => void {
    return () => {
      this.service.navigateToBase();
    };
  }

  openReportEmail(): () => void {
    return () => {
      this.componentDialogRef = this.dialogService.open(
        AddImageToModelComponent,
        Utilities.getDefaultModalConfig(new AddImageModalInput(this.model))
      );
      this.componentDialogRef.afterClosed().subscribe(async result => {
        if (result != 'cancel') {
          this.model.attachmentId = result.id;
          this.model.attachment = result;
        }
      });
    };
  }

  openAddImageModal(): () => void {
    return () => {
      this.componentDialogRef = this.dialogService.open(
        AddImageToModelComponent,
        Utilities.getDefaultModalConfig(new AddImageModalInput(this.model))
      );
      this.componentDialogRef.afterClosed().subscribe(async result => {
        if (result != 'cancel') {
          this.model.attachmentId = result.id;
          this.model.attachment = result;
        }
      });
    };
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getLabel(): (option: User) => string {
    return (option: User) => {
      return option.firstName + " " + option.lastName;
    };
  }

  partnerSelectionChange() {
    this.model.users = this.users.filter(user => this.selectedUserKeys.includes(user.keycloakId)).map(user => this.createPartnerUserFromUser(user));
  }

  changeSwitcherForUser(i: number) {
    this.model.users[i].type = this.model.users[i].type == this.CONTACT ? PARTNER_USER_TYPES.GENERAL : PARTNER_USER_TYPES.CONTACT;
  }

  private createPartnerUserFromUser(user: User): PartnerUser {
    const partnerUser = new PartnerUser(user.keycloakId);
    partnerUser.user = user;
    if (this.model.users) {
      const partnerUserInPartner = this.model.users.find(partnerUser => partnerUser.userKey == user.keycloakId);
      if (partnerUserInPartner) {
        partnerUser.type = partnerUserInPartner.type;
      }
    } else {
      partnerUser.type = PARTNER_USER_TYPES.CONTACT;
    }
    return partnerUser;
  }

  openAddUserModel(): () => void {
    return () => {
      const user = new User();
      user.roles.push(USER_ROLES.PARTNER)
      user.chmod = ['0', '4', '0'];
      this.componentDialogRef = this.dialogService.open(
        AddUserToModelComponent,
        Utilities.getDefaultModalConfig(user)
      );
      this.componentDialogRef.afterClosed().subscribe(async result => {
        if (result != 'cancel') {
          this.userService.repo.saveUser(result).subscribe(savedUser => {
            this.users.push(this.userService.addExtendedPropertiesForModel(savedUser));
            if (!this.selectedUserKeys) {
              this.selectedUserKeys = [];
            }
            this.selectedUserKeys.push(savedUser.keycloakId);
            this.partnerSelectionChange();
          });
        }
      });
    };
  }
}
