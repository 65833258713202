<form class="nx-margin-top-s">
  <div nxLayout="grid">
    <div nxRow="">
      <div nxCol="{{withRoles?'12,12,12,4':'12'}}">
        <div nxRow="">
          <div nxCol="{{withRoles?'12':'12,12,6,6'}}">
            <app-input
              captionCode="user.firstName"
              name="firstName"
              type="text"
              [required]="true"
              [(ngModel)]="user.firstName"
            ></app-input>
          </div>
          <div nxCol="{{withRoles?'12':'12,12,6,6'}}">
            <app-input
              captionCode="user.lastName"
              name="lastName"
              type="text"
              [required]="true"
              [(ngModel)]="user.lastName"
            ></app-input>
          </div>
          <div nxCol="{{withRoles?'12':'12,12,6,6'}}">
            <app-input
              captionCode="user.userName"
              name="userName"
              type="text"
              [required]="true"
              [(ngModel)]="user.userName"
            ></app-input>
          </div>
          <div nxCol="{{withRoles?'12':'12,12,6,6'}}">
            <app-input
              captionCode="user.email"
              name="email"
              type="email"
              [required]="true"
              [(ngModel)]="user.email"
            ></app-input>
          </div>
          <div nxCol="{{withRoles?'12':'12,12,6,6'}}">
            <nx-formfield nxLabel="{{'user.phone' | caption}} *">
              <nx-phone-input name="phone"
                              countryCode="HU"
                              [(ngModel)]="user.phone"
                              required
              ></nx-phone-input>
            </nx-formfield>
          </div>
        </div>
      </div>
      <ng-container *ngIf="withRoles">
        <div nxCol="12,12,12,8">
          <forms-user-role-form [user]="user" [userPartner]="userPartner" [userDistributor]="userDistributor" (userDistributorChange)="userDistributorChangeSupplier($event)" (userPartnerChange)="userPartnerChangeSupplier($event)"></forms-user-role-form>
        </div>
      </ng-container>
      <ng-container *ngIf="showAddress && user.address.city">
        <div nxCol="12">
          <nx-data-display [label]="'general.address.label' | caption" orientation="vertical">
            {{user.address | address}}
          </nx-data-display>
        </div>
      </ng-container>

    </div>
  </div>
</form>
