import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {BaseModelService} from './base-model.service';
import {DetectorRepository} from '../../repositories/detector-repository';
import {IrfNotificationService} from "irf-services";
import {Detector} from "../../models/detector.model";
import {ValidatorService} from "../validator.service";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DetectorService extends BaseModelService<DetectorRepository> {

  constructor(repo: DetectorRepository, notificationService: IrfNotificationService, router: Router,) {
    super(ROUTING.Detector, repo, notificationService, router);
  }

  getAllByDeleted(deleted: boolean) {
    return this.repo.getAllByDeleted(deleted).pipe(
      map(detectors => this.addExtendedPropertiesForModelList(detectors))
    );
  }

  getById(id: string) {
    return this.repo.getById(id).pipe(
      map(detector => this.addExtendedPropertiesForModel(detector))
    );
  }

  override validate(editableRow: Detector) {
    const attributeBlackList = ['uuId', 'id', 'algorithm'];
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    ValidatorService.allRequired(editableRow, attributeList, 'Detector');
  }
}
