<nx-card class="viewer">
  <nx-card-header>
    <page-form-header [model]="model"></page-form-header>
  </nx-card-header>
  <page-form-tool-bar
    [saveSupplier]="save()"
    [cancelSupplier]="cancel()"
    [disableSave]="model.config.length<1"
  >

  </page-form-tool-bar>
  <forms-detector-form [model]="model"></forms-detector-form>
</nx-card>
