import {PARTNER_USER_TYPES} from "../utilities/constants";
import {User} from "./user.model";
import {Addon} from "./addon.model";
import {BaseModel} from "./base.model";

export class Distributor implements BaseModel {

  id: number;
  slug: string;
  name: string = '';
  address:string=''
  users: DistributorUser[];
  attachmentId: number;
  attachment?: any;
  contactEmails?:string;
}

export class DistributorUser {
  userKey: string;
  user: User

  constructor(userKey: string) {
    this.userKey = userKey;
  }

}

