<ng-container *ngIf="!showData">
  <span>{{placeholder}}</span>
  <a class="float-end" href="javascript:void(0)" (click)="showData = true">
    <nx-icon name="eye"></nx-icon>
  </a>
</ng-container>
<ng-container *ngIf="showData">
  <div nxRow="">
    <div nxCol="10">
      <span class="hidden-content pe-2" >{{data}}</span></div>
    <div nxCol="2">
      <a class=" float-end" href="javascript:void(0)" (click)="showData = false">
        <nx-icon name="eye-slash"></nx-icon>
      </a>
    </div>
  </div>

</ng-container>
