<nx-card class="table-card-viewer">
  <page-list-view-header
    [title]="'webhook.headline'"
  ></page-list-view-header>
  <ng-container *ngFor="let type of WebhookType | keyvalue">
    <nx-card class="viewer nx-margin-bottom-2m">
      <nx-toolbar class="justify-content-between d-flex">

        <h4 nxHeadline="subsection-small" class="back-right">{{'webhook.' + type.key | caption}}</h4>

        <ng-container *ngIf="!mobile">
          <button nxButton="primary small" type="button" (click)="openNewDialog(type.key)">
            <nx-icon name="save-o" class="nx-margin-right-2xs"></nx-icon>
            {{'general.new' | caption}}
          </button>
        </ng-container>

        <ng-container *ngIf="mobile">

          <button
            nxIconButton="cte small"
            [nxContextMenuTriggerFor]="menu"
            aria-label="Open menu"
            type="button"
          >
            <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
          </button>

          <nx-context-menu #menu="nxContextMenu">
            <button nxContextMenuItem type="button" (click)="openNewDialog(type.key)">
              <nx-icon name="save-o" class="nx-margin-right-2xs"></nx-icon>
              {{'general.new' | caption}}
            </button>
          </nx-context-menu>


        </ng-container>
      </nx-toolbar>
      <ng-container *ngFor="let webhook of models | skipInvalidItem:'type':type.key">

        <div class="row" style="padding: 0 32px">
          <div class="col-10">
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="people-group" class="me-1"></nx-icon>
                    {{'webhook.partner' | caption}}:
                  </p>
                  <p class="ms-2 info">{{webhook.partner.name}}</p>
                </div>
              </div>
              <div class=" col-md-4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="handshake" class="me-1"></nx-icon>
                    {{'webhook.signatureUrl' | caption}}:

                  </p>
                  <p class="ms-2 info">{{webhook.signatureUrl}}</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="link" class="me-1"></nx-icon>
                    {{'webhook.url' | caption}}:

                  </p>
                  <p class="ms-2 info">{{webhook.url}}</p>
                </div>
              </div>
              <div class=" col-12">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="mask" class="me-1"></nx-icon>
                    {{'webhook.securityKey' | caption}}:

                  </p>
                  <p class="ms-2 info">{{webhook.securityKey}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2 d-flex flex-column align-items-end justify-content-center">
            <button nxButton="secondary small" type="button" (click)="openEditDialog(webhook)" class="mb-2">
              <nx-icon name="pencil" class="nx-margin-right-2xs"></nx-icon>
              {{'general.edit' | caption}}
            </button>
            <button nxButton="danger small" type="button" (click)="delete(webhook)">
              <nx-icon name="trash" class="nx-margin-right-2xs"></nx-icon>
              {{'general.delete' | caption}}
            </button>
          </div>
        </div>
        <hr/>

      </ng-container>
    </nx-card>
  </ng-container>
</nx-card>
