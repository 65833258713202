import {PARTNER_USER_TYPES} from "../utilities/constants";
import {User} from "./user.model";
import {BaseModel} from "./base.model";

export class Partner implements BaseModel {

  id: number;
  slug: string;
  apiKey: string;
  name: string = '';
  users: PartnerUser[];
  attachmentId: number;
  attachment?: any;
  address: any;
  reportEmails: any[];
  menuConfig: any[];

}

export class PartnerUser {
  type: string;
  userKey: string;
  user: User

  constructor(userKey: string) {
    this.userKey = userKey;
    this.type = PARTNER_USER_TYPES.GENERAL
  }

}

