import {Injectable} from '@angular/core';
import {ROUTING} from '../../utilities/routing-constants';
import {Router} from '@angular/router';
import {ContractRepository} from '../../repositories/contract-repository';
import {Contract} from "../../models/contract.model";
import {ValidatorService} from "../validator.service";
import {map} from "rxjs";
import {ConfiguratorContactRepository} from "../../repositories/configurator-contact-repository";

import {Utilities} from "../../utilities/utilities";
import {IrfNotificationService} from "irf-services";
import {Distributor} from "../../models/distributor.model";
import {BaseContractService} from "./base-contract.service";

@Injectable({
  providedIn: 'root'
})
export class ContractService extends BaseContractService<ContractRepository> {


  constructor(repo: ContractRepository, notificationService: IrfNotificationService, router: Router, configuratorRepo: ConfiguratorContactRepository) {
    super(ROUTING.Contract, repo, notificationService, router, configuratorRepo);
  }

  getAllByDeleted(deleted: boolean) {
    return this.repo.getAllByDeleted(deleted).pipe(
      map(contracts => this.addExtendedPropertiesForModelList(contracts))
    )
  }

  getAllByMeasuringDeviceIdOrMeasuringPointId(pointId: number, deviceId: number) {
    return this.repo.getAllByMeasuringDeviceIdOrMeasuringPointId(pointId, deviceId).pipe(
      map(contracts => this.addExtendedPropertiesForModelList(contracts))
    )
  }

  getById(id: number) {
    return this.repo.getById(id).pipe(
      map(contract => this.addExtendedPropertiesForModel(contract))
    )
  }

  getAllActiveWithOnlineDevice(distributorIds: number[]) {
    return this.configuratorRepo.getAllActiveWithOnlineDevice(distributorIds).pipe(
      map(contracts => this.addExtendedPropertiesForModelList(contracts).filter(contract => !contract.deleted))
    );
  }

  lock(id: number, lockId: string) {
    return this.configuratorRepo.lock(id, lockId);
  }

  unlock(id: number, lockId: string) {
    return this.configuratorRepo.unlock(id, lockId);
  }

  adminUnlock(id: number) {
    return this.repo.adminUnlock(id);
  }

  updateConfigJson(id: number, lockId: string, configJSON: string) {
    return this.configuratorRepo.updateConfigJson(id, lockId, {
      id: id, lockId: lockId, rawConfigJson: configJSON
    });
  }

  override addExtendedPropertiesForModel(model: any): any {
    model.name = model.measuringPoint.name + ' - ' + model.measuringDevice.uuId;
    if (!model.distributor) {
      model.distributor = new Distributor();

    }
    return model;
  }

  override validate(editableRow: Contract, startCanBeInPast = false, extendedAttributeBlackList: string[] = []) {
    const attributeBlackList = ['id', 'measuringDevice', 'measuringPoint', 'partners', 'actualConfig'].concat(extendedAttributeBlackList);
    const attributeList = Object.keys(editableRow).filter(row => !attributeBlackList.includes(row));
    ValidatorService.allRequired(editableRow, attributeList, 'Contract');
    ValidatorService.validateStartEndDate(new Date(Utilities.dateToBackendString(new Date(editableRow.start))), new Date(editableRow.end), startCanBeInPast);
  }
}
