import {Component, Input, OnInit} from '@angular/core';
import {Detector} from "../../../models/detector.model";

@Component({
  selector: 'inside-show-detector',
  templateUrl: './show-detector.component.html',
  styleUrls: ['./show-detector.component.scss']
})
export class ShowDetectorComponent implements OnInit {

  @Input() model: any | Detector;

  constructor() {
  }

  ngOnInit(): void {
  }

}
