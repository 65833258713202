import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'page-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {
  @Input() model: any;
  @Input() titleProperty: string = 'name';
  modelType: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.modelType = this.route.snapshot.url[0].path;
  }

}
