import {Component, Inject, OnInit} from '@angular/core';
import {User} from "../../../models/user.model";
import {UserService} from "../../../services/model/user.service";
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

@Component({
  selector: 'app-add-user-to-model',
  templateUrl: './add-user-to-model.component.html',
  styleUrls: ['./add-user-to-model.component.scss']
})
export class AddUserToModelComponent implements OnInit {

  user: User;

  constructor(@Inject(NX_MODAL_DATA) public model: User, private userService: UserService) {
  }

  ngOnInit(): void {
    this.user = this.model;
  }
}
