import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WelcomeComponent} from './welcome/welcome.component';
import {RouterModule} from "@angular/router";
import {NxTableModule} from "@aposin/ng-aquila/table";
import {NxBadgeModule} from "@aposin/ng-aquila/badge";
import {TranslateModule} from "@ngx-translate/core";
import {TestComponent} from './test/test.component';
import {CoreModule} from "common";
import {NxLinkModule} from "@aposin/ng-aquila/link";
import {NxGridModule} from "@aposin/ng-aquila/grid";
import {IrfCaptionModule} from "irf-caption";


@NgModule({
  declarations: [
    WelcomeComponent,
    TestComponent
  ],
  exports: [
    WelcomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NxTableModule,
    NxBadgeModule,
    TranslateModule,
    CoreModule,
    NxLinkModule,
    NxGridModule,
    IrfCaptionModule,

  ],
})
export class WelcomeModule {
}
