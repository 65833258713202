import {Component, Input, OnInit} from '@angular/core';
import {MeasuringDevice} from "../../../models/measuring-device.model";
import {
  DATA_DISPLAY_DEFAULT_OPTIONS,
  DataDisplayDefaultOptions,
  NxDataDisplayOrientation
} from "@aposin/ng-aquila/data-display";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {map, Observable} from "rxjs";

const options: DataDisplayDefaultOptions = {
  size: 'medium',
};

@Component({
  selector: 'inside-show-measuring-device-data',
  templateUrl: './show-measuring-device-data.component.html',
  styleUrls: ['./show-measuring-device-data.component.scss'],
  providers: [
    {provide: DATA_DISPLAY_DEFAULT_OPTIONS, useValue: options,},
  ],
})
export class ShowMeasuringDeviceDataComponent implements OnInit {
  readonly mobileViewport$ = this.viewportService.max(
    NxBreakpoints.BREAKPOINT_MEDIUM,
    100,
  );
  readonly orientation$: Observable<NxDataDisplayOrientation> =
    this.mobileViewport$.pipe(
      map(mobile => (mobile ? 'vertical':'horizontal-columns' )),
    );

  @Input() model: any | MeasuringDevice;
  showApiKey: boolean = false;

  constructor(private readonly viewportService: NxViewportService) {
  }

  ngOnInit(): void {
  }

}
