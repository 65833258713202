<nx-card class="viewer">
  <nx-card-header>
    <page-form-header [model]="model" [titleProperty]="'name'">
    </page-form-header>
  </nx-card-header>

  <page-form-tool-bar
    [saveSupplier]="save()"
    [cancelSupplier]="cancel()"
    [modalOptions]="toolbarAddOptions"
  ></page-form-tool-bar>
  <form class="form-example mt-3">
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12,12,6,4">
          <div nxRow="">
            <div nxCol="12">
              <nx-formfield nxLabel="{{'partner.name' | caption}}">
                <input name="lastName" type="text" nxInput [(ngModel)]="model.name" required/>
              </nx-formfield>
            </div>
            <div nxCol="12">
              <nx-formfield nxLabel="{{'addon.menuConfig' | caption}} *">
                <nx-multi-select [(ngModel)]="model.menuConfig" name="roles"
                                 [options]="menuConfig" selectValue="id" selectLabel="label"
                ></nx-multi-select>
              </nx-formfield>
            </div>
            <div nxCol="12">
              <ng-container *ngIf="users">
                <nx-formfield nxLabel="{{'partner.users' | caption}}">
                  <nx-multi-select filter
                                   [(ngModel)]="selectedUserKeys" name="users"
                                   [options]="users"
                                   [selectLabel]="getLabel()"
                                   [selectValue]="'keycloakId'"
                                   (selectionChange)="partnerSelectionChange()"
                  ></nx-multi-select>
                </nx-formfield>
              </ng-container>
            </div>
            <div nxCol="12">
              <p>{{'measuringDevice.attachment' | caption}}</p>
              <ng-container *ngIf="model.attachment">
                <figure nxFigure="">
                  <img style="width: 200px"
                       [src]="model.attachment.srcWidth"
                       [alt]="model.name"
                       (error)="model.attachment.srcWidth = 'assets/img/default.png'"
                  />
                </figure>
              </ng-container>
            </div>
            <div nxCol="12">
              <p>{{'partner.usersContact' | caption}}</p>
              <ng-container *ngFor="let partnerUser of model.users; let i = index">

                <nx-switcher labelSize="small" class="nx-margin-s" (checkedChange)="changeSwitcherForUser(i)"
                             [checked]="partnerUser.type==CONTACT">{{'partner.userIsContact' | caption}}
                  -> {{partnerUser.user.firstName}} {{partnerUser.user.lastName}}</nx-switcher>

              </ng-container>

            </div>
          </div>
        </div>
        <div nxCol="12, 12,6,8">
          <p>{{'partner.address' | caption}}</p>
          <forms-address-form [address]="address"></forms-address-form>


        </div>
      </div>
    </div>
  </form>
</nx-card>
