<nx-card class="viewer">
  <nx-card-header>
    <page-form-header [model]="model"></page-form-header>
  </nx-card-header>
  <page-form-tool-bar
    [saveSupplier]="save()"
    [cancelSupplier]="cancel()"
  >
  </page-form-tool-bar>
  <forms-addon-form [model]="model"></forms-addon-form>
</nx-card>
