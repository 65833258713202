import {Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {Partner, PartnerUser} from '../models/partner.model';
import {BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from "ng-pository";
import {Distributor, DistributorUser} from "../models/distributor.model";

@RepoPath('/mgmt')
@Injectable({
  providedIn: 'root'
})
export class DistributorRepository extends BaseRepository<PostPayloadInterface | any> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/admin/distributors?deleted={deleted}')
  getAllByDeleted(deleted: boolean) {
    return super.get();
  }

  @Path('/admin/distributors?deleted={deleted}&dtoType={dtoType}')
  getAllByDeletedAndDtoType(deleted: boolean, dtoType: string) {
    return super.get();
  }

  @Path('/admin/distributors/{id}')
  getById(id: string) {
    return super.get();
  }

  @Path('/admin/distributors/by-user-key/{userKey}')
  getDistributorForUserAsAdmin(userKey: string) {
    return super.get();
  }
  @Path('/distributor/distributors/by-user-key/{userKey}')
  getDistributorForUser(userKey: string) {
    return super.get();
  }

  @Path('/admin/distributors')
  save(partner: Distributor) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: partner
    });
  }

  @Path('/admin/distributors/{id}')
  update(id: number, partner: Distributor) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: partner
    });
  }

  @Path('/admin/distributors/{id}')
  deleteById(id: string) {
    return super.delete();
  }

  @Path('/admin/distributors/{id}/users/add')
  addUsers(id: number, distributorUsers: DistributorUser[]) {
    return super.patch({id: id, users: distributorUsers});
  }

  @Path('/admin/distributors/{id}/users/remove')
  removeUsers(id: number, userKeys: string[]) {
    return super.patch({id: id, userKeys: userKeys});
  }


}

