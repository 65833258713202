import {Component, Inject, Input, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";

type QRCODE_LEVEL = "L" | "M" | "Q" | "H";

@Component({
  selector: 'app-show-qr-code',
  templateUrl: './show-qr-code.component.html',
  styleUrls: ['./show-qr-code.component.scss']
})
export class ShowQrCodeComponent implements OnInit {
  @Input() value: string;
  public level: QRCODE_LEVEL;
  public width: number;
  public levelOptions:QRCODE_LEVEL[] = ["L", "M", "Q", "H"];
  public widthOptions = [200, 300, 400, 500];

  constructor(@Inject(NX_MODAL_DATA) value: string) {
    this.value = value;
    this.level = "L";
    this.width = 200;
  }

  ngOnInit(): void {
  }

  qrLevel(val:QRCODE_LEVEL) {
    this.level = val;
  }

  qrWidth(val: number) {
    this.width = val;
  }

}
