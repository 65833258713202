import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AttachmentService} from "../../../services/model/attachment.service";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";


@Component({
  selector: 'app-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss']
})
export class ShowImageComponent implements OnInit, AfterViewInit {
  @Input() figure: 'auto' | '1by1' | '1dot8by1' | '1dot2by1' | '1by1dot1' | '2dot6by1' | 'rounded' = "auto";
  @Input() image: any;
  @Input() width: string | number = 200;
  @Input() height: string | number = 'auto';
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';

  @ViewChild('fancy') templateRef!: TemplateRef<any>;
  widthStyle: string;
  heightStyle: string;
  templateDialogRef?: NxModalRef<any>;


  constructor(private attachmentService: AttachmentService, private readonly dialogService: NxDialogService) {
  }

  ngOnInit(): void {
    this.image = this.attachmentService.addExtendedPropertiesForModel(this.image);
    console.log(this.image);
    this.image.src = this.attachmentService.getSrcForAttachment(this.image, this.orientation, this.getScale());
    this.initStyle()
  }

  private getScale() {
    if (this.orientation == "horizontal" && typeof this.width == "number") {
      return this.width;
    }
    if (this.orientation == "horizontal" && typeof this.width != "number" && typeof this.height == "number") {

      this.widthStyle = this.width + "px";
      return this.height;
    }
    if (this.orientation == "vertical" && typeof this.height == "number") {
      return this.height;
    }
    if (this.orientation == "vertical" && typeof this.height != "number" && typeof this.width == "number") {
      return this.width;
    }
    return 200;
  }

  ngAfterViewInit(): void {
    const element: HTMLElement | null = document.getElementById(this.image.slug);
    if (element != null) {
      element.style.height = this.height.toString();
      element.style.width = this.width.toString();
    }
  }

  private initStyle() {
    if (typeof this.width == "number") {
      this.widthStyle = this.width + "px";
    } else {
      this.widthStyle = this.width;
    }
    if (typeof this.height == "number") {
      this.heightStyle = this.height + "px";
    } else {
      this.heightStyle = this.height;
    }
  }

  openFancy() {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      showCloseIcon: true,
    });
  }
}
