<div class="table-card-toolbar nx-margin-bottom-s">
  <h4 class=" back-right nx-margin-y-s"
      [ngClass]="{'mobile-hading':mobile}">
    {{title | caption}}
  </h4>

  <ng-container *ngIf="!mobile">
    <ng-container *ngIf="viewChangers != undefined">
      <ng-container *ngFor="let viewChangerEntry of viewChangers | keyvalue">
        <button
          nxButton="{{viewChangerEntry.value.show?'cte':'tertiary'}} small"
          [attr.aria-label]="viewChangerEntry.value.viewName"
          type="button"
          class="nx-margin-right-s nx-margin-bottom-0"
          (click)="changeView(viewChangerEntry.key)"
        >
          <nx-icon name="{{viewChangerEntry.value.icon}}"></nx-icon>
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="newSupplier && hasPermission">
      <button (click)="newSupplier()"
              nxButton="primary small"
              aria-label="new"
              type="button">
        <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
        {{'general.new' | caption}}
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="mobile">

    <button
      nxIconButton="cte small"
      [nxContextMenuTriggerFor]="menu"
      aria-label="Open menu"
      type="button"
    >
      <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
    </button>

    <nx-context-menu #menu="nxContextMenu">
      <ng-container *ngIf="viewChangers != undefined">
        <button
          *ngFor="let viewChangerEntry of viewChangers | keyvalue"
          nxContextMenuItem
          [attr.aria-label]="viewChangerEntry.value.viewName"
          type="button"
          class="nx-margin-right-s nx-margin-bottom-0"
          (click)="changeView(viewChangerEntry.key)"
        >
          <nx-icon name="{{viewChangerEntry.value.icon}}"></nx-icon>
          <span
            [ngClass]="{'active-view':viewChangerEntry.value.show}">{{'general.' + viewChangerEntry.value.viewName | caption}}</span>
        </button>
      </ng-container>
      <button (click)="newSupplier()"
              nxContextMenuItem
              aria-label="a useful label"
              type="button">
        <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
        {{'general.new' | caption}}
      </button>
    </nx-context-menu>
  </ng-container>
</div>

