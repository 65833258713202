<div nxLayout="grid">
  <div nxRow="">
    <div nxCol="12">
      <nx-accordion [multi]="false">
        <nx-expansion-panel>
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>{{'general.attachmentInnerUpload' | caption}}</nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <app-attachment-upload
            [acceptString]="acceptString"
            [isMultiple]="isMultiple"
            [saveSupplier]="attachmentSave()"
          ></app-attachment-upload>
        </nx-expansion-panel>
        <nx-expansion-panel class="p-0" [(expanded)]="secondPanelExpanded">
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>{{'general.attachmentChoose' | caption}}</nx-expansion-panel-title>
          </nx-expansion-panel-header>
          <div nxRow="">

            <div nxCol="6">
              <div class="attachment-card"
                   [id]="AVAILABLE"
                   cdkDropList
                   #available="cdkDropList"
                   [cdkDropListConnectedTo]="[attached]"
                   [cdkDropListData]="filteredOptionalElements"
                   (cdkDropListDropped)="drop($event)">
                <div class="attachment-card-header">
                  <h5> {{availableTitle | caption}}</h5>
                  <app-input
                    type="text"
                    name="filter"
                    captionCode="general.search"
                    getCaption
                    [(ngModel)]="filterValue"
                    (ngModelChange)="filter()"
                    ></app-input>
                </div>
                <div class="attachment-card-body">
                  <div *ngFor="let attachment of filteredOptionalElements" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <ng-template viewAnchor [data]="attachment" [isOptional]="true"
                                 (refreshEvent)="refreshFromChild($event)"></ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div nxCol="6">
              <div class="attachment-card"
                   [id]="ATTACHED"
                   cdkDropList
                   #attached="cdkDropList"
                   [cdkDropListConnectedTo]="[available]"
                   [cdkDropListData]="attachedItems"
                   (cdkDropListDropped)="drop($event)">
                <div class="attachment-card-header">
                  <h5> {{attachedTitle | caption}}</h5>
                </div>
                <div class="attachment-card-body">
                  <div *ngFor="let attachment of attachedItems" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    <ng-template viewAnchor [data]="attachment" (refreshEvent)="refreshFromChild($event)"></ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>


    </div>
  </div>
</div>

