import {Component, Inject, OnInit} from '@angular/core';
import {NX_MODAL_DATA} from "@aposin/ng-aquila/modal";
import {Contract} from "../../../models/contract.model";
import {lastValueFrom} from "rxjs";
import {Utilities} from "../../../utilities/utilities";
import {ContractService} from "../../../services/model/contract.service";
import {MyStorageService} from "../../../services/my-storage.service";
import {DENY_DATE_KEYS} from "../../../utilities/constants";
import {ContractDistributorService} from "../../../services/model/contract-distributor.service";

export type CONTRACT_EDIT_TYPES = 'date' | 'measuringPoint' | 'measuringDevice' | 'startDate' | 'endDate' | 'partner'  | 'config';

@Component({
  selector: 'app-select-contract-edit-type',
  templateUrl: './select-contract-edit-type.component.html',
  styleUrls: ['./select-contract-edit-type.component.scss']
})
export class SelectContractEditTypeComponent implements OnInit {

  contract: Contract;
  denyDates: Date[] = [];

  firstStartDenyDate?: Date;
  firstEndDenyDate?: Date;

  editTypes: CONTRACT_EDIT_TYPES[] = ['date', 'measuringPoint', 'measuringDevice', 'partner','config'];
  selectedEditType: CONTRACT_EDIT_TYPES = 'measuringDevice';
  availableDateModifyingType?: CONTRACT_EDIT_TYPES;
  private asDistributor: boolean = false;

  constructor(private service: ContractService,private contractDistributorService:ContractDistributorService,private myStorageService: MyStorageService, @Inject(NX_MODAL_DATA) public data: { model: Contract, asDistributor:boolean }) {
  }

  ngOnInit(): void {
    this.contract = this.data.model;
    this.asDistributor = this.data.asDistributor;

    this.initDenyDates();

  }

  private async initDenyDates(): Promise<void> {
    if (this.contract.measuringDevice != undefined && this.contract.measuringPoint != undefined) {
      let contracts;

      if(this.asDistributor){
        if(this.contract.distributor) {
          contracts = await lastValueFrom(this.contractDistributorService.getAllByMeasuringDeviceIdOrMeasuringPointId(this.contract.distributor.id, this.contract.measuringPoint?.id, this.contract.measuringDevice?.id));
        }else{
          this.service.notificationService.showError('general.error', 'error.noDistributor');
          return;
        }
      }else{
        contracts = await lastValueFrom(this.service.getAllByMeasuringDeviceIdOrMeasuringPointId(this.contract.measuringPoint?.id, this.contract.measuringDevice?.id));
      }
      contracts.forEach(contract => {
        this.denyDates = this.denyDates.concat(Utilities.getDatesInRange(new Date(contract.start), new Date(contract.end)));
      });
    }
    this.setAvailableDateModifyingType();
    this.clearStorage()
    this.myStorageService.saveToStore(DENY_DATE_KEYS.DENY_DATES, this.denyDates);
    this.myStorageService.saveToStore(DENY_DATE_KEYS.FIRST_END_DENY_DATE, this.firstEndDenyDate);
    this.myStorageService.saveToStore(DENY_DATE_KEYS.FIRST_START_DENY_DATE, this.firstStartDenyDate);
  }

  private setAvailableDateModifyingType() {
    const startDate = new Date(this.contract.start);
    const endDate = new Date(this.contract.end);
    const startDeny = this.denyDates.filter(date => {
      return date.getTime() < startDate.getTime();
    }).sort((date1, date2) => date2.getTime() - date1.getTime());
    const endDeny = this.denyDates.filter(date => {
      return date.getTime() > endDate.getTime();
    }).sort((date1, date2) => date1.getTime() - date2.getTime());
    if (startDeny.length < 1 && endDeny.length < 1) {
      this.availableDateModifyingType = "date";
      return;
    }

    this.firstStartDenyDate = startDeny[0];
    this.firstEndDenyDate = endDeny[0];
    if (startDeny.length > 0 && endDeny.length < 1) {
      if (Utilities.addDays(startDate, -1).getTime() > this.firstStartDenyDate.getTime()) {
        this.availableDateModifyingType = 'date';
      } else {
        this.availableDateModifyingType = 'endDate';
      }
      return;
    }
    if (startDeny.length < 1 && endDeny.length > 0) {
      if (Utilities.addDays(endDate, 1).getTime() < this.firstEndDenyDate.getTime()) {
        this.availableDateModifyingType = 'date';
      } else {
        this.availableDateModifyingType = 'startDate';
      }
      return;
    }

    if (Utilities.addDays(startDate, -1).getTime() <= this.firstStartDenyDate.getTime()
      && Utilities.addDays(endDate, 1).getTime() >= this.firstEndDenyDate.getTime()
    ) {
      this.availableDateModifyingType = undefined;
    } else if (Utilities.addDays(startDate, -1).getTime() > this.firstStartDenyDate.getTime()
      && Utilities.addDays(endDate, 1).getTime() >= this.firstEndDenyDate.getTime()) {
      this.availableDateModifyingType = 'startDate';
    } else if (Utilities.addDays(startDate, -1).getTime() <= this.firstStartDenyDate.getTime()
      && Utilities.addDays(endDate, 1).getTime() < this.firstEndDenyDate.getTime()
    ) {
      this.availableDateModifyingType = 'endDate'
    } else {
      this.availableDateModifyingType = "date"
    }
  }

  private clearStorage() {
    this.myStorageService.deleteFromStore(DENY_DATE_KEYS.DENY_DATES);
    this.myStorageService.deleteFromStore(DENY_DATE_KEYS.FIRST_START_DENY_DATE);
    this.myStorageService.deleteFromStore(DENY_DATE_KEYS.FIRST_END_DENY_DATE);
  }
}
