import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';
import {BASE_URL, BaseRepository, PositoryErrorHandlerService, RepoPath, Path} from 'ng-pository';
import {NoteModel} from "../models/note.model";

@Injectable({
  providedIn: 'root'
})
export class NoteRepository extends BaseRepository<PostPayloadInterface> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }


  @Path('/mgmt/admin/notes')
  saveAsAdmin(addon: NoteModel) {
    return super.post({
      type: 'Note',
      id: 0,
      attribute: addon
    });
  }

}

