<div nxLayout="grid">
  <div nxRow="">
    <div nxCol="12,12,12,8">
      <app-drag-and-drop [files]="files"
                         [acceptString]="acceptString"
                         [isMultiple]="isMultiple">
      </app-drag-and-drop>
    </div>
    <ng-container *ngIf="isMultiple">
      <div nxCol="12">
        <h5>{{'attachment.files' | caption}}</h5>
        <ul class="nx-margin-left-2m">
          <li *ngFor="let file of files; let i = index">
            {{file.name}} <a href="javascript:void(0)" (click)="removeFile(i)">
            <nx-icon name="close"></nx-icon>
          </a>
          </li>
        </ul>
      </div>
    </ng-container>
    <div nxCol="12,12,12,4">
      <div nxRow="">
        <div nxCol="12">
          <nx-formfield nxLabel="{{'attachment.title' | caption}}">
            <input name="firstName" type="text" nxInput [(ngModel)]="attachment.title" required/>
          </nx-formfield>
        </div>
        <div nxCol="12">
          <nx-switcher [(ngModel)]="attachment.detectorAttachment">{{'attachment.isDetectorAttachment' | caption}}</nx-switcher>
        </div>
        <div nxCol="12" class="text-end nx-margin-top-m">
          <button nxButton="primary small" type="button" (click)="save()">{{'general.save' | caption }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
