import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";
import {AbstractShowPage} from "../../../../models/AbstractShowPage";
import {Contract} from "../../../../models/contract.model";
import {ContractDistributorService} from "../../../../services/model/contract-distributor.service";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {MarkerElement} from "../../../../components/map/map.component";
import {MyStorageService} from "../../../../services/my-storage.service";
import {
  SelectContractEditTypeComponent
} from "../../../../components/modal/select-contract-edit-type/select-contract-edit-type.component";
import {Utilities} from "../../../../utilities/utilities";

@Component({
  selector: 'app-show-contracts',
  templateUrl: './show-contracts-for-distributor.component.html',
  styleUrls: ['./show-contracts-for-distributor.component.scss']
})
@PageTest({
  path: '/show/:id',
  pathPrefix: ROUTING.Contract.basePlural,
  layout: DistributorLayoutComponent
})
export class ShowContractsForDistributorComponent extends AbstractShowPage<Contract, ContractDistributorService> implements OnInit, OnDestroy {
  componentDialogRef?: NxModalRef<any>;
  mapModel: MarkerElement;


  constructor(service: ContractDistributorService, route: ActivatedRoute,
              myStorageService:MyStorageService,
  public dialogService: NxDialogService,) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }


  protected override initProcess() {
    this.mapModel = this.service.convertModelToMarkerElement(this.model);

  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  override edit(): () => void {
    return () => {
      console.log(this.model);
      this.componentDialogRef = this.dialogService.open(SelectContractEditTypeComponent,
        Utilities.getDefaultModalConfig({
          model: this.model,
          asDistributor:true
        }));
      this.componentDialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.service.edit(this.model.id,result);
        }
        this.componentDialogRef = undefined;
      });
    };

  }


}
