import {Injectable} from "@angular/core";
import {BaseModelService} from "./base-model.service";
import {WebhookRepository} from "../../repositories/webhook-repository";
import {ModelPath, ROUTING} from "../../utilities/routing-constants";
import {IrfNotificationService} from "irf-services";
import {Router} from "@angular/router";
import {FilterService} from "../filterer/filter.service";
import {map} from "rxjs";
import {Webhook, WebhookType} from "../../models/webhook.model";
import {DTO} from "./measuring-device.service";
import {ValidatorService} from "../validator.service";
import {ReportEmailRepository} from "../../repositories/report-email-repository";

@Injectable({
  providedIn: 'root'
})
export class ReportEmailService extends BaseModelService<ReportEmailRepository> {

  constructor(repo: ReportEmailRepository, notificationService: IrfNotificationService, router: Router, private filterer: FilterService) {
    super(ROUTING.Webhook, repo, notificationService, router);
  }

  getById(id: any, other: any) {
    return this.repo.getById(id);
  }

  getAllByPartners(partnerId: number) {
    let filter: any = this.filterer.filter(DTO.WebhookDTO)
      .equal('deleted', false)
      .and()
      .equal('partner_id', partnerId).create();
    return this.repo.getAllByFilter(filter);
  }


  override validate(model: any) {
    ValidatorService.allRequired(model, ['partnerId', 'type'], Webhook.name);
  }
}
