<div nxModalContent >
  <nx-card class="w-100 h-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.addAddressToModel' | caption}}
      </h3>
    </nx-card-header>
    <div nxLayout="grid">
      <forms-address-form [address]="address"></forms-address-form>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="address"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | caption}}
  </button>
</div>
