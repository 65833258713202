import {Injectable} from "@angular/core";
import {FilterOperationService, SingletonOperation} from "./filter-operation.service";

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    public filter(dtoType: string): SingletonOperation {
        return new FilterOperationService(dtoType);
    }
}
