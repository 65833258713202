import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MeasuringDevice} from '../../../models/measuring-device.model';
import {MeasuringDeviceService} from '../../../services/model/measuring-device.service';
import {NoteModel} from "../../../models/note.model";
import {PageTest} from "../../../utilities/PageTest";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {ROUTING} from "../../../utilities/routing-constants";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'app-show-measuring-devices',
  templateUrl: './show-measuring-devices.component.html',
  styleUrls: ['./show-measuring-devices.component.scss']
})
@PageTest({
  path: 'show/:id',
  pathPrefix: ROUTING.MeasuringDevice.basePlural,
  layout: AdminLayoutComponent
})
export class ShowMeasuringDevicesComponent extends AbstractShowPage<MeasuringDevice, MeasuringDeviceService> implements OnInit {

  constructor(service: MeasuringDeviceService, route: ActivatedRoute,
              myStorageService:MyStorageService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  addNote(): (note: NoteModel) => void {
    return async (note: NoteModel) => {
      await lastValueFrom(this.service.repo.addNote(this.model.id, note.id));
      this.model = await lastValueFrom(this.service.getById(this.model.id));
    };
  }
}
