import {Component, Input, OnInit} from '@angular/core';
import {MeasuringDevice} from "../../../models/measuring-device.model";
import {AttachmentService} from "../../../services/model/attachment.service";
import {MeasuringDeviceService} from "../../../services/model/measuring-device.service";

@Component({
  selector: 'inside-show-measuring-device',
  templateUrl: './show-measuring-device.component.html',
  styleUrls: ['./show-measuring-device.component.scss']
})
export class ShowMeasuringDeviceComponent implements OnInit {

  @Input() model: any | MeasuringDevice;
  @Input() withNavigation: boolean = true;

  constructor(private attachmentService: AttachmentService, private service: MeasuringDeviceService) {
  }

  ngOnInit(): void {
    this.initAttachments();
  }

  private initAttachments(): void {
    if (this.model.attachments && this.model.attachments.length > 0) {
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachments[0].attachment);
    } else {
      this.model.attachment = {
        src: 'assets/img/default.png',
        srcWidth: 'assets/img/default.png'
      }
    }
  }

  goPartner() {
    this.service.show(this.model.id);
  }

}
