import {Component, OnInit} from '@angular/core';
import {lastValueFrom, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MeasuringPoint} from '../../../models/measuring-point.model';
import {MeasuringPointService} from '../../../services/model/measuring-point.service';
import {AddNoteToModelComponent} from "../../../components/modal/add-note-to-model/add-note-to-model.component";
import {
  AddImageModalInput,
  AddImageToModelComponent
} from "../../../components/modal/add-image-to-model/add-image-to-model.component";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {
  AddAddressToModelComponent
} from "../../../components/modal/add-address-to-model/add-address-to-model.component";
import {FormToolBarOption} from "../../../components/form-tool-bar/form-tool-bar-option.model";
import {Utilities} from "../../../utilities/utilities";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AdminLayoutComponent} from "../../../layouts/admin-layout/admin-layout.component";
import {
  AddContractToModelComponent,
  InputToContractModal
} from "../../../components/modal/add-contract-to-model/add-contract-to-model.component";
import {Contract} from "../../../models/contract.model";
import {ContractService} from "../../../services/model/contract.service";
import {AttachmentService} from "../../../services/model/attachment.service";
import {Distributor} from "../../../models/distributor.model";
import {DistributorService} from "../../../services/model/distributor.service";

@Component({
  selector: 'app-edit-measuring-points',
  templateUrl: './edit-measuring-points.component.html',
  styleUrls: ['./edit-measuring-points.component.scss']
})
@PageTest({
  path: ['edit', 'edit/:id'],
  pathPrefix: ROUTING.MeasuringPoint.basePlural,
  layout: AdminLayoutComponent
})
export class EditMeasuringPointsComponent implements OnInit {

  private sub: Subscription;
  model: MeasuringPoint = new MeasuringPoint();
  componentDialogRef!: NxModalRef<any>;
  toolbarAddOptions: FormToolBarOption[];
  contract: Contract;
  distributors: Distributor[];

  constructor(private service: MeasuringPointService,
              private contractService: ContractService,
              public dialogService: NxDialogService,
              public distributorService: DistributorService,
              private attachmentService: AttachmentService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.sub = this.route.params.subscribe(async params => {
      let id = params['id'];
      if (id) {
        this.model = await lastValueFrom(this.service.getById(id));
      }
      if (this.model == null) {
        this.model = new MeasuringPoint();
      }
      this.initAttachments();
      this.toolbarAddOptions = [
        new FormToolBarOption(this.openAddImageModal(), 'file', 'general.addImageModalButton'),
        new FormToolBarOption(this.openAddAddressModal(), 'home', 'general.addAddressModalButton'),
        new FormToolBarOption(this.openContractModal(), 'rocket', 'general.addContractModalButton'),
      ];
      if (!this.model.id) {
        this.toolbarAddOptions.push(new FormToolBarOption(this.openAddNoteModal(), 'speech-bubble-o', 'general.addNoteModalButton'))
      }
      this.distributors = await lastValueFrom(this.distributorService.getAllByDeleted());
    });


  }

  save(): () => void {
    return async () => {
      try {
        this.service.validate(this.model);
        if(this.model.distributor){
          this.model.distributorId = this.model.distributor.id;
        }
        if (this.contract) {
          this.contract.start = this.contractService.setStartOfDay(this.contract.start);
          this.contract.end = this.contractService.setEndOfDay(this.contract.end)
          this.contractService.validate(this.contract, true, ['measuringPointId'])
        }
        if (!this.model.id) {
          await this.saveProcess();
        } else {
          await this.updateProcess();
        }
        this.service.navigateToBase();
      } catch (e: any) {
        this.service.validationErrorHandler(e);
      }
    };
  }

  private async saveProcess() {
    this.model = await lastValueFrom(this.service.repo.save(this.model));
    if (this.contract) {
      this.contract.measuringPointId = this.model.id;
      await lastValueFrom(this.contractService.repo.save(this.contract));
    }
  }

  private async updateProcess() {
    await lastValueFrom(this.service.repo.update(this.model.id, this.model));
    if (this.contract) {
      this.contract.measuringPointId = this.model.id;
      await lastValueFrom(this.contractService.repo.save(this.contract));
    }
  }

  cancel(): () => void {
    return () => {
      this.service.navigateToBase();
    };
  }

  openAddNoteModal(): () => void {
    return () => {
      if (!this.model.id) {
        this.componentDialogRef = this.dialogService.open(
          AddNoteToModelComponent,
          Utilities.getDefaultModalConfig(this.model.note)
        );
        this.componentDialogRef.afterClosed().subscribe(result => {
          if (result != 'cancel') {
            this.model.note = result;
          }
        });
      } else {
        this.service.notificationService.showInfo('general.info', 'measuringDevice.addNoteInShowView');
      }
    };
  }

  openAddAddressModal(): () => void {
    return () => {
      this.componentDialogRef = this.dialogService.open(
        AddAddressToModelComponent,
        Utilities.getDefaultModalConfig(this.model)
      );
      this.componentDialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.model.address = result;
        }
      });

    };
  }

  openContractModal(): () => void {
    return () => {
      if (this.model.name.length < 1) {
        this.service.notificationService.showWarning("general.warning", "measuringPoint.contractNotAvailable");
        return;
      }
      if (this.contract == undefined) {
        this.contract = new Contract(this.model)
      }
      if (this.model.id) {
        this.contract.measuringPointId = this.model.id;
      }
      const inputToModal: InputToContractModal = {
        model: this.contract,
        points: [this.model],
        denyDates: []
      }
      this.componentDialogRef = this.dialogService.open(
        AddContractToModelComponent,
        Utilities.getDefaultModalConfig(inputToModal)
      );
      this.componentDialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.contract = result;
        }
      });

    };
  }

  openAddImageModal(): () => void {
    return () => {
      this.componentDialogRef = this.dialogService.open(
        AddImageToModelComponent,
        Utilities.getDefaultModalConfig((this.model.id ? new AddImageModalInput(this.model, true, 3) : new AddImageModalInput(this.model)))
      );
      this.componentDialogRef.afterClosed().subscribe(async result => {
        if (result != 'cancel') {
          if (!this.model.id) {
            this.model.attachment = result;
          } else {
            for (const modelAttachment of this.model.attachments) {
              await lastValueFrom(this.service.repo.removeAttachment(this.model.id, modelAttachment.id));
            }
            this.model.attachments = [];
            for (const attachment of result) {
              await lastValueFrom(this.service.repo.addAttachment(this.model.id, attachment.id));
              this.model.attachments.push({attachment: attachment});
            }
          }
          this.initAttachments();
        }
      });
    };
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private initAttachments() {
    if (this.model.attachments && this.model.attachments.length > 0) {
      this.model.attachment = this.attachmentService.addExtendedPropertiesForModel(this.model.attachments[0]);
    }
  }

}
