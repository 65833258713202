import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'skipInvalidItem'
})
export class SkipInvalidItemPipe implements PipeTransform {
  transform(items: any[], property: string, expectedValue: any): any[] {
    if (!items || !property || !expectedValue) {
      return items;
    }

    return items.filter(item => item[property] === expectedValue);
  }

}
