import {Inject, Injectable} from '@angular/core';
import {PostPayloadInterface} from '../utilities/post-payload.interface';
import {HttpClient} from '@angular/common/http';

import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from 'ng-pository';
import {Detector} from '../models/detector.model';

@RepoPath('/mgmt/admin/detectors')
@Injectable({
  providedIn: 'root'
})
export class DetectorRepository extends BaseRepository<PostPayloadInterface> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('?deleted={deleted}')
  getAllByDeleted(deleted: boolean) {
    return super.get();
  }

  @Path('/{id}')
  getById(id: string) {
    return super.get();
  }

  save(detector: Detector) {
    return super.post({
      type: 'Add',
      id: 0,
      attribute: detector
    });
  }

  @Path('/{id}')
  update(id: number, detector: Detector) {
    return super.put({
      type: 'Update',
      id: 0,
      attribute: detector
    });
  }

  @Path('/{id}')
  deleteById(id: string) {
    return super.delete();
  }
  @Path('/{id}/attachments/{attachmentId}/add')
  addAlgorithm(id: number, attachmentId:number) {
    return super.patch();
  }
  @Path('/{id}/attachments/remove')
  removeAlgorithm(id: number) {
    return super.patch();
  }

}

