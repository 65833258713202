import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../models/user.model";

@Component({
  selector: 'inside-show-user-contact',
  templateUrl: './show-user-contact.component.html',
  styleUrls: ['./show-user-contact.component.scss']
})
export class ShowUserContactComponent implements OnInit {
  @Input() model: any | User;

  constructor() {
  }

  ngOnInit(): void {
  }

}
