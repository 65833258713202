import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Contract} from "../../../models/contract.model";
import {lastValueFrom} from "rxjs";
import {MeasuringDeviceService} from "../../../services/model/measuring-device.service";
import {MeasuringPointService} from "../../../services/model/measuring-point.service";
import {PartnerService} from "../../../services/model/partner.service";
import {Utilities} from "../../../utilities/utilities";
import {NxDatepickerComponent} from "@aposin/ng-aquila/datefield";
import * as moment from "moment";
import {Moment} from "moment";
import {MeasuringPointDistributorService} from "../../../services/model/measuring-point-distributor.service";
import {Distributor} from "../../../models/distributor.model";
import {DistributorService} from "../../../services/model/distributor.service";
import {DATA_TYPE_FORMATTER_MAP, JCD_ADDON_MENU} from "jcd-addon";
import {AbstractCaptionService} from "irf-caption";



@Component({
  selector: 'forms-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss']
})
export class ContractFormComponent implements OnInit {

  @ViewChild('endDatePicker') endDatepicker!: NxDatepickerComponent<Moment>;
  @Input() model: Contract;
  @Input() measuringDevices?: any[];
  @Input() measuringPoints?: any[];
  @Input() partners?: any[];
  @Input() distributors?:Distributor[];
  @Input() denyDates: Date[] = [];
  @Input() asDistributor:boolean = false;
  minDate: moment.Moment;
  public chartTypes: any[];
  public menuConfig: any[];

  constructor(private measuringDeviceService: MeasuringDeviceService,
              private measuringPointService: MeasuringPointService,
              private measuringPointDistributorService: MeasuringPointDistributorService,
              private distributorService:DistributorService,
              private partnersService: PartnerService,
              private captionService: AbstractCaptionService) {
  }

  async ngOnInit(): Promise<void> {
    this.loadData();
    this.chartTypes = await Utilities.getChartTypesForForms();
    this.menuConfig = await Utilities.getAddOnMenuOptionsForForms();
  }

  async loadDeviceAndPoint() {
    if(!this.asDistributor) {
      this.measuringDevices = await lastValueFrom(this.measuringDeviceService.getAllWithoutContract(
        Utilities.dateToBackendString(new Date(this.model.start)),
        Utilities.dateToBackendString(new Date(this.model.end))
      ));
      this.measuringPoints = await lastValueFrom(this.measuringPointService.getAllWithoutContract(
        Utilities.dateToBackendString(new Date(this.model.start)),
        Utilities.dateToBackendString(new Date(this.model.end))
      ));
    }else if(this.asDistributor && this.model.distributor){
      this.measuringDevices = await lastValueFrom(this.measuringDeviceService.getAllWithoutContractForDistributor(
        this.model.distributor.id,
        Utilities.dateToBackendString(new Date(this.model.start)),
        Utilities.dateToBackendString(new Date(this.model.end))
      ));
      this.measuringPoints = await lastValueFrom(this.measuringPointDistributorService.getAllWithoutContract(
        this.model.distributor.id,
        Utilities.dateToBackendString(new Date(this.model.start)),
        Utilities.dateToBackendString(new Date(this.model.end))
      ));
    }else{
      this.partnersService.notificationService.showWarning('general.warning','error.missingDistributor');
    }
  }

  private async loadData(): Promise<void> {
    if (this.partners == undefined) {
      this.partners = await lastValueFrom(this.partnersService.getAllByDeleted());
    }
    if(this.distributors == undefined) {
      this.distributors = await lastValueFrom(this.distributorService.getAllByDeleted());
    }
  }

  updateEndDatepicker() {
    this.minDate = moment(this.model.start);
    if (!this.model.end) {
      this.model.end = this.model.start;
      setTimeout(() => this.endDatepicker.open(), 10);
    }
  }

  denyDate(): (date: Moment | null) => boolean {

    return (date) => {
      if (!date) {
        return true;
      }
      return this.denyDates.map(d => moment(d)).find(m => m.isSame(date, 'day')) === undefined;
    };
  }


  updateDevice(id: any) {
    this.model.measuringDevice = this.measuringDevices?.find(md => md.id == id);
  }

  updatePartner(id: any) {
    this.model.partner = this.partners?.find(p => p.id == id);
  }
  updateDistributor(id: any) {
    this.model.distributor = this.distributors?.find(p => p.id == id);
  }
}
