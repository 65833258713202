<ng-container *ngIf="model">
  <nx-card class="h-100 w-100">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.name"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>
    <inside-show-measuring-point [showMap]="true" [model]="model"
                                 [withNavigation]="false"></inside-show-measuring-point>
  </nx-card>
</ng-container>
