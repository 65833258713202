import {Component, OnInit} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MeasuringDevice} from '../../../models/measuring-device.model';
import {MeasuringDeviceService} from '../../../services/model/measuring-device.service';
import {NoteModel} from "../../../models/note.model";
import {PageTest} from "../../../utilities/PageTest";
import {ROUTING} from "../../../utilities/routing-constants";
import {AbstractShowPage} from "../../../models/AbstractShowPage";
import {MyStorageService} from "../../../services/my-storage.service";
import {DistributorLayoutComponent} from "../../../layouts/distributor-layout/distributor-layout.component";

@Component({
  selector: 'app-show-measuring-devices',
  templateUrl: './show-measuring-devices-for-distributor.component.html',
  styleUrls: ['./show-measuring-devices-for-distributor.component.scss']
})
@PageTest({
  path: 'show/:id',
  pathPrefix: ROUTING.MeasuringDevice.basePlural,
  layout: DistributorLayoutComponent
})
export class ShowMeasuringDevicesForDistributorComponent extends AbstractShowPage<MeasuringDevice, MeasuringDeviceService> implements OnInit {

  constructor(service: MeasuringDeviceService, route: ActivatedRoute,
              myStorageService: MyStorageService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.onInit();
  }


  override onInit() {
    this.sub = this.route.params.subscribe(async params => {
      this.model = await lastValueFrom(this.service.getByIdAsDistributor(params['id']));
      if (this.model == null) {
        this.service.notificationService.swalAlertPromise('warning', 'general.warning', 'error.modelNotExsist').then((result: any) => {
          this.service.navigateToUrl('distributor/measuring-devices');
        });
      }
      this.initProcess();
    });
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  addNote(): (note: NoteModel) => void {
    return async (note: NoteModel) => {
      await lastValueFrom(this.service.repo.addNote(this.model.id, note.id));
      this.model = await lastValueFrom(this.service.getById(this.model.id));
    };
  }

  override back() {
    return () => this.service.navigateToUrl('distributor/measuring-devices');

  }
}
