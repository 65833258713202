import {SearchFilterModel} from "./search-filter.model";
import {SearchOperationsEnum} from "./search-operations.enum";
import {FilterServiceError} from "./filter.error";
import {OrderByModel} from "./orderBy.model";
import {FilterPayloadInterface, FilterRequestPayload} from "./filter-payload-interface";

export interface BlockOperation {
  and(): SingletonOperation;

  or(): SingletonOperation;

  orBlock(): SingletonOperation;

  orderBy(...orderByList: OrderByModel[]): GroupByOperation;

  groupBy(...fieldNames: string[]): OrderByOperation;

  create(): FilterPayloadInterface;
}

export interface OrderByOperation {

  orderBy(...orderByList: OrderByModel[]): GroupByOperation;

  create(): FilterPayloadInterface;
}

export interface GroupByOperation {

  groupBy(...fieldNames: string[]): OrderByOperation;

  create(): FilterPayloadInterface;
}

export interface SingletonOperation {
  greaterThan(fieldName: string, value: any): BlockOperation;

  lessThan(fieldName: string, value: any): BlockOperation;

  greaterThanEqual(fieldName: string, value: any): BlockOperation;

  lessThanEqual(fieldName: string, value: any): BlockOperation;

  notEqual(fieldName: string, value: any): BlockOperation;

  equal(fieldName: string, value?: any): BlockOperation;

  isNull(fieldName: string): BlockOperation;

  isNotNull(fieldName: string): BlockOperation;

  match(fieldName: string, value: any): BlockOperation;

  matchStart(fieldName: string, value: any): BlockOperation;

  matchEnd(fieldName: string, value: any): BlockOperation;

  notMatch(fieldName: string, value: any): BlockOperation;

  matchNotStart(fieldName: string, value: any): BlockOperation;

  matchNotEnd(fieldName: string, value: any): BlockOperation;

  // in(fieldName: string, value: any): BlockOperation;
  //
  // notIn(fieldName: string, value: any): BlockOperation;
  create(): FilterPayloadInterface;
}


export class FilterOperationService implements SingletonOperation, BlockOperation, OrderByOperation, GroupByOperation {

  private readonly getPayload: FilterPayloadInterface;
  private searchFilter: SearchFilterModel | null = null;

  private layerOneFilters: SearchFilterModel[] = [];
  private layerTwoFilters: any[] = [];
  private layerThreeFilters: any[] = [];

  constructor(dtoType: string) {
    this.getPayload = new FilterRequestPayload(dtoType);
  }

  private addOrBlock() {
    this.addAndBlock();
    this.layerThreeFilters.push(this.layerTwoFilters);
    this.layerTwoFilters = [];
    this.layerOneFilters = [];
    this.searchFilter = null;
  }

  private addAndBlock() {
    this.addSearchFilter();
    this.layerTwoFilters.push(this.layerOneFilters);
    this.layerOneFilters = [];
    this.searchFilter = null;
  }

  private addSearchFilter() {
    if (this.searchFilter != null) {
      this.layerOneFilters.push(this.searchFilter);
    }
    this.searchFilter = null;
  }

  private createSearchFilter(fieldName: string, operation: SearchOperationsEnum, value?: any) {
    if (this.searchFilter != null) {
      throw new FilterServiceError("U didn't use operation");
    }
    this.searchFilter = new SearchFilterModel(fieldName, operation, value);
  }

  public and(): SingletonOperation {
    this.addAndBlock();
    return this;
  }

  public or(): SingletonOperation {
    this.addSearchFilter();
    return this;
  }

  public orBlock(): SingletonOperation {
    this.addOrBlock();
    return this;
  }

  public greaterThan(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.GREATER_THAN, value);
    return this;
  }

  public lessThan(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.LESS_THAN, value);
    return this;
  }

  public greaterThanEqual(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.GREATER_THAN_EQUAL, value);
    return this;
  }

  public lessThanEqual(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.LESS_THAN_EQUAL, value);
    return this;
  }

  public notEqual(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.NOT_EQUAL, value);
    return this;
  }

  public equal(fieldName: string, value?: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.EQUAL, value);
    return this;
  }

  public isNotNull(fieldName: string): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.IS_NOT_NULL);
    return this;
  }

  public isNull(fieldName: string): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.IS_NULL);
    return this;
  }

  public match(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.MATCH, value);
    return this;
  }

  public matchStart(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.MATCH_START, value);
    return this;
  }

  public matchEnd(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.MATCH_END, value);
    return this;
  }

  public notMatch(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.NOT_MATCH, value);
    return this;
  }

  public matchNotStart(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.MATCH_NOT_START, value);
    return this;
  }

  public matchNotEnd(fieldName: string, value: any): BlockOperation {
    this.createSearchFilter(fieldName, SearchOperationsEnum.MATCH_NOT_END, value);
    return this;
  }

  // public in(fieldName: string, value: any): BlockOperation {
  //     this.createSearchFilter(fieldName, SearchOperationsEnum.IN, value);
  //     return this;
  // }
  //
  // public notIn(fieldName: string, value: any): BlockOperation {
  //     this.createSearchFilter(fieldName, SearchOperationsEnum.NOT_IN, value);
  //     return this;
  // }

  public create(): FilterPayloadInterface {
    if (this.layerThreeFilters.length > 0 || this.layerTwoFilters.length > 0 || this.layerOneFilters.length > 0 || this.searchFilter != null) {
      this.addOrBlock();
    }
    this.getPayload.filters = this.layerThreeFilters;
    return this.getPayload;
  }

  groupBy(...fieldNames: string[]): OrderByOperation {
    this.getPayload.groupByAttributes = fieldNames;
    return this;
  }

  orderBy(...orderByList: OrderByModel[]): GroupByOperation {
    this.getPayload.orderByFilters = orderByList;
    return this;
  }
}
