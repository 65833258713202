import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, lastValueFrom, map} from "rxjs";
import {AddonRepository} from "./repositories/addon-repository";
import {AUTH_USER_KEY} from "./utilities/constants";
import {MyStorageService} from "./services/my-storage.service";
import {KeycloakService} from "keycloak-angular";
import {AbstractCaptionService, InKodItemModel} from "irf-caption";
import {IrfNotificationService} from "irf-services";
import {JCD_ADDON_MENU} from "jcd-addon";
import {ADMIN_MENU} from "./layouts/admin-layout/admin-menu.const";
import {DISTRIBUTOR_MENU} from "./layouts/distributor-layout/distributor-menu.const";
import {LoaderService} from "./services/loader.service";
import {DistributorService} from "./services/model/distributor.service";
import {PARTNER_MENU} from "./layouts/partner-layout/partner-menu.const";
import {PartnerService} from "./services/model/partner.service";

export const PARTNER_MENUS: { [key: string]: any } = {
  'partner': {
    icon: 'people-group',
    label: 'start.partnerRole.title',
    query: '',
    role: 'partner',
    children: PARTNER_MENU.map((item: any) => {
      item.prefix = 'partner';
      return item;
    })
  }
}
export const ADDON_MENUS: { [key: string]: any } = {

  'jcd-addon': {
    icon: 'statistic-addon',
    label: 'menu.jcd-addon',
    query: '',
    role: 'partner',
    children: JCD_ADDON_MENU.map((item: any) => {
      item.prefix = 'partner/jcd-addon';
      return item;
    })
  }
}
export const ADMIN_MENUS: { [key: string]: any } = {
  'admin': {
    icon: 'gears',
    label: 'start.adminRole.title',
    query: '',
    role: 'admin',
    children: ADMIN_MENU.map((item: any) => {
      item.prefix = 'admin';
      return item;
    })
  }
}
export const DISTRIBUTOR_MENUS: { [key: string]: any } = {
  'distributor': {
    icon: 'distributors',
    label: 'start.distributorRole.title',
    query: '',
    role: 'distributor',
    children: DISTRIBUTOR_MENU.map((item: any) => {
      item.prefix = 'distributor';
      return item;
    })
  }
}
export const CONFIG_MENUS: { [key: string]: any } = {
  'configurator': {
    icon: 'device-config',
    label: 'start.configuratorRole.title',
    query: 'config',
    role: 'config',
  }
}

export const ROLES_MENU_MAP: any[] = [
  {role: 'admin', card: ADMIN_MENUS, startUri: '/admin/contracts'},
  {role: 'Partner', card: PARTNER_MENUS, startUri: '/partner/webhook'},
  {role: 'Partner', card: {}, startUri: '/partner/jcd-addon/statistic', mode: 'addon'},
  {role: 'configurator', card: CONFIG_MENUS, startUri: '/config'},
  {role: 'distributor', card: DISTRIBUTOR_MENUS, startUri: '/distributor/contracts'}
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isInitRouteFinish: boolean = false;
  readonly currentYear = new Date().getFullYear();

  constructor(private titleService: Title,
              public translateService: TranslateService,
              private myStorageService: MyStorageService,
              private captionService: AbstractCaptionService,
              public activatedRoute: ActivatedRoute,
              private addonRepo: AddonRepository,
              private keycloakService: KeycloakService,
              private distributorService: DistributorService,
              private partnerService: PartnerService,
              private notificationService: IrfNotificationService,
              private loaderService: LoaderService,
              public router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.loaderService.show();
    await this.initCookies();
    this.setTitleByPageDecorator();
    this.isInitRouteFinish = true;
    this.loaderService.hide();
    setTimeout(() => {
      const roles = ROLES_MENU_MAP.filter(rm => this.keycloakService.isUserInRole(rm.role));
      if (roles.length > 0 && this.router.url == '/') {
        const startUri = ROLES_MENU_MAP.find(rm => rm.role == roles[0].role);
        if (startUri) {
          this.router.navigate([startUri.startUri]);
        }
      }
    }, 100);
  }

  private setTitleByPageDecorator() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this.activatedRoute.firstChild;
        if (child != null && child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((ttl: string) => {
      this.captionService.getCaption(ttl).then((result: InKodItemModel) => {
        this.titleService.setTitle(result.nev);
      });
    });
  }

  private async initCookies(): Promise<void> {
    const partnerRoute = this.router.config.find(route => route.path == 'partner');
    let authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, undefined);
    if (authUser === undefined) {
      authUser = await this.keycloakService.getKeycloakInstance().loadUserProfile();
      const b: any = await this.keycloakService.getKeycloakInstance().loadUserInfo();
      authUser.id = b.sub;
    }
    if (authUser) {
      const partnerForUser = await lastValueFrom(this.partnerService.getPartnersForUserPartner(authUser.id));
      this.partnerService.$partnerOfUser.next(partnerForUser);
      
      this.myStorageService.saveToCookies(AUTH_USER_KEY, authUser);
    }
  }
}
