<div nxModalContent style="height: calc(100% - 72px)">
  <nx-card class="w-100 h-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.addImageToModel' | caption}}
      </h3>
    </nx-card-header>
    <app-attachment-handler
      [attachedItems]="itemAttachments"
      [availableItems]="availableAttachments"
      [attachedTitle]="'general.attachedImages'"
      [availableTitle]="'general.availableImages'"
      [attachedViewComponent]="imgViewComponent"
      [availableViewComponent]="imgViewComponent"
      [acceptString]="'image/*'"
      [isMultiple]="componentConfig.multiple"
      [maxElement]="componentConfig.maxElement"
      (refreshEvent)="attachmentRefresh()"
      (attachmentSaveEvent)="attachmentSave($event)"
    >
    </app-attachment-handler>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="selectedAttachment"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | caption}}
  </button>
</div>
