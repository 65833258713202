import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersComponent} from './users/users.component';
import {RouterModule} from "@angular/router";
import {NxGridModule} from "@aposin/ng-aquila/grid";
import {ComponentsModule} from "../../components/components.module";
import {NxListModule} from "@aposin/ng-aquila/list";
import {NxTabsModule} from "@aposin/ng-aquila/tabs";
import {NxHeadlineModule} from "@aposin/ng-aquila/headline";
import {NxIconModule} from "@aposin/ng-aquila/icon";
import {NxFormfieldModule} from "@aposin/ng-aquila/formfield";
import {NxInputModule} from "@aposin/ng-aquila/input";
import {FormsModule} from "@angular/forms";
import {NxDropdownModule} from "@aposin/ng-aquila/dropdown";
import {NxPhoneInputModule} from "@aposin/ng-aquila/phone-input";
import {NxButtonModule} from "@aposin/ng-aquila/button";
import {TranslateModule} from "@ngx-translate/core";
import {EditUsersComponent} from './edit-users/edit-users.component';
import {ShowUsersComponent} from './show-users/show-users.component';
import {NxCheckboxModule} from "@aposin/ng-aquila/checkbox";
import {NxRadioModule} from "@aposin/ng-aquila/radio-button";
import {NxCardModule} from "@aposin/ng-aquila/card";
import {NxTableModule} from "@aposin/ng-aquila/table";
import {PipeModule} from "../../pipe/pipe.module";
import {NxDataDisplayModule} from "@aposin/ng-aquila/data-display";

@NgModule({
  declarations: [
    UsersComponent,
    EditUsersComponent,
    ShowUsersComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        NxGridModule,
        ComponentsModule,
        NxListModule,
        NxTabsModule,
        NxHeadlineModule,
        NxIconModule,
        NxFormfieldModule,
        NxInputModule,
        FormsModule,
        NxDropdownModule,
        NxPhoneInputModule,
        NxButtonModule,
        TranslateModule,
        NxCheckboxModule,
        NxRadioModule,
        NxCardModule,
        NxTableModule,
        PipeModule,
        NxDataDisplayModule,
    ]
})
export class UserModule {
}
