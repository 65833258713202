import {Pipe, PipeTransform} from '@angular/core';
import {AddressModel} from "../components/modal/add-address-to-model/address.model";

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(addressModel: AddressModel, ...args: unknown[]): string {
    if (addressModel) {
      let cc = "";
      if (addressModel.countryCode) {
        cc = "(" + addressModel.countryCode + ")";
      }
      return addressModel.country + cc + ", " + addressModel.zipCode + " " + addressModel.city + ", " + addressModel.address + " " + addressModel.houseNumber;
    }
    return "No Address";
  }

}
