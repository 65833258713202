<div nxModalContent>
  <nx-card class="w-100">
    <nx-card-header>
      <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
        {{'general.addNoteToModel' | caption}}
      </h3>
    </nx-card-header>
    <div nxLayout="grid">
      <div nxRow="">
        <div nxCol="12">
          <ng-container *ngIf="note">
            <nx-formfield nxLabel="{{'general.note'|caption}}">
              <textarea name="note" [(ngModel)]="note.message" nxInput cdkTextareaAutosize required
                        cols="4" style="min-height: 200px;"></textarea>
            </nx-formfield>
          </ng-container>
        </div>
      </div>
    </div>
  </nx-card>
</div>
<div nxModalActions>
  <button
    nxModalClose="cancel"
    class="nx-margin-bottom-0 nx-margin-right-xs"
    nxButton="small secondary"
    type="button"
  >
    {{'general.cancel' | caption}}
  </button>
  <button
    [nxModalClose]="note"
    class="nx-margin-bottom-0"
    nxButton="small"
    type="button"
  >
    {{'general.add' | caption}}
  </button>
</div>
