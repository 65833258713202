import {Type} from "@angular/core";
import {LoadChildren, Route, Routes} from "@angular/router";

interface PageConfigR {
  path: string | string[];
  singlePage?: boolean;
  layout?: Type<any>;
  pathPrefix?: string;
  data?: any;
  authGuard?: any;
}

interface LayoutConfigR {
  path: string;
  default?: boolean;
  layout?: Type<any>;
  authGuard?: any;
  data?: any;
  loadChildren?: LoadChildren;
}

interface LayoutInfo {
  config: LayoutConfigR;
  component: Type<any>;
}

interface PageInfo {
  config: PageConfigR;
  component: Type<any>;
}

interface LayoutMapElement {
  info: LayoutInfo;
  childrenPageConfig: PageInfo[];
  childrenLayoutConfig: LayoutMapElement[];
}

//TODO nested layout kezelés, Default layout, Beállítása és configja hogy ki NEM használja. Tömb path használata
let ROUTES: Routes;

const PAGE_INFO: PageInfo[] = [];
const LAYOUTS_INFO: LayoutInfo[] = [];

let DEFAULT_LAYOUT_NAME: string | undefined;

export function PageLayout(config: LayoutConfigR) {
  return function (constructor: any): any {
    LAYOUTS_INFO.push({config: config, component: constructor});
    if (config.default === true && DEFAULT_LAYOUT_NAME !== undefined) {
      console.warn('Existing default layout: ' + DEFAULT_LAYOUT_NAME + '. We can not set to default: ' + constructor.name);
    }
    if (config.default === true && DEFAULT_LAYOUT_NAME === undefined) {
      DEFAULT_LAYOUT_NAME = constructor.name;
    }
  };
}

export function PageTest(config: PageConfigR) {//TODO átnevezni
  return function (constructor: any): any {
    PAGE_INFO.push({config: config, component: constructor});
    return constructor;
  }
}

export function getRoutesR(outOfPageContextRoutes:Routes = []): Routes {//TODO átnevezni
  if (!ROUTES) {
    ROUTES = createRoutes();
  }
  console.log(ROUTES);
  return ROUTES.concat(outOfPageContextRoutes);
}

function createLayoutMapElementWithPageChildren(pagesOfLayout: PageInfo[], lc: LayoutInfo):LayoutMapElement {
  return {info: lc, childrenPageConfig: pagesOfLayout, childrenLayoutConfig:[]};
}

function createLayoutMapWithLayoutChildren(pagesOfLayout: PageInfo[], lc: LayoutInfo):LayoutMapElement  {
  return {info: lc, childrenPageConfig: [], childrenLayoutConfig:[]};
}
function createLayoutMap() {
  const layoutMap: LayoutMapElement[] = [];

  const pageWithLayout = PAGE_INFO.filter(pc => pc.config.layout);
  const pageWithDefaultLayout = PAGE_INFO.filter(pc => !pc.config.layout && !pc.config.singlePage);

  const layoutWithLayout = LAYOUTS_INFO.filter(layoutInfo => layoutInfo.config.layout);
  const layoutWithoutLayout = LAYOUTS_INFO.filter(layoutInfo => !layoutInfo.config.layout);

  layoutWithoutLayout.forEach(layoutInfo => {
    let pagesOfLayout:PageInfo[] = pageWithLayout.filter(pageInfo => pageInfo.config.layout?.name == layoutInfo.component.name)
    if (layoutInfo.component.name == DEFAULT_LAYOUT_NAME) {
      pagesOfLayout = pagesOfLayout.concat(pageWithDefaultLayout)
    }
    let element: LayoutMapElement;
      element = createLayoutMapElementWithPageChildren(pagesOfLayout, layoutInfo);
      layoutMap.push(element);
  });

  layoutWithLayout.forEach(layoutInfo => {
    let pagesOfLayout:PageInfo[] = pageWithLayout.filter(pageInfo => pageInfo.config.layout?.name == layoutInfo.component.name)
    if (layoutInfo.component.name == DEFAULT_LAYOUT_NAME) {
      pagesOfLayout = pagesOfLayout.concat(pageWithDefaultLayout)
    }
    let element: LayoutMapElement;
    element = createLayoutMapElementWithPageChildren(pagesOfLayout, layoutInfo);
    layoutMap.push(element);
  });

  return layoutMap;
}


function createRoutes() {
  let routes: Routes = [];

  if (DEFAULT_LAYOUT_NAME === undefined && LAYOUTS_INFO.length > 0) {
    DEFAULT_LAYOUT_NAME = LAYOUTS_INFO[0].component.name;
    console.warn('You did not set default layout therefore we set to ' + LAYOUTS_INFO[0].component.name)
  }

  const pageWithoutLayout = PAGE_INFO.filter(pc => !pc.config.layout && pc.config.singlePage === true);

  const layoutMap = createLayoutMap();

  layoutMap.forEach((layoutElement: LayoutMapElement) => {
    const layoutRoute: Route = createRouteElement(trimPath(layoutElement.info.config.path), layoutElement.info);
    if (layoutElement.childrenPageConfig.length > 0 && layoutElement.info.config.loadChildren == undefined) {
      let childrenRoutes: Route[] = [];
      layoutElement.childrenPageConfig.forEach((pageInfo: PageInfo) => {
        const routeElement: Route | Route[] = generateRoute(pageInfo, layoutElement.info);
        if (Array.isArray(routeElement)) {
          childrenRoutes = childrenRoutes.concat(routeElement);
        } else {
          childrenRoutes.push(routeElement);
        }
      });
      layoutRoute.children = childrenRoutes;
    }
    if(layoutElement.info.config.loadChildren != undefined){
      layoutRoute.loadChildren = layoutElement.info.config.loadChildren;
    }
    routes.push(layoutRoute);
  });

  pageWithoutLayout.forEach(pageInfo => {
    const routeElement: Route | Route[] = generateRoute(pageInfo);
    if (Array.isArray(routeElement)) {
      routes = routes.concat(routeElement);
    } else {
      routes.push(routeElement);
    }
  });

  return routes;
}

function createRouteElement(path: string, configAndComponent: LayoutInfo | PageInfo, layout?:LayoutInfo) {
  const item: Route = {
    path: trimPath(path),
    component: configAndComponent.component,
  };
  if (configAndComponent.config.authGuard) {
    item.canActivate = [configAndComponent.config.authGuard];
  }else if(layout){
    item.canActivate = [layout.config.authGuard];
  }
  if (configAndComponent.config.data) {
    item.data = configAndComponent.config.data
  }
  return item;
}

function generateRoute(pageInfo: PageInfo, layoutInfo?:LayoutInfo): Route | Route[] {
  if (Array.isArray(pageInfo.config.path)) {
    const routeElements: Route[] = [];
    pageInfo.config.path.forEach(pathElement => {
      const path = (pageInfo.config.pathPrefix ? trimPath(pageInfo.config.pathPrefix) + '/' : '') + trimPath(pathElement);
      const items = createRouteElement(trimPath(path), pageInfo, layoutInfo);
      routeElements.push(items);
    });
    return routeElements;
  }
  const path = (pageInfo.config.pathPrefix ? trimPath(pageInfo.config.pathPrefix) + '/' : '') + trimPath(pageInfo.config.path);
  return createRouteElement(trimPath(path), pageInfo, layoutInfo);
}

function trimPath(path: string) {
  return path.replace(/^\/|\/$/g, '');
}

