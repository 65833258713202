<div nxLayout="grid ">
  <div nxRow="">
    <div nxCol="3">
      <div nxRow="">
        <div nxCol="12">
          <figure nxFigure="">
            <img
              [src]="model.attachment.srcWidth"
              [alt]="model.uuId"
            />
          </figure>
        </div>
      </div>
    </div>
    <div nxCol="9">
      <h3 nxHeadline="subsection-medium" class="bar-as-toolbar nx-margin-bottom-2xs">
        {{model.uuId}}
        <ng-container *ngIf="withNavigation">
          <a href="javascript:void(0)" (click)="goPartner()" class="back-left">
            <nx-icon [name]="'eye'"></nx-icon>
          </a>
        </ng-container>
      </h3>
      <div nxRow="">
        <div nxCol="12">
          <inside-show-measuring-device-data [model]="model"></inside-show-measuring-device-data>
        </div>
    </div>
  </div>
</div>
