import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormToolBarOption} from "./form-tool-bar-option.model";
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";

@Component({
  selector: 'page-form-tool-bar',
  templateUrl: './form-tool-bar.component.html',
  styleUrls: ['./form-tool-bar.component.scss']
})
export class FormToolBarComponent implements OnInit {
  @Input() disableSave: boolean;
  @Input() saveSupplier: () => void;
  @Input() cancelSupplier: () => void;
  @Input() modalOptions: FormToolBarOption[] = [];
  mobile: boolean = false;

  private readonly _destroyed = new Subject<void>();

  constructor(public viewportService: NxViewportService,
              private _cdr: ChangeDetectorRef,) {
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_LARGE)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
  }

}
