import {QueryList} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AbstractCaptionService, CaptionCodeDirective, InKodItemModel} from "irf-caption";
import {lastValueFrom, map} from "rxjs";

export type TRANSLATE_MAP = { [key: string]: string };

export class FileBaseCaptionService extends AbstractCaptionService {

  constructor(private translateService: TranslateService) {
    super();
  }

  getCaption(param: string, codeGroup?: string, languageId?: string): Promise<InKodItemModel>;
  getCaption(param: string[], codeGroup?: string, languageId?: string): Promise<InKodItemModel[]>;
  getCaption(param: string | string[], codeGroup?: string, languageId?: string): Promise<InKodItemModel | InKodItemModel[]>;
  getCaption(param: string | string[], codeGroup?: string, languageId?: string): Promise<InKodItemModel> | Promise<InKodItemModel[]> | Promise<InKodItemModel | InKodItemModel[]> {
    if (typeof param === "string") {
      return lastValueFrom(this.translateService.get(param).pipe(
        map(value => new ImplInKodItemModel(param, value))
      ));
    }
    if (param.length > 0) {
      return lastValueFrom(this.translateService.get(param).pipe(
        map((value: TRANSLATE_MAP) => this.convertTranslateMapToInKodItemModelArray(value))
      ));
      }
    return Promise.resolve([{kod: "Ups", kodCsoport: "something went wrong", nev: "", leiras: ""}])
  }

  async loadCaption(captionCodes: QueryList<CaptionCodeDirective>, detectChanges?: boolean): Promise<void> {
    if (detectChanges) {
      captionCodes.changes.subscribe(changedCaptionCodes => {
        this.loadCaption(changedCaptionCodes);
      });
    }

    const codeList: string[] = captionCodes.map(caption =>{
      return caption.captionCode
    } );
    const captions: TRANSLATE_MAP = this.convertInKodItemModelArrayToTranslateMap(await this.getCaption(codeList));
    captionCodes.forEach((captionDirective, index) => {
      captionDirective.captionName = captions[captionDirective.captionCode];
    });
    return Promise.resolve(undefined);
  }

  private convertTranslateMapToInKodItemModelArray(value: TRANSLATE_MAP): ImplInKodItemModel[] {
    const result: ImplInKodItemModel[] = [];
    Object.keys(value).forEach(translateKey => {
      result.push(new ImplInKodItemModel(translateKey, value[translateKey]));
    })
    return result
  }

  private convertInKodItemModelArrayToTranslateMap(value: ImplInKodItemModel[]): TRANSLATE_MAP {
    const result: TRANSLATE_MAP = {};
    value.forEach(inKodModel => {
      result[inKodModel.kod] = inKodModel.nev;
    });
    return result;
  }

}

export class ImplInKodItemModel implements InKodItemModel {
  kod: string;
  kodCsoport: string;
  leiras: string;
  nev: string;


  constructor(kod: string, nev: string) {
    this.kod = kod;
    this.kodCsoport = "APP_CAPTIONS"
    this.nev = nev;
    this.leiras = nev;
  }
}
