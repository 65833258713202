import {Component, OnInit} from '@angular/core';
import {TestRepository} from "../../../repositories/TestRepository";
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";
import {PageTest} from "../../../utilities/PageTest";
import {PartnerLayoutComponent} from "../../../layouts/partner-layout/partner-layout.component";
import {AbstractBaseComponent} from "common";


export interface MappingInterface {
  method: string,
  className: string,
  function: string,
  urlPattern: string,
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
@PageTest({
  path: 'no-addon',
  layout: PartnerLayoutComponent
})
export class WelcomeComponent extends AbstractBaseComponent implements OnInit {

  METHODS: { [key: string]: string } = {
    'GET': 'positive',
    'POST': 'negative',
    'DELETE': 'critical',
    'PUT': 'active',
    'PATCH': 'active'
  };


  title = 'mgmt - ' + environment.profile;
  public response: any;
  public mappings: Map<string, MappingInterface[]> = new Map<string, any[]>();

  constructor(private testRepository: TestRepository) {
    super();
  }

  async ngOnInit(): Promise<void> {
    // this.response = await lastValueFrom(this.testRepository.rawMappings(this.METHODS));
    // console.log(this.response);
    // this.response = this.response.contexts.mgmtApi.mappings.dispatcherServlets.dispatcherServlet.filter((asd: any) => asd.handler.includes('hu.mdlb.rvc'));
    // this.processHandlers();
  }

  private processHandlers() {
    this.mappings = new Map<string, any[]>();
    this.response.forEach((handler: any) => {
      const functionName = handler.handler.split('#')[1];
      let classNameSplit = handler.details.handlerMethod.className.split('.');
      const classNameSplitElement = classNameSplit[classNameSplit.length - 1];
      if (this.mappings.has(classNameSplitElement)) {
        const element = this.mappings.get(classNameSplitElement);
        if (element != undefined) {
          element.push({
            method: handler.details.requestMappingConditions.methods[0],
            className: classNameSplitElement,
            function: functionName,
            urlPattern: handler.details.requestMappingConditions.patterns[0]
          });
        }
      } else {
        this.mappings.set(classNameSplitElement, []);
      }
    });
  }
}
