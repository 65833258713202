import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {NxBreakpoints, NxViewportService} from "@aposin/ng-aquila/utils";
import {AUTH_USER_KEY, ROLE_INDEX} from "../../utilities/constants";
import {MyStorageService} from "../../services/my-storage.service";
import {PermissionCheckComponent} from "../../models/PermissionCheckComponent";

@Component({
  selector: 'page-show-tool-bar',
  templateUrl: './show-tool-bar.component.html',
  styleUrls: ['./show-tool-bar.component.scss']
})
export class ShowToolBarComponent extends PermissionCheckComponent implements OnInit {
  @Input() backSupplier: () => void;
  @Input() editSupplier?: () => void;
  @Input() title: string;

  mobile: boolean = false;

  private readonly _destroyed = new Subject<void>();

  constructor(myStorageService: MyStorageService,
              public viewportService: NxViewportService,
              private _cdr: ChangeDetectorRef,) {
    super(myStorageService)
    this.viewportService
      .min(NxBreakpoints.BREAKPOINT_MEDIUM)
      .pipe(takeUntil(this._destroyed))
      .subscribe(isGreaterThanXLarge => {
        this.mobile = !isGreaterThanXLarge;
      });
  }

  ngOnInit(): void {
   this.initPermission();
  }

}
